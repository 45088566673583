export default {
  configs: {
    fetchSessionCategories: {
      api: {
        url: 'default',
        version: 1,
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs) {
        return `therapist/v${configs.api.version}/session/categories`
      },
    },
  },
  protocols: {
    getProtocolsDefinitions: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { therapyIds, kitName, patientId }) {
        const params = new URLSearchParams()

        if (therapyIds) params.append('therapy_ids', therapyIds)
        if (kitName) params.append('kit_name', kitName)
        if (patientId) params.append('patient_id', patientId)

        const queryString = params.toString()

        return `therapist/v${configs.api.version}/prescriptions/protocol_definitions${queryString ? `?${queryString}` : ''}`
      },
    },
    getProtocolSessions: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { protocolId, phase }) {
        return `therapist/v${configs.api.version}/prescriptions/protocol_definitions/${protocolId}/phases/${phase}`
      },
    },
    getProtocolSessionDefinition: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, sessionId) {
        return `therapist/v${configs.api.version}/prescriptions/protocol_session_definitions/${sessionId}`
      },
    },
    prescribeProtocol: {
      api: {
        url: 'default',
        version: '2',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs) {
        return `therapist/v${configs.api.version}/protocol_prescriptions`
      },
    },
    activatePendingProtocol: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, patientUUID) {
        return `therapist/v${configs.api.version}/patients/${patientUUID}/pending-protocol-prescription/apply`
      },
    },
    getPrescribedProtocolPath: {
      api: {
        url: 'default',
        version: '2',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$meta: {
        mock: (_, patientUUID) => `mocks/endpoints/prescription/protocols/getPrescribedProtocolPath/${patientUUID}.json`,
      },
      $$makeUrl(configs, patientUUID) {
        const patientQuery = new URLSearchParams({ patient_uuid: patientUUID })

        return `therapist/v${configs.api.version}/protocol_prescriptions?${patientQuery}`
      },
    },
    disableTreatmentPath: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, uuid) {
        return `therapist/v${configs.api.version}/protocol_prescriptions/${uuid}/disable`
      },
    },
    updateRecommendationEnabled: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'patch',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, uuid) {
        return `therapist/v${configs.api.version}/protocol_prescriptions/${uuid}`
      },
    },
    extendProtocolPhase: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$meta: {
        mock: () => 'mocks/endpoints/prescription/protocol/extendProtocolPhase.json',
      },
      $$makeUrl(configs, uuid) {
        return `therapist/v${configs.api.version}/protocol_prescriptions/${uuid}/extend`
      },
    },
    progressProtocolPhaseWithChanges: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$meta: {
        mock: () => 'mocks/endpoints/prescription/protocol/progressProtocolPhaseWithChanges.json',
      },
      $$makeUrl(configs, uuid) {
        return `therapist/v${configs.api.version}/protocol_prescriptions/${uuid}/progress_with_changes`
      },
    },
    progressProtocolPhaseImmediately: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, uuid) {
        return `therapist/v${configs.api.version}/protocol_prescriptions/${uuid}/progress`
      },
    },
  },
  therapies: {
    fetchTherapies: {
      api: {
        url: 'default',
      },
      $$method: 'get',
      $$meta: {
        mock: () => 'mocks/endpoints/prescription/therapies/fetchTherapies.json',
      },
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { kitName } = {}) {
        const url = 'api/v1.1/therapies'
        const queryParams = new URLSearchParams('')

        if (kitName) {
          queryParams.append('kit_name', kitName)
        }

        return !queryParams.toString() ? url : `${url}?${queryParams}`
      },
    },
    getTherapyExercises: {
      api: {
        url: 'default',
        version: '1.1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { therapy, includeLevels, includeAssets }) {
        let extraParams = ''

        if (therapy.side) {
          extraParams += `&filter_side=${therapy.side}`
        }

        if (includeLevels || includeAssets) {
          extraParams += '&include='
          extraParams += includeLevels ? 'levels' : ''
          extraParams += includeLevels && includeAssets ? ',assets' : ''
          extraParams += !includeLevels && includeAssets ? 'assets' : ''
          extraParams += ',versioned'
        }

        return `api/v${configs.api.version}/exercises?therapy=${therapy.id}${extraParams}`
      },
    },
  },
  patient: {
    getCBTPrescription: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, accountUUID) {
        return `therapist/v${configs.api.version}/cbt/member/${accountUUID}/prescription`
      },
    },
    saveCBTPrescription: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, accountUUID) {
        return `therapist/v${configs.api.version}/cbt/member/${accountUUID}/prescription`
      },
    },
    fetchPrescribedSessions: {
      api: {
        url: 'default',
        version: '1.1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$meta: {
        mock: (_, patientId) => `mocks/endpoints/prescription/patient/fetchPrescribedSessions/${patientId}.json`,
      },
      $$makeUrl(configs, patientId) {
        return `api/v${configs.api.version}/patients/${patientId}/sessions`
      },
    },
    getPrescribedSessionExercises: {
      api: {
        url: 'default',
        version: 1,
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$meta: {
        mock: (_, { patientId, sessionId, therapySide }) => {
          let mockBaseUrl = `mocks/endpoints/prescription/patient/getPrescribedSessionExercises/${patientId}/session_${sessionId}`

          if (therapySide) {
            mockBaseUrl += `_side_${therapySide}`
          }

          return `${mockBaseUrl}.json`
        },
      },
      $$makeUrl(configs, { patientId, sessionId, therapySide }) {
        const url = `therapist/v${configs.api.version}/patients/${patientId}/sessions/${sessionId}/exercises`
        const queryParams = new URLSearchParams('')

        queryParams.append('includes', 'exercises,blacklisted')

        if (therapySide) {
          queryParams.append('filter_side', therapySide)
        }

        return `${url}?${queryParams}`
      },
    },
    getTherapyExercisesConditionsByPatient: {
      api: {
        url: 'default',
        version: '1.1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { patientId, therapyId, ignoreFromDefaultSessions = false }) {
        const url = `api/v${configs.api.version}/patients/${patientId}/conditions/therapy/${therapyId}`
        let queryParams = null

        if (ignoreFromDefaultSessions) {
          queryParams = new URLSearchParams('')
          queryParams.set('ignore_conditions_from_default_sessions', true)
        }

        return !queryParams ? url : `${url}?${queryParams}`
      },
    },
    getExerciseConditions: {
      api: {
        url: 'default',
        version: '1.1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { patientId, exerciseId, ignoreFromDefaultSessions = false }) {
        const url = `api/v${configs.api.version}/patients/${patientId}/exercise/${exerciseId}/conditions`
        let queryParams = null

        if (ignoreFromDefaultSessions) {
          queryParams = new URLSearchParams('')
          queryParams.set('ignore_conditions_from_default_sessions', true)
        }

        return !queryParams ? url : `${url}?${queryParams}`
      },
    },
    addSession: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, patientId) {
        return `therapist/v${configs.api.version}/patients/${patientId}/sessions`
      },
    },
    updateSession: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'put',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { patientId, sessionId }) {
        return `therapist/v${configs.api.version}/patients/${patientId}/sessions/${sessionId}`
      },
    },
    deleteSession: {
      api: {
        url: 'default',
        version: '1.1',
      },
      $$method: 'delete',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { patientId, sessionId }) {
        return `api/v${configs.api.version}/patients/${patientId}/sessions/${sessionId}`
      },
    },
    fetchExercisesWithResistanceForCategories: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$meta: {},
      $$makeUrl(configs, patientId) {
        return `therapist/v${configs.api.version}/patients/${patientId}/session/exercises/resistance-propagation`
      },
    },
    fetchBlacklistedExercises: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$meta: {
        mock: (_, { patientId }) => {
          return `mocks/endpoints/prescription/patient/fetchBlacklistedExercises/${patientId}.json`
        },
      },
      $$makeUrl(configs, { patientId, offset, limit, sortDir, therapyId }) {
        const url = `therapist/v${configs.api.version}/patients/${patientId}/exercises/blacklist`
        const queryParams = new URLSearchParams('')

        if (offset !== undefined) {
          queryParams.append('offset', offset)
        }

        if (limit) {
          queryParams.append('limit', limit)
        }

        if (sortDir) {
          queryParams.append('sortDir', sortDir)
        }

        if (therapyId) {
          queryParams.append('therapy', therapyId)
        }

        return `${url}?${queryParams}`
      },
    },
    removeExerciseFromBlacklist: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'delete',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, patientId) {
        return `therapist/v${configs.api.version}/patients/${patientId}/exercises/blacklist`
      },
    },
  },
  recommendations: {
    fetchRecommendations: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        withCredentials: true,
        headers: (data) => data.headers.authorization,
      },
      $$meta: {
        mock: (_, { pathologyId }) => `mocks/endpoints/prescription/recommendations/fetchRecommendations/pathology_${pathologyId}.json`,
      },
      $$makeUrl(configs, { pathologyId, patientId, sessionId, groupByType, sessionDefinitionId }) {
        const url = `therapist/v${configs.api.version}/recommendation`

        const params = {
          pathology_id: pathologyId,
          session_id: sessionId,
          session_definition_id: sessionDefinitionId,
          group_by_type: groupByType,
          patient_id: patientId,
        }

        const queryParams = new URLSearchParams()

        Object.entries(params).forEach(([key, value]) => {
          if (value) queryParams.append(key, value)
        })

        return `${url}?${queryParams}`
      },
    },
    saveRecommendations: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'post',
      $$config: {
        withCredentials: true,
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { patientID, sessionID }) {
        // Session if sense to make no sense for this request
        if (sessionID) return `therapist/v${configs.api.version}/patient/${patientID}/session/${sessionID}/recommendation`

        return `api/v1.1/patient/${patientID}/recommendation`
      },
    },
  },
  session: {
    calculateEstimatedSessionTime: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs) {
        return `therapist/v${configs.api.version}/estimate-session-time`
      },
    },
  },
  message: {
    initialMessage: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { patientId }) {
        return `therapist/v${configs.api.version}/prescriptions/${patientId}/initial-message`
      },
    },
  },
  acceptProtocolSuggestion: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'post',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs) {
      return `therapist/v${configs.api.version}/protocol_prescriptions/accept_protocol_suggestion`
    },
  },
}
