import Vue from 'vue'
import i18n from '@/scripts/app-configs/i18n-config'

/**
 *  Managing Prescription Recommendations
 */

export default {
  namespaced: true,
  state: {
    recommendations: {
      list: null,
      selected: [],
      locked: [],
      excluded: [],
    },
    selectedByLocking: [],
  },
  getters: {
    getRecommendations: (state) => state.recommendations,
    getSelected: (state) => state.recommendations.selected,
    getFilteredSelected: (state) => {
      const notShownOnDTCodes = state.recommendations.list?.after_session.reduce((set, recommendation) => {
        if (recommendation.is_assigned && !recommendation.is_shown_on_dt) {
          set.add(recommendation.code)
        }

        return set
      }, new Set())

      return (state.recommendations.selected || []).filter((code) => !notShownOnDTCodes.has(code))
    },
    getLocked: (state) => state.recommendations.locked,
    getFilteredLocked: (state) => {
      const notShownOnDTCodes = state.recommendations.list?.after_session.reduce((set, recommendation) => {
        if (recommendation.is_locked && !recommendation.is_shown_on_dt) {
          set.add(recommendation.code)
        }

        return set
      }, new Set())

      return state.recommendations.locked.filter((code) => !notShownOnDTCodes.has(code))
    },
    getExcluded: (state) => state.recommendations.excluded,
    getSelectedByLocking: (state) => state.selectedByLocking,
    isEmptyRecommendations: (state) =>
      !state.recommendations.selected?.length &&
      !state.recommendations.locked?.length &&
      !state.recommendations.excluded?.length,
    isLimitReached: (state) => {
      const coolDowns = state.recommendations.list?.after_session || []
      const items = coolDowns.filter(({ is_shown_on_dt }) => is_shown_on_dt)

      return items.length > 4
    },
  },
  mutations: {
    setRecommendationsList(state, recommendations) {
      Vue.set(state.recommendations, 'list', recommendations)
    },
    setSelectedRecommendations(state, recommendations) {
      Vue.set(state.recommendations, 'selected', recommendations)
    },
    setLockedRecommendations(state, recommendations) {
      if (!recommendations || !recommendations.length) {
        state.recommendations.locked.clear()

        return
      }
      state.recommendations.locked.forEach(state.recommendations.locked.add, recommendations)
    },
    setExcludedRecommendations(state, recommendations) {
      if (!recommendations || !recommendations.length) {
        state.recommendations.excluded.clear()

        return
      }
      state.recommendations.excluded.forEach(state.recommendations.excluded.add)
    },
    resetRecommendations(state) {
      Vue.set(state, 'recommendations', { list: null, selected: null, locked: new Set(), excluded: new Set() })
      Vue.set(state, 'selectedByLocking', [])
    },
    updateLockedRecommendations(state, { action, payload }) {
      const currentlyLocked = new Set(state.recommendations.locked)

      if (action === 'reset') currentlyLocked.clear()

      if (!payload) return

      const codes = Array.isArray(payload) ? payload : [payload]

      if (action === 'add') codes.forEach(currentlyLocked.add, currentlyLocked)
      else if (action === 'remove') codes.forEach(currentlyLocked.delete, currentlyLocked)
      Vue.set(state.recommendations, 'locked', [...currentlyLocked])
    },
    updateExcludedRecommendations(state, { action, payload }) {
      const currentlyExcluded = new Set(state.recommendations.excluded)

      if (action === 'reset') currentlyExcluded.clear()

      if (!payload) return

      const codes = Array.isArray(payload) ? payload : [payload]

      if (action === 'add') codes.forEach(currentlyExcluded.add, currentlyExcluded)
      else if (action === 'remove') codes.forEach(currentlyExcluded.delete, currentlyExcluded)
      Vue.set(state.recommendations, 'excluded', [...currentlyExcluded])
    },
    updateSelectedByLocking(state, { action, payload }) {
      const currentlySelectedByLocking = new Set(state.selectedByLocking)

      if (action === 'reset') currentlySelectedByLocking.clear()

      if (!payload) return

      if (action === 'add') currentlySelectedByLocking.add(payload)
      else if (action === 'remove') currentlySelectedByLocking.delete(payload)
      Vue.set(state, 'selectedByLocking', [...currentlySelectedByLocking])
    },
    toggleRecommendationSelection(state, { index, recommendationsGroup, isSelected }) {
      Vue.set(state.recommendations.list[recommendationsGroup][index], 'is_assigned', isSelected)
      Vue.set(state.recommendations.list[recommendationsGroup][index], 'is_shown_on_dt', isSelected)
    },
  },
  actions: {
    async fetchRecommendations({ commit, dispatch, rootGetters }, { sessionId }) {
      const sessionDefinitionId = rootGetters['prescription/currentSession/getProtocolSessionDefinitionId']

      commit('prescription/setAsFetching', { recommendations: true }, { root: true })

      try {
        if (sessionDefinitionId) {
          const params = {
            sessionDefinitionId: sessionDefinitionId,
            groupByType: true,
            useVersionedAssets: true,
          }

          const { data } = await Vue.$http('prescription/recommendations/fetchRecommendations', params)

          const defaultRecommendationsMapped = Object.entries(data).reduce((acc, group) => {
            const [groupName, recommendations] = group

            acc[groupName] = recommendations.map((item) => ({
              ...item,
              is_assigned: item.is_selected_by_default || item.is_assigned,
            }))

            return acc
          }, {})

          commit('setRecommendationsList', defaultRecommendationsMapped)
          dispatch('getRecommendationsCodes')
        }

        await dispatch('getPatientRecommendations', { sessionId, isCustomSession: !sessionDefinitionId })

      } catch (error) {
        console.error('An error occurred: Could not fetch recommendations.', error)
      } finally {
        commit('prescription/setAsFetching', { recommendations: false }, { root: true })
      }
    },
    async getPatientRecommendations({ commit, rootGetters, getters }, { sessionId, isCustomSession }) {
      const patientId = rootGetters['patient/getMemberBasics'].id

      const params = {
        sessionId,
        patientId,
        groupByType: true,
        useVersionedAssets: true,
      }

      const { data: memberRecommendations } = await Vue.$http('prescription/recommendations/fetchRecommendations', params)

      const alreadySelectedRecommendations = !isCustomSession && getters.getSelected ? getters.getSelected : []

      const groupedRecommendations = Object.values(memberRecommendations).flat().reduce((acc, recommendation) => {
        const { code, is_blacklisted: excluded, is_locked: locked, is_assigned: selected } = recommendation

        const addCodeToGroup = (condition, groupName) => {
          if (condition) acc[groupName].push(code)
        }

        addCodeToGroup(selected, 'selected')
        addCodeToGroup(excluded, 'excluded')
        addCodeToGroup(locked, 'locked')

        return acc
      }, {
        locked: [],
        excluded: [],
        selected: [],
      })

      commit('updateLockedRecommendations', { action: 'add', payload: groupedRecommendations.locked })

      commit('updateExcludedRecommendations', { action: 'add', payload: groupedRecommendations.excluded })

      commit('setSelectedRecommendations', [...alreadySelectedRecommendations, ...groupedRecommendations.selected])

      if ((patientId && sessionId) || isCustomSession) {
        commit('setRecommendationsList', memberRecommendations)
      }
    },
    async saveRecommendations({ getters, rootGetters, commit }, sessionID) {
      if (getters.hasMoreThanFourCoolDownRecommendations) {
        Vue.$notify.error(i18n.t('RECOMMENDATIONS.ERROR.LIMIT'))

        return Promise.reject(new Error('RECOMMENDATIONS.ERROR.LIMIT'))
      }

      try {
        commit('prescription/setAsSaving', { recommendations: true }, { root: true })
        const lockedRecommendations = getters.getFilteredLocked ?? []

        const excludedRecommendations = getters.getRecommendations.excluded ?? []

        const selectedRecommendationsCodes = getters.getFilteredSelected ?? []

        const patientID = Number(rootGetters['patient/getMemberBasics'].id)

        const body = {
          recommendation_codes: selectedRecommendationsCodes.filter((code) =>
            !lockedRecommendations.includes(code) &&
            !excludedRecommendations.includes(code),
          ),
          locked_codes: lockedRecommendations,
          excluded_codes: excludedRecommendations,
        }

        await Vue.$http('prescription/recommendations/saveRecommendations', { patientID, sessionID }, {
          body,
        })
        commit('patient/updateCounters',
          { total_recommendations: selectedRecommendationsCodes.length + lockedRecommendations.length }, { root: true },
        )
        commit('resetRecommendations')

        return Promise.resolve()
      } catch (error) {
        console.error('[presription-recommendations] An error occurred: Recommendations were not saved.', error)
        Vue.$notify.error(i18n.$t('RECOMMENDATIONS.ERROR_SAVING'))

        return Promise.reject(error)
      } finally {
        commit('prescription/setAsSaving', { recommendations: false }, { root: true })
      }
    },
    getRecommendationsCodes({ commit, getters }) {
      const allRecommendations = getters.getRecommendations

      if (!allRecommendations.selected?.length) {
        commit('setSelectedRecommendations', null)
      }

      const selectedRecommendations = Object.keys(allRecommendations.list).reduce((accRecommendations, currentRecommendationGroup) => {
        const recommendations = allRecommendations.list[currentRecommendationGroup]
          .filter(((recommendation) => recommendation.is_assigned))
          .map(({ code }) => code)

        return [...accRecommendations, ...recommendations]
      }, [])

      commit('setSelectedRecommendations', selectedRecommendations)
    },
    toggleRecommendationSelection({ commit, dispatch, getters }, { recommendationsGroup, index }) {
      const recommendations = getters.getRecommendations
      const recommendationToEdit = recommendations.list[recommendationsGroup][index]

      commit('toggleRecommendationSelection', { index, recommendationsGroup, isSelected: !recommendationToEdit.is_assigned })
      dispatch('getRecommendationsCodes')
    },
    toggleLockedRecommendations({ commit, dispatch, getters }, { recommendationsGroup, index }) {
      const recommendations = Object.assign({}, getters.getRecommendations)

      const recommendationToEdit = recommendations.list[recommendationsGroup][index]

      const alreadyLocked = getters.getLocked.includes(recommendationToEdit.code)

      if (!alreadyLocked) {
        // If the recommendation is not selected yet, we need to select it
        if (!recommendationToEdit.is_assigned) {
          commit('toggleRecommendationSelection', { index, recommendationsGroup, isSelected: true })
          commit('updateSelectedByLocking', { action: 'add', payload: recommendationToEdit.code })
        }
        commit('updateLockedRecommendations', { action: 'add', payload: recommendationToEdit.code })
        dispatch('getRecommendationsCodes')

        return
      }

      const wasSelectedByLocking = getters.getSelectedByLocking.includes(recommendationToEdit.code)

      if ((wasSelectedByLocking || !recommendationToEdit.is_shown_on_dt) && recommendationToEdit.is_assigned) {
        commit('toggleRecommendationSelection', { index, recommendationsGroup, isSelected: false })
        commit('updateSelectedByLocking', { action: 'remove', payload: recommendationToEdit.code })
      }

      commit('updateLockedRecommendations', { action: 'remove', payload: recommendationToEdit.code })
      dispatch('getRecommendationsCodes')
    },
    toggleExcludedRecommendations({ commit, getters }, { recommendationsGroup, index }) {
      const recommendations = getters.getRecommendations
      const recommendationToEdit = recommendations.list[recommendationsGroup][index]

      const action = getters.getExcluded.includes(recommendationToEdit.code) ? 'remove' : 'add'

      commit('updateExcludedRecommendations', { action, payload: recommendationToEdit.code })
    },
    resetRecommendations({ commit }) {
      commit('resetRecommendations')
    },
  },
}
