import type { VueConstructor } from 'vue'
import exerciseState from './exercise-state'
import openMemberSideBar from './open-member-side-bar'
import shadowDom from './shadow-dom'

const directives = {
  exerciseState,
  openMemberSideBar,
  shadowDom,
}

export default {
  install(Vue: VueConstructor) {
    for (const directive in directives) {
      Vue.directive(directive, directives[directive as keyof typeof directives])
    }
  },
}
