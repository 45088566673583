import Vue, { unref, computed, type ComputedRef } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import getBreakpoint from '@/scripts/GetBreakpoint.js'

type SupportedAssetsType = 'image' | 'video'
type AssetKeyType = string | undefined

type OptionsType = {
  fetchEnabled: ComputedRef<boolean> | boolean,
}

const defaultOpts: OptionsType = {
  fetchEnabled: true,
}

export default function useExercisesAssets(assetsVersion?: number | 'latest', opts?: OptionsType) {

  const options = {
    ...defaultOpts,
    ...(opts || {}),
  }

  const assetsOk = computed(() => !!data.value && !error.value)

  const exerciseAssetsVersion = assetsVersion ?? 'latest'

  const { data, error, isLoading } = useQuery({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: Infinity,
    queryKey: ['exercise-assets', exerciseAssetsVersion],
    enabled: () => unref(options.fetchEnabled),
    queryFn: async () => {
      const { data } = await Vue.$http('general/getAssets', { version: exerciseAssetsVersion })

      return { baseRemotePath: data.url_pattern, image: data.assets.image, video: data.assets.video }
    },
  })

  function getAssetKeyByPriority(type: SupportedAssetsType, assetObj: Record<string, AssetKeyType>): AssetKeyType {
    const priorityKeyList = {
      video: ['video_key', 'video'],
      image: [
        'constraint_presentation_image_key',
        'step_presentation_image',
        'exercise_presentation_image_key',
        'exercise_presentation_image',
        'side_presentation_image_key',
        'image_key',
        'image',
      ],
    }

    const priorityKeyFound = (priorityKeyList[type] || []).find((key: string) => Object.prototype.hasOwnProperty.call(assetObj, key))

    return priorityKeyFound && assetObj[priorityKeyFound]
  }

  const getAssetPath = (assetType: SupportedAssetsType, source: string | Record<string, AssetKeyType>) => {

    // Real assets key could be given on fn call, or it can be provided an exercise object instead
    const assetKey = typeof source === 'string' ? source : source ? getAssetKeyByPriority(assetType, source) : undefined

    const asset = data.value && assetKey && data.value[assetType][assetKey]

    if (!asset) return ''

    return data.value.baseRemotePath.replace('%s', `${asset.relative_path}${asset.file.file}`)
  }

  const getAsset = (assetType: SupportedAssetsType, source: string | Record<string, AssetKeyType>) => {

    let assetPathToShow: string
    let assetNotFound: boolean = false

    // While still loading assets, show this
    if (isLoading.value || !data.value || error.value) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      assetPathToShow = require(`Images/placeholders/${assetType}_not_found.svg`)
    } else {
      assetPathToShow = getAssetPath(assetType, source)
      assetNotFound = !assetPathToShow
    }

    return {
      notFound: assetNotFound,
      path: assetPathToShow,
    }
  }

  const makeImageAssetStyle = (exercise?: Record<string, AssetKeyType>, isLine?: boolean) => {

    if (isLine) {
      const stylingObj = { width: '', margin: '' }
      const activeBreakpoint = getBreakpoint()

      stylingObj.width = assetsOk.value || activeBreakpoint === 'xxxs' ? '100%' : '46%'
      stylingObj.margin = assetsOk.value ? 'auto' : '29% auto'

      return stylingObj
    }

    if (!exercise) return {}

    const { path: assetPath } = getAsset('image', exercise)
    const backgroundHeightRatio = assetsOk.value ? '100%' : '70%'

    return {
      'background-image': `url(${assetPath})`,
      'background-size': `auto ${backgroundHeightRatio}`,
      'background-repeat': 'no-repeat',
      'background-position': 'center',
    }
  }

  return {
    error,
    assetsList: data,
    assetsOk,
    isLoading,
    // Methods
    getAsset,
    makeImageAssetStyle,
  }
}
