<template>
  <base-feedback-screen-structure v-show="showBaseFeedbackScreen" :animated="animated" :center-content="centerContent" :transparent="transparent">
    <slot></slot>
  </base-feedback-screen-structure>
</template>

<script>
import feedbackScreenProps from '@/mixins/components/feedbackScreenProps'
import BaseFeedbackScreenStructure from '@/components/feedbacks/boilerPlate/BaseFeedbackScreenStructure.vue'

export default {
  name: 'BaseFeedbackScreen',
  components: {
    BaseFeedbackScreenStructure,
  },
  mixins: [feedbackScreenProps],
  data() {
    return {
      showBaseFeedbackScreen: false,
    }
  },
  mounted() {
    this.showBaseFeedbackScreen = true
  },
  destroyed() {
    this.showBaseFeedbackScreen = false
  },
}
</script>
