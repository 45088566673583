import { CONFIG } from '@/scripts/app-configs/constants'

export default function useBroadcastTabChannel(channelName) {
  const bc = new BroadcastChannel(channelName || CONFIG.broadcastChannelName)

  function onMessage(type = 'any', callback = () => null) {
    bc.onmessage = ((messageEvent) => {
      const parsed = JSON.parse(messageEvent.data)

      // eslint-disable-next-line callback-return
      if (parsed.type === type) callback(parsed.message)
    })
  }

  function postMessage(type = 'any', message = 'any') {
    bc.postMessage(JSON.stringify({ type, message }))
  }

  return {
    onMessage,
    postMessage,
  }
}
