import Vue from 'vue'
import compatibilityTools from '@/scripts/tools/compatibility'
import { assignToStruct } from '@/scripts/tools/helper-tools'
import {
  THERAPY_INTERFACE,
  SESSION_INTERFACE,
  PRESCRITPION_FLOW_TYPES,
  prescriptionTypeForPayload,
} from '@/scripts/configs/prescription/prescription-configs'
import genResistancePropagationPayload from '@/scripts/configs/prescription/payload-generators/resistance-propagation-payload'
import genExercisesChangedPayload from '@/scripts/configs/prescription/payload-generators/exercises-changes-payload'
import getDefaultJustifications from '@/scripts/configs/prescription/default-justifications'
import {
  genNewSessionPayload,
  genEditSessionPayload,
  genProtocolPrescriptionPayload,
} from '@/scripts/configs/prescription/payload-generators/sessions-payload'
import i18n from '@/scripts/app-configs/i18n-config'
import protocolPrescription from './protocolPrescriptionStore'

const { getDefaultSessionName } = compatibilityTools

const defaultState = () => {
  return {
    fetching: {
      prescribedSessionsList: null,
      exercisesWithWeightForCategories: null,
      blacklistedExercises: null,
    },
    initialMessage: false,
    sessionsPrescribed: {
      details: {},
      list: [],
    },
    blacklistedExercises: {
      exercises: [],
      total: 0,
    },
    categoryResistancesByExerciseCode: {},
  }
}

export default {
  namespaced: true,
  modules: {
    protocol: protocolPrescription,
  },
  state: defaultState(),
  getters: {
    needFetching: (state) => (context) => state.fetching[context] === null,
    isFetching: (state) => (context, immediate = true) => {
      const considerBaseStatus = immediate && state.fetching[context] === null

      return state.fetching[context] || considerBaseStatus
    },
    isFirstPrescription: (state, getters, rootState) => {
      // TODO: add more logic to this maybe
      return rootState.patient.counters.total_recommendations === null
    },
    getPrescribedSessionById: (state) => (sessionId) => {
      return state.sessionsPrescribed.list.find((s) => s.id === Number(sessionId))
    },
    getPrescribedSessions: (state) => state.sessionsPrescribed.list,
    getPrescribedSessionsDetails: (state) => state.sessionsPrescribed.details,
    getBlacklistedExercises: (state) => state.blacklistedExercises,
    getExerciseCodesWithWeightForCurrentSession: (state) => state.categoryResistancesByExerciseCode,
    getInitialMessage: (state) => state.initialMessage,
  },
  mutations: {
    setAsFetching(state, payload) {
      Object.keys(payload).forEach((prop) => {
        state.fetching[prop] = payload[prop]
      })
    },
    setPrescribedSessionsDetails(state, details) {
      state.sessionsPrescribed.details = details
    },
    setInitialMessage(state, initialMessage) {
      state.initialMessage = initialMessage
    },
    setPrescribedSessions(state, sessions) {
      sessions.forEach((s) => {
        s.custom_name = s.custom_name || getDefaultSessionName(s.name)
      })
      state.sessionsPrescribed.list = sessions
    },
    setExerciseCodesWithResistanceByCategory(state, { category, codes = [] }) {
      if (!state.categoryResistancesByExerciseCode[category]) {
        Vue.set(state.categoryResistancesByExerciseCode, category, [])
      }
      state.categoryResistancesByExerciseCode[category] = codes
    },
    setPrescribedSessionArchiveStates(state, { sessionId, newState }) {
      const targetSession = state.sessionsPrescribed.list.find((s) => s.id === Number(sessionId))

      if (!targetSession) {
        return
      }
      Object.assign(targetSession, { is_active: newState })
    },
    removePrescribedSession(state, sessionId) {
      const targetSessionIndex = state.sessionsPrescribed.list.findIndex((s) => s.id === Number(sessionId))

      if (targetSessionIndex === -1) {
        return
      }
      state.sessionsPrescribed.list.splice(targetSessionIndex, 1)
    },
    resetPrescribedSessions(state) {
      const { sessionsPrescribed: defaultSessionsPrescribedState, fetching: defaultFetchingState } = defaultState()

      Vue.set(state, 'sessionsPrescribed', defaultSessionsPrescribedState)
      Vue.set(state, 'fetching', defaultFetchingState)
    },
    setBlacklistedExercises(state, { exercises = [], total = 0 } = {}) {
      state.blacklistedExercises = { exercises, total }
    },
  },
  actions: {
    async prescribableTherapiesForCurrentPatientConditionOnActiveInstitution({ rootGetters, dispatch }, patientId) {
      let pathologiesList = rootGetters['general/getPathologiesList']
      let institutionTherapies = rootGetters['prescription/therapies/getInstitutionTherapies']
      let patientClinicalData = rootGetters['patient/getClinical']

      if (!pathologiesList.length) {
        pathologiesList = await dispatch('general/fetchPathologiesList', null, { root: true })
      }

      if (!institutionTherapies) {
        await dispatch('prescription/therapies/fetchTherapies', null, { root: true })
        institutionTherapies = rootGetters['prescription/therapies/getInstitutionTherapies']
      }

      if (!Object.values(patientClinicalData.pathology).length) {
        await dispatch('patient/fetchPatientProfile', patientId, { root: true })

        patientClinicalData = rootGetters['patient/getClinical']
      }

      const { pathology } = patientClinicalData

      const { condition: patientConditionName, therapy_name: patientTherapyName } = pathology

      const matchingCondition = pathologiesList.find((p) => {
        const matchingConditionName = p.condition === patientConditionName
        const matchingpTherapyName = p.therapy_name === patientTherapyName

        return matchingConditionName && matchingpTherapyName
      })

      if (!matchingCondition) {
        console.log(`Couldn't find any condition matching '${patientConditionName}'`)

        return []
      }

      return matchingCondition.active_therapies.map((therapyName) => {
        const therapyData = institutionTherapies.find((therapy) => therapy.name === therapyName)

        return therapyData && {
          name: therapyName,
          id: therapyData.id,
        }
      }).filter((c) => !!c)
    },
    async saveSession({ rootGetters, dispatch }) {

      // Getting patient data
      const currentPatient = rootGetters['patient/getMemberBasics']
      const { id: patientId } = currentPatient

      // Flow control variables
      const flowType = rootGetters['prescription/flowType']
      const isEditMode = rootGetters['prescription/isEditMode']
      const addingProtocol = flowType === PRESCRITPION_FLOW_TYPES.ADD_PROTOCOL

      // Session justification vars for payload control
      const trackingIsActive = rootGetters['prescription/trackingIsActive']
      const justificationNeedIsActive = rootGetters['prescription/justificationNeedIsActive']
      const sessionChanges = rootGetters['prescription/justification/getExercisesChanges']
      const hasChangesToJustify = !!Object.keys(sessionChanges).length
      const mustAppendChangesPayload = trackingIsActive && hasChangesToJustify

      // Getting a session replica before manual changes ( existing session AND/OR automatic protocols )
      // Generating new session payload
      const currentSessionData = rootGetters['prescription/currentSession/getCurrentSession']
      const addedExercises = rootGetters['prescription/currentSession/getCurrentExercises'].added
      const removedExercises = rootGetters['prescription/currentSession/getCurrentExercises'].removed
      const reAddedExercises = rootGetters['prescription/currentSession/getCurrentExercises'].reAdded
      const backupPrescriptionExercises = rootGetters['prescription/backupSession/getBackupPrescriptionExercises']
      const goal = rootGetters['prescription/currentSession/getCurrentGoal'] || ''
      const { custom_name: customName } = currentSessionData

      // Formulating generic session changes payload
      let changesJustificationPayload = {}

      if (mustAppendChangesPayload) {
        const changesByExerciseID = rootGetters['prescription/justification/getExercisesChanges']
        const fullExercisesList = rootGetters['prescription/currentSession/getCurrentExercises'].list

        /* if session has changes but no justification is needed for this PT and member, a default justifications object should
        be generated, to match API requirements and keep change data */
        const justifications = justificationNeedIsActive ? rootGetters['prescription/justification/getChangesJustifications']
          : getDefaultJustifications(changesByExerciseID)

        changesJustificationPayload = {
          payload: {
            exercises: genExercisesChangedPayload(justifications, fullExercisesList, changesByExerciseID, backupPrescriptionExercises),
          },
        }
      }

      // Same structure both for add and edit prescriptions
      const { enabled, disabled } = rootGetters['prescription/currentSession/getResistancePropagationTracking']
      const resistancePropagationOnSession = genResistancePropagationPayload({ enabled, disabled })

      if (isEditMode) {
        // Editing sessions goes here
        const sessionId = rootGetters['prescription/currentSession/getSessionId']

        const updateSessionPayload = genEditSessionPayload(addedExercises, customName, goal, removedExercises, reAddedExercises)

        Object.assign(updateSessionPayload, resistancePropagationOnSession)

        return dispatch('prescription/updateSessionPrescription', {
          patientId,
          sessionId,
          updatePayload: updateSessionPayload,
          justificationPayload: mustAppendChangesPayload ? changesJustificationPayload : null,
        }, { root: true })
      }

      // Adding Sessions specifics go here
      const { weekly_frequency: prevWeeklyFrequency } = rootGetters['patient/getCounters']
      const { custom_name: sessionCustomName, weekly_frequency: sessionWeeklyFrequency } = currentSessionData

      /**
       * Getting correct session name
       * If no custom name inserted and we're dealing with protocol prescription, have with protocol default name
       * TODO This deserves an overall fix across portal
       */
      const useProtocolSessionName = !sessionCustomName && addingProtocol
      const sessionDefaultName = useProtocolSessionName ? rootGetters['prescription/currentSession/getProtocolSessionName'] : null
      const sessionDefinitionId = rootGetters['prescription/currentSession/getProtocolSessionId']
      const sessionCategory = rootGetters['prescription/currentSession/getSessionCategory']

      const newSessionPayloadData = {
        patientId,
        addedExercises,
        sessionData: currentSessionData,
        weeklyFrequency: !prevWeeklyFrequency ? sessionWeeklyFrequency : null,
        sessionName: sessionCustomName || sessionDefaultName,
        sessionDefinitionId,
        goal,
        sessionCategory,
      }

      if (!sessionDefinitionId) {
        delete newSessionPayloadData.sessionDefinitionId
      }

      const newSessionPayload = {
        prescription_type: prescriptionTypeForPayload(flowType, mustAppendChangesPayload),
        session: genNewSessionPayload(newSessionPayloadData),
        ...resistancePropagationOnSession,
      }

      if (reAddedExercises.length) {
        newSessionPayload.blacklisted_exercises_removed = reAddedExercises
      }

      if (removedExercises.length) {
        newSessionPayload.blacklisted_exercises_added = removedExercises
      }

      // ADDING PROTOCOL DATA TO PAYLOAD
      let protocolDataPayload = {}

      if (addingProtocol) {
        const status = rootGetters['prescription/currentSession/getProtocolStatus']
        const statusJustification = rootGetters['prescription/currentSession/getProtocolStatusJustification']()
        const filters = rootGetters['prescription/currentSession/getProtocolFilter']()

        const protocolSpecs = {
          protocolName: rootGetters['prescription/currentSession/getProtocolName'],
          protocolSessionName: rootGetters['prescription/currentSession/getProtocolSessionName'],
        }

        protocolDataPayload = genProtocolPrescriptionPayload(status, statusJustification, filters, protocolSpecs, backupPrescriptionExercises)
      }

      const dptActivityUuid = rootGetters['dptActivity/getDptActivityUuid']

      return dispatch('prescription/addSessionPrescription', {
        patientId,
        sessionPayload: newSessionPayload,
        justificationPayload: mustAppendChangesPayload ? changesJustificationPayload : null,
        protocolPayload: addingProtocol ? protocolDataPayload : null,
        dptActivityUuid,
      }, { root: true })

    },
    async fetchPrescribedSessions({ rootGetters, commit }, patientID) {
      try {
        commit('setAsFetching', { prescribedSessionsList: true })
        const { data } = await Vue.$http('prescription/patient/fetchPrescribedSessions', patientID)
        const { information: sessionsDetails, sessions } = data

        commit('setPrescribedSessionsDetails', sessionsDetails)
        commit('setPrescribedSessions', sessions)
        commit('setAsFetching', { prescribedSessionsList: false })

        commit('setInitialMessage', data.information.prescription_initial_message)

        const patientIsReady = rootGetters['patient/isReady']

        if (patientIsReady) {
          const totalActiveSessions = sessions.filter((s) => s.is_active).length

          commit('patient/updateCounters', { 'total_prescriptions': totalActiveSessions }, { root: true })
        }
      } catch (e) {
        console.log('Error getting patient prescribed sessions.', e)
      }
    },
    async fetchExistingSessionExercises(_, { patientId, sessionId, therapySide }) {
      try {
        const params = { patientId, sessionId, therapySide: therapySide === 'both' ? undefined : therapySide }
        const { data } = await Vue.$http('prescription/patient/getPrescribedSessionExercises', params)
        const { exercises, blacklisted, filters, therapy_exercise_positions: positions, session } = data

        const fetchedTherapyData = {
          id: session.therapy_id,
          filters,
          positions,
          exercises,
          removedExercises: blacklisted,
        }

        const fetchedSessionData = {
          id: session.id,
          therapyId: session.therapy_id,
          name: session.name,
          customName: session.custom_name,
          exercises: session.exercises,
          lastExercisesConfigDate: session.exercises_configurations_last_updated_at,
          goal: session.goal,
        }

        return {
          therapy: assignToStruct(THERAPY_INTERFACE, fetchedTherapyData),
          session: assignToStruct(SESSION_INTERFACE, fetchedSessionData),
        }
      } catch (error) {
        console.log(`Error session exercises. sessionID: ${sessionId} patientID: ${patientId}`, error)

        return error
      }
    },
    async fetchExercisesWithResistanceForCategories({ commit, rootGetters }) {
      try {
        commit('setAsFetching', { exercisesWithWeightForCategories: true })
        const patientId = rootGetters['patient/getMemberBasics'].id
        const {
          data: {
            session_exercises_resistance_enabled: sessionExercisesResistanceEnabled,
          },
        } = await Vue.$http('prescription/patient/fetchExercisesWithResistanceForCategories', patientId)

        Object.entries(sessionExercisesResistanceEnabled).forEach(([category, codes]) => {
          commit('patient/prescription/setExerciseCodesWithResistanceByCategory', { category, codes }, { root: true })
        })
      } catch (e) {
        console.error('Error fetching exercises with weight for session categories', e)
      } finally {
        commit('setAsFetching', { exercisesWithWeightForCategories: true })
      }
    },
    async fetchBlacklistedExercises({ commit, rootGetters }, { offset, limit, sortDir, therapyId }) {
      const patientId = rootGetters['patient/getMemberBasics'].id

      try {
        commit('setAsFetching', { blacklistedExercises: true })
        const requestParams = { offset, limit, patientId, sortDir, therapyId }
        const { data } = await Vue.$http('prescription/patient/fetchBlacklistedExercises', requestParams)

        commit('setBlacklistedExercises', data)
      } catch (error) {
        console.error('[pp-blacklist-exercises] Error fetching blacklisted exercises list.', error)
      } finally {
        commit('setAsFetching', { blacklistedExercises: false })
      }
    },
    async removeExerciseFromBlacklist({ commit, getters, rootGetters }, exerciseCode) {
      const blacklistedExercises = getters.getBlacklistedExercises
      const patientId = rootGetters['patient/getMemberBasics'].id

      try {
        const payload = { exerciseCodes: [exerciseCode] }

        await Vue.$http('prescription/patient/removeExerciseFromBlacklist', patientId, { body: payload })
        const updatedBlacklistedExercises = blacklistedExercises.exercises.filter(({ code }) => code !== exerciseCode)

        commit('setBlacklistedExercises', { exercises: updatedBlacklistedExercises, total: blacklistedExercises.total - 1 })

        return Promise.resolve(true)
      } catch (error) {
        console.error(`[pp-blacklist-exercises] Error removing exercise with code ${exerciseCode} from blacklist.`, error)

        return Promise.reject(error)
      }
    },
    async sendMessage({ rootGetters, commit }, messageContent) {
      const { id: memberId, name: memberName } = rootGetters['patient/getMemberBasics']

      try {
        await Vue.$http('prescription/message/initialMessage', { patientId: memberId }, { body: { message: messageContent } })
        commit('setInitialMessage', true)
        Vue.$notify.success(i18n.t('initial_prescription.relationship_message.email_sent', { member_name: memberName }))
      } catch (error) {
        console.error('Error while sending message', error)
      }
    },
  },
}
