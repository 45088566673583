import Vue from 'vue'
import { THERAPY_INTERFACE } from '@/scripts/configs/prescription/prescription-configs'
import { assignToStruct } from '@/scripts/tools/helper-tools'
/**
 *  Managing institution therapies
 */

export default {
  namespaced: true,
  state: {
    institutionTherapies: [],
    /*
     *  Store therapy exercise by ID (keys) and side (deep key)
     * {
     *    <id> { <side> : <exercises> }
     *    ...
     * }
     */
    therapyExercisesInfoByTherapyID: {},
  },
  getters: {
    getInstitutionTherapies: (state) => state.institutionTherapies.length ? state.institutionTherapies : null,
    getInstitutionTherapyByID: (state) => (id) => state.institutionTherapies.find((t) => t.id === id),
    getInstitutionTherapyByCode: (state) => (code) => state.institutionTherapies.find((t) => t.code === code),
    getTherapyExercisesData: (state) => (therapyID, therapySide) => {
      const therapyIdObject = state.therapyExercisesInfoByTherapyID[therapyID]

      return therapyIdObject && therapyIdObject[therapySide] ? therapyIdObject[therapySide] : null
    },
    getRecommendedTherapy: (state, getters, rootState, rootGetters) => {
      const patientPathology = rootGetters['patient/getClinical'].pathology

      return state.institutionTherapies?.find((therapy) => therapy.name === patientPathology.therapy_name)
    },
  },
  mutations: {
    setAvailableTherapies(state, therapiesList) {
      Vue.set(state, 'institutionTherapies', therapiesList)
    },
    setTherapyExercises(state, { id: therapyId, side: therapySide, ...exercisesInfo }) {
      const hasTherapyEntry = therapyId in state.therapyExercisesInfoByTherapyID

      if (!hasTherapyEntry) {
        Vue.set(state.therapyExercisesInfoByTherapyID, therapyId, {})
      }
      const hasTherapySideEntry = therapySide in state.therapyExercisesInfoByTherapyID[therapyId]

      if (!hasTherapySideEntry) {
        Vue.set(state.therapyExercisesInfoByTherapyID[therapyId], therapySide, [])
      }
      Vue.set(state.therapyExercisesInfoByTherapyID[therapyId], therapySide, Object.freeze(exercisesInfo))
    },
  },
  actions: {
    async fetchTherapies({ commit }, kitName) {
      try {
        const { data } = await Vue.$http('prescription/therapies/fetchTherapies', { kitName })

        commit('setAvailableTherapies', data.therapies)
      } catch (e) {
        console.error(`Error getting therapies. Kit name param was: ${kitName}.`, e)
      }
    },
    async fetchTherapyExercises({ getters, commit }, { therapyId, therapySide }) {

      const therapyExercisesData = getters.getTherapyExercisesData(therapyId, therapySide)

      if (therapyExercisesData) {
        return therapyExercisesData
      }
      const requestObj = {
        therapy: {
          id: therapyId,
          side: therapySide === 'both' ? undefined : therapySide,
        },
        includeLevels: true,
        includeAssets: true,
      }

      try {
        const { data } = await Vue.$http('prescription/therapies/getTherapyExercises', requestObj)

        const {
          exercises: therapyExercises,
          filters: therapyExercisesFilters,
          therapy_exercise_positions: therapyExercisesPositions,
        } = data

        const fetchedTherapyData = assignToStruct(THERAPY_INTERFACE, {
          id: therapyId,
          side: therapySide,
          filters: therapyExercisesFilters,
          positions: therapyExercisesPositions,
          exercises: therapyExercises,
        })

        commit('setTherapyExercises', fetchedTherapyData)

        return fetchedTherapyData
      } catch (e) {
        console.error(`Error fetching exercises of therapy. ID: ${therapyId} SIDE: ${requestObj.therapy.side}`, e)

        return e
      }
    },
  },
}
