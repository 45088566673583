<template>
  <feed-item
    :is-new="isNew"
    :vip="recommendation.patient.is_vip_account"
    :gender="recommendation.patient.gender"
    :picture="recommendation.patient.picture"
    @picture-click="openInNewTab('profile.board', { patientID: recommendation.patient.id })"
    @item-click="openInNewTab('profile.sessions', { patientID: recommendation.patient.id })"
  >
    <i18n :path="outcomeTranslation.copy" :places="outcomeTranslation.copyParams" tag="p" class="recommendation__text">
      <button class="button-link" place="link" @click.stop="$emit('view-recommendation-reasons')">
        {{ $t('copy_53').toLowerCase() }}
      </button>
    </i18n>
  </feed-item>
</template>

<script>
import FeedItem from '@/components/feed-item.vue'

// Configs
import { RECOMMENDATION_OUTCOME, RECOMMENDATION_STATUS } from '@/scripts/configs/recommendations'

// Composables
import useOpenNewTab from '@/composables/use-open-new-tab'

export default {
  name: 'RecommendationItem',
  components: {
    FeedItem,
  },
  props: {
    recommendation: {
      type: Object,
    },
  },
  setup() {
    const openInNewTab = useOpenNewTab()

    return {
      openInNewTab,
    }
  },
  computed: {
    isNew() {
      return this.recommendation.status === RECOMMENDATION_STATUS.NEW
    },
    outcomeTranslation() {
      const outcome = {
        copy: '',
        copyParams: {
          memberName: `${this.recommendation.patient.firstname} ${this.recommendation.patient.lastname}`,
          currentPhase: this.recommendation.phase_name,
          nextPhase: this.recommendation.next_phase_name,
        },
      }

      switch (this.recommendation.output) {
        case RECOMMENDATION_OUTCOME.OUTPUT_PROGRESS:
          outcome.copy = 'recommendationProgress'
          break
        case RECOMMENDATION_OUTCOME.OUTPUT_HOLD:
          outcome.copy = 'recommendationPutOnHold'
          break
        default:
          outcome.copy = ''
      }

      return outcome
    },
  },
}
</script>

<style lang="scss" scoped>
.recommendation {
  &__text {
    line-height: 1.375rem;
  }
}
</style>
