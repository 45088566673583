export default {
  list: {
    api: {
      url: 'default',
      version: '2',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      mock: (_, { state }) => `mocks/endpoints/patientsFunnel/list/${state}.json`,
    },
    $$makeUrl(configs, {
      state,
      offset = null,
      limit = null,
      search = '',
      searchType = '',
      sortBy = '',
      sortDir = '',
      filters = '',
      ...params
    }) {
      const url = `therapist/v${configs.api.version}/patients/list/${state}`
      const queryParams = new URLSearchParams(params)

      const paramMappings = [
        { key: 'offset', value: offset },
        { key: 'limit', value: limit },
        { key: 'search', value: search },
        { key: 'search_type', value: search ? searchType : '' },
        { key: 'sort_by', value: sortBy },
        { key: 'sort_dir', value: sortDir },
        { key: 'filters', value: filters },
      ]

      paramMappings
        .filter(({ value }) => value || value === 0)
        .forEach(({ key, value }) => queryParams.set(key, value))

      return `${url}?${queryParams}`

    },
  },
  totals: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      mock: () => 'mocks/endpoints/patientsFunnel/totals.json',
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    $$makeUrl(configs, { search = '', searchType = '', filters = '', enrollmentDays = null, ...params }) {
      const url = `therapist/v${configs.api.version}/patients/list/totals`
      const queryParams = new URLSearchParams(params)

      if (searchType && search) {
        queryParams.set('search_type', searchType)
        queryParams.set('search', search)
      }

      if (filters) {
        queryParams.set('filters', filters)
      }

      return `${url}?${queryParams}`
    },
  },
  todos: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      mock: () => 'mocks/endpoints/patientsFunnel/todos.json',
    },
    $$makeUrl(configs, { patientIds = [] }) {
      const url = `therapist/v${configs.api.version}/patients/list/todos`
      const queryParams = new URLSearchParams('')

      if (patientIds) {
        queryParams.set('patients', patientIds.join())
      }

      return `${url}?${queryParams}`
    },
  },
}
