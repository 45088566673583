import VuexPersist from 'vuex-persist'
import { SYSTEM_STORAGE } from '@/scripts/app-configs/constants'

const systemStorage = new VuexPersist({
  key: SYSTEM_STORAGE.key, // Store key
  storage: localStorage,
  reducer: (state) => {
    return {
      system: state.system,
    }
  },
  filter: (mutation) => {
    const systemRegex = new RegExp(/^system\//)

    return systemRegex.test(mutation.type) && mutation.type !== 'system/setAppVersion'
  },
})

const systemStoragePlugin = systemStorage

export default systemStoragePlugin
