import i18n from '@/scripts/app-configs/i18n-config'

function monthName(monthRef: number) {
  const allMonths = [
    {
      name: 'January',
      translatedname: i18n.t('CALENDAR.JANUARY'),
    },
    {
      name: 'February',
      translatedname: i18n.t('CALENDAR.FEBRUARY'),
    },
    {
      name: 'March',
      translatedname: i18n.t('CALENDAR.MARCH'),
    },
    {
      name: 'April',
      translatedname: i18n.t('CALENDAR.APRIL'),
    },
    {
      name: 'May',
      translatedname: i18n.t('CALENDAR.MAY2'),
    },
    {
      name: 'June',
      translatedname: i18n.t('CALENDAR.JUNE'),
    },
    {
      name: 'July',
      translatedname: i18n.t('CALENDAR.JULY'),
    },
    {
      name: 'August',
      translatedname: i18n.t('CALENDAR.AUGUST'),
    },
    {
      name: 'September',
      translatedname: i18n.t('CALENDAR.SEPTEMBER'),
    },
    {
      name: 'October',
      translatedname: i18n.t('CALENDAR.OCTOBER'),
    },
    {
      name: 'November',
      translatedname: i18n.t('CALENDAR.NOVEMBER'),
    },
    {
      name: 'December',
      translatedname: i18n.t('CALENDAR.DECEMBER'),
    },
  ]

  if (typeof monthRef === 'string') {
    return allMonths.find((month) => month.name === monthRef)
  }

  return allMonths[monthRef - 1]
}

export default monthName
