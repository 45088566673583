import Vuelidate from '@sword-health/vuelidate'
import vuelidateErrorExtractor from 'vuelidate-error-extractor'

import inputErrorMessage from '@/components/inputs/input-error-message.vue'
import InputErrorWuk from '@sword-health/web-ui-kit/src/components/inputs/footnotes/InputError.vue'

const vuelidateErrorExtractorOpts = {

  /**
   * Optionally provide the template in the configuration.
   * or register like so Vue.component("FormField", templates.singleErrorExtractor.foundation6)
   */
  name: 'inputErrorMessage',
  template: inputErrorMessage, // you can also pass your own custom template
  i18n: '', // Path to validation messages. Can be deeply.nested.property.
  messages: {
    required: 'VALIDATIONS.required',
    minLength: 'VALIDATIONS.minLength',
    daysOnHold: 'VALIDATIONS.daysOnHold',
    maxLength: 'VALIDATIONS.maxLength',
    invalidCharacter: 'VALIDATIONS.invalidCharacter',
    validUrl: 'VALIDATIONS.invalidUrl',
    validTrackingUrl: 'VALIDATIONS.invalidUrl',
    alreadyHasCard: 'api.error.10320',
    cardAlreadyAssigned: 'api.error.10310',
    invalidCard: 'api.error.10301',
    invalidEmail: 'VALIDATIONS.invalidEmail',
    sameAsPassword: 'COMMON.PASSWORDS_DO_NOT_MATCH',
    emailFormat: 'VALIDATIONS.invalidEmail',
    usernameFormat: 'VALIDATIONS.INVALID_USERNAME',
    easyPass: 'VALIDATIONS.easyPass',
    isRegex: 'VALIDATIONS.isRegex',
    validDay: 'VALIDATIONS.validDay',
    day: 'VALIDATIONS.validDay',
    validYear: 'VALIDATIONS.validYear',
    year: 'VALIDATIONS.validYear',
    dayRange: 'VALIDATIONS.dayRange',
    monthRange: 'VALIDATIONS.monthRange',
    yearRange: 'VALIDATIONS.yearRange',
    isPastDate: 'VALIDATIONS.isPastDate',
    FutureDate: 'VALIDATIONS.FutureDate',
    isNotFuture: 'VALIDATIONS.isNotFuture',
    isFutureDate: 'VALIDATIONS.isFutureDate',
    isFuture: 'VALIDATIONS.PastDate',
    PastDate: 'VALIDATIONS.PastDate',
    phoneFormat: 'VALIDATIONS.phoneFormat',
    internationalPhoneFormat: 'VALIDATIONS.phoneFormat',
    prefixAndPhoneRequired: 'VALIDATIONS.required',
    prefix: 'VALIDATIONS.required',
    minLimit: 'VALIDATIONS.minLimit',
    maxLimit: 'VALIDATIONS.maxLimit',
  },
}

export {
  Vuelidate,
  vuelidateErrorExtractor,
  vuelidateErrorExtractorOpts,
  InputErrorWuk,
}
