/**
 * Setting up translations configs.
 * Language imports and bootstrap config are done here
 * @module Language Configs
 */

import { language as languagesTools } from '@sword-health/ui-core'
import * as config from '@/scripts/app-configs/config'
import languageConfig from './language-helper'

const preferredLanguage = languageConfig()
const languagesFolderContext = require.context('../../../languages', true, /\.json$/)

const languagesConfigs = languagesTools.swordVueLanguages(languagesFolderContext, preferredLanguage, config.get('LANGUAGE_FALLBACK'))

export { languagesConfigs, preferredLanguage }
