function isValidJSONstring(str) {
  try {
    const json = JSON.parse(str)

    return typeof json === 'object'
  } catch (e) {
    return false
  }
}

class StorageInterface {
  constructor(storeToUse = localStorage) {
    this.contextStore = storeToUse
  }

  get log() {
    return this.contextStore
  }

  read(key) {
    const readValue = this.contextStore.getItem(key)

    return isValidJSONstring(readValue) ? JSON.parse(readValue) : readValue
  }

  write(key, src) {
    let value = src

    if (typeof value === 'object') {
      const readValue = this.read(key)

      if (readValue && typeof readValue === 'object') {
        value = Object.assign(readValue, value)
      }
      value = JSON.stringify(value)
    }
    this.contextStore.setItem(key, value)
  }

  delete(key) {
    this.contextStore.removeItem(key)
  }
}

export default StorageInterface
