<template>
  <transition v-if="isModalOpen" name="feed">
    <div class="modal-backdrop feed-backdrop" @click="$emit('close-modal')">
      <div class="feed" @click.stop>
        <header class="feed__header">
          <h4>{{ $t(title) }} {{ totalItems }}</h4>
          <slot name="header-right"></slot>
          <sword-button-wuk
            theme="text"
            round
            size="small"
            icon="close"
            @click.native="$emit('close-modal')"
          />
        </header>
        <main class="feed__content">
          <loading-screen v-if="isFetching && !showItems" />
          <ul v-else-if="showItems" class="feed__list">
            <slot></slot>
            <li v-if="showLoadMore" key="load-more" class="center-text feed__load-more">
              <infinite-scroll @load-more="$emit('load-more')">
                <pulse-loader v-if="isFetching" />
              </infinite-scroll>
            </li>
          </ul>
          <empty-list-screen v-else :text="noItemsText" class="-py_6" />
        </main>
      </div>
    </div>
  </transition>
</template>

<script>
// Components
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue'

import EmptyListScreen from '@/components/feedbacks/EmptyListScreen.vue'
import InfiniteScroll from '@/components/infinite-scroll.vue'
import LoadingScreen from '@/components/feedbacks/LoadingScreen.vue'
import pulseLoader from '@/components/feedbacks/loaders/pulseLoader.vue'

export default {
  name: 'Feed',
  components: {
    EmptyListScreen,
    InfiniteScroll,
    LoadingScreen,
    pulseLoader,
    SwordButtonWuk,
  },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    isFetching: {
      type: Boolean,
      default: false,
    },
    showLoadMore: {
      type: Boolean,
      default: false,
    },
    showItems: {
      type: Boolean,
      default: false,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    noItemsText: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
$feed-header-height: 5rem;

.modal-backdrop.feed-backdrop {
  background-color: transparent;
  z-index: 25;
}

.feed-enter,
.feed-leave-to {
  opacity: 0;
  transform: translateX(110%);
}

.feed-enter-to,
.feed-leave {
  opacity: 1;
  transform: translateX(0);
}

.feed-enter-active {
  transition: all 0.3s ease-out;
}

.feed-leave-active {
  transition: all 0.1s ease-in;
}

.feed {
  position: fixed;
  top: 3.1rem;
  right: 2.5rem;
  width: 27rem;
  height: calc(100% - 4.65rem);
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 0.25rem;
  @include x-default-shadow();

  &__header {
    border-bottom: 1px solid $context-color-6;
    padding: 0 1rem;
    height: $feed-header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      margin: 0;
    }
  }

  .sword-button-wuk {
    display: none;
  }

  &__content {
    height: 100%;
    max-height: calc(100% - $feed-header-height);
    position: relative;
    overflow-y: auto;
  }

  &__list {
    height: 100%;
  }

  &__load-more {
    height: 5rem;
  }

  ::v-deep {
    .full-screen-feedback-container {
      p {
        @extend %-t_medium;
        margin-top: 0 !important;
        color: revert;
        font-size: revert;
      }
    }
  }

  @media screen and (max-width: $max-smartphone-size) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;

    .sword-button-wuk {
      display: block;
    }
  }
}
</style>
