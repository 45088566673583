import FloatingVue from 'floating-vue'

// https://floating-vue.starpad.dev/theme-editor
export const floatingVueOptions = {
  themes: {
    'sword-tooltip': {
      distance: 10,
      $extend: 'tooltip',
      delay: { show: 0, hide: 0 },
    },
  },
  defaultTheme: 'sword-tooltip',
}

export default FloatingVue
