/**
 * Sort plain or objects array numerically filter
 * @name SortNumeric Utils filter
 * @returns {array} Numerically sorted Array
 */

type SortableItem = number | { [key: string]: number };

export const sortNumeric = <T extends SortableItem>(arrayToSort: T[], sortByKey?: string, orderBy: 'asc' | 'desc' = 'asc'): T[] => {
  const isSortable = Array.isArray(arrayToSort)
  const plainNumArray = isSortable && arrayToSort.every((element) => typeof element === 'number')

  if (!isSortable || (!plainNumArray && !sortByKey)) {
    console.warn('[sort-numeric-filter] Unable to numerically sort provided source')

    return arrayToSort
  }

  return arrayToSort.sort((a: T, b: T) => {
    const aVal = plainNumArray ? a as number : (a as { [key: string]: number })[sortByKey || ''] || 0
    const bVal = plainNumArray ? b as number : (b as { [key: string]: number })[sortByKey || ''] || 0

    return orderBy === 'asc' ? aVal - bVal : bVal - aVal
  })
}

export default sortNumeric
