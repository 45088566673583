// WIP error handler util
export const errorSafeHandler = (e, responseBodyProp = 'response') => {
  const serverAbleToRespond = e && e[responseBodyProp]
  const { data } = serverAbleToRespond || {}
  const { error_code: errorCode, error_description: errorDescription } = data || {}
  const isLocalError = !e || !e.config || !e.config.url

  const errorLevel = {
    network: !isLocalError && serverAbleToRespond === undefined,
    api: !!serverAbleToRespond,
    local: isLocalError,
  }

  return {
    e,
    errorLevel,
    data,
    hasData: data && !!Object.keys(data).length,
    errorCode,
    errorDescription,
  }
}
