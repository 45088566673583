<template>
  <feed-item
    :notification-type="notification.type"
    :is-new="!notification.is_read"
    :vip="notification.metadata.patient_is_vip_account"
    :picture="notification.metadata.picture"
  >
    <div>
      <p>{{ $t(notificationMessage) }} {{ patientName }}</p>
      <p v-if="isProgramSummarySuccess">
        <a class="link" :href="clinicalRecordUrl" target="_blank">{{ $t("summary_pdf.button.view_pdf") }}</a>
      </p>
      <time class="t3 fc-ctx-5" :datetime="notification.updated_at">{{ formattedNotificationDate }}</time>
    </div>
  </feed-item>
</template>

<script>
import FeedItem from '@/components/feed-item.vue'

// utils
import { getFormattedTimeAnnotation } from '@/scripts/utils'

export default {
  name: 'NotificationItemProgramSummary',
  components: {
    FeedItem,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isProgramSummarySuccess() {

      return this.notification?.metadata?.value === 'PROGRAM_SUMMARY_COMPLETED'
    },
    patientName() {
      const firstName = this.notification.metadata?.first_name
      const lastName = this.notification.metadata?.last_name || ''
      const programId = this.notification.metadata?.program_id
      const programUuid = this.notification.metadata?.program_uuid

      return firstName ? `${firstName} ${lastName}` : programId ? programId : programUuid ? programUuid : 'Unknown'
    },
    notificationMessage() {
      switch (this.notification?.metadata?.value) {
        case 'PROGRAM_SUMMARY_COMPLETED':
          return 'summary_pdf.notification.email_sent'
        default:
          return 'summary_pdf.notification.email_failed'
      }
    },
    clinicalRecordUrl() {

      return this.notification?.metadata?.clinical_record_url
    },
    formattedNotificationDate() {
      const annotation = getFormattedTimeAnnotation(this.$date(this.notification.updated_at))

      return `${this.$date().defaultDiff(this.notification.updated_at)}, ${annotation} `
    },
  },
}
</script>

<style lang="scss" scoped>
.link {
  color: $context-color-1;
  font-weight: 500;
  transition: all 100ms linear;

  &:hover {
    color: $color-primary-500;
  }
}
</style>
