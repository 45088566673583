import { deepCopyObj } from '@/scripts/tools/helper-tools'

export default (therapyExercises, previousPrescriptionExercises) => {

  let exercisesToAdd = []
  let maxOrderExercise = 1
  let totalAddedExercises = 0

  // Prefill exercises lists to replicate previous prescription
  const fullExercisesList = therapyExercises.map((therapyExercise) => {
    let exerciseToEdit = therapyExercise

    // Find current exercise on previous prescribed exercises
    const prevExercise = previousPrescriptionExercises.find((ex) => ex.id === exerciseToEdit.id)

    // Exercise hasn't been prescribed. Just set as un-prescribed
    if (!prevExercise) {
      exerciseToEdit.series = 0

      return exerciseToEdit
    }

    /**
     * If previous exercise prescription was found...
     * Apply all previous prescription changes, plus
     * Get number of exercise instances in session and also update the list
     */

    // Get all instances of the current exercise that were prescribed
    const exerciseInstances = previousPrescriptionExercises.filter((ex) => ex.id === prevExercise.id)

    // Update exercise with previous prescription and ensure number of series prescribed
    exerciseToEdit = Object.assign(exerciseToEdit, prevExercise, { series: exerciseInstances.length })

    // Create an exercise entry for each time exercise was prescribed
    const exerciseToAdd = exerciseInstances.map((instance) => {

      if (maxOrderExercise < instance.order) {
        maxOrderExercise = instance.order
      }

      const exerciseCopy = deepCopyObj(exerciseToEdit)

      Object.assign(exerciseCopy, instance, {
        series: 1, // Force exercise series to be 1, as they're replicated
        order: instance.order || maxOrderExercise, // If not ordered use last position
      })

      return exerciseCopy
    })

    exercisesToAdd = exercisesToAdd.concat(exerciseToAdd)
    totalAddedExercises += exerciseToEdit.series

    return exerciseToEdit
  })

  return {
    maxOrder: maxOrderExercise,
    numOfExercises: totalAddedExercises,
    exercisesList: fullExercisesList,
    exercisesAdded: exercisesToAdd,
  }
}
