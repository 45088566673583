<template>
  <base-feedback-screen :animated="false" transparent>
    <img class="mt-2 mb-3" :src="img" :alt="alternativeImgText" />
    <p v-if="text" class="t1 -mt_3 -mb_0 -mb_0 -px_2 center-text" :class="{ '-t_medium fc-primary': bold, 'fc-secondary': !bold }">
      {{ $t(text) }}
    </p>
  </base-feedback-screen>
</template>

<script>
import i18n from '@/scripts/app-configs/i18n-config'
import baseFeedbackScreen from '@/components/feedbacks/boilerPlate/BaseFeedbackScreen.vue'

export default {
  name: 'EmptyListScreen',
  components: {
    baseFeedbackScreen,
  },
  props: {
    img: {
      type: String,
      default: require('Images/feedback-screens/empty-list.svg'),
    },
    description: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    alternativeImgText() {
      if (this.description) {
        return this.description
      }

      return this.text ? i18n.t(this.text) : 'Empty list'
    },
  },
}
</script>
