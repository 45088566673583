import Vue from 'vue'

import ChangeTracker from '@/scripts/configs/prescription/change-tracker'

/**
 *  Managing Prescription Changes tracking
 *  Apart from this model, there should only be calls to it and the global subscriber for the generic tracking
 */

export default {
  namespaced: true,
  state: {
    hasTrackedChanges: false,
    sessionChangesByExerciseID: {}, // Where session changes are being stored
    justificationsByExerciseID: {}, // Storing each change justification
  },
  getters: {
    hasTrackedChanges: (state) => state.hasTrackedChanges,
    getExercisesChanges: (state) => state.sessionChangesByExerciseID,
    getChangesJustifications: (state) => state.justificationsByExerciseID,
  },
  mutations: {
    discardJustificationsByExerciseID(state) {
      Vue.set(state, 'justificationsByExerciseID', {})
    },
    justifyChange(state, { id, prop, value }) {
      if (!state.justificationsByExerciseID[id]) {
        Vue.set(state.justificationsByExerciseID, id, {
          reason: '',
          explanation: '',
          portal_based: false,
        })
      }
      Vue.set(state.justificationsByExerciseID[id], prop, value)
    },
    setExerciseChange(state, { id, payload }) {
      if (!state.sessionChangesByExerciseID[id]) {
        Vue.set(state.sessionChangesByExerciseID, id, {})
      }
      Object.assign(state.sessionChangesByExerciseID[id], payload)
    },
    resetTrackedChanges(state) {
      state.hasTrackedChanges = false
      Vue.set(state, 'sessionChangesByExerciseID', {})
    },
    untrackAllExerciseChangesById(state, exerciseId) {
      delete state.sessionChangesByExerciseID[exerciseId]
    },
    removeJustificationByExerciseID(state, exerciseID) {
      delete state.justificationsByExerciseID[exerciseID]
    },
    refreshIfHasTrackedChanges(state) {
      state.hasTrackedChanges = !!Object.keys(state.sessionChangesByExerciseID).length
    },
  },
  actions: {
    storeExerciseChange({ commit, getters, rootGetters }, { id, payload }) {
      // Currently stored changes on and exercise
      const currentChangesOnExercise = getters.getExercisesChanges[id]
      // Get reference exercise prescription IF existed. Undefined when not previously prescribed
      const prevExercisePrescription = rootGetters['prescription/backupSession/getBackupReferenceExerciseById'](id)
      // Get base exercises list before any change applied
      const prevPrescribedSessionExercises = rootGetters['prescription/backupSession/getBackupPrescriptionExercises']

      const tracker = new ChangeTracker(id, prevPrescribedSessionExercises, prevExercisePrescription, currentChangesOnExercise, payload)

      tracker.run()
      const { trackedChanges } = tracker

      if (!trackedChanges) {
        commit('untrackAllExerciseChangesById', id)
        commit('refreshIfHasTrackedChanges')

        return
      }
      commit('setExerciseChange', {
        id,
        payload: trackedChanges,
      })
      commit('refreshIfHasTrackedChanges')

    },
  },
}
