export default () => {
  const windowWidth = window.innerWidth

  if (windowWidth < 395) {
    return 'xxxs'
  }
  if (windowWidth < 575) {
    return 'xxs'
  }
  if (windowWidth < 768) {
    return 'xs'
  }
  if (windowWidth < 992) {
    return 'sm'
  }
  if (windowWidth < 1200) {
    return 'md'
  }
  if (windowWidth < 1600) {
    return 'lg'
  }

  return 'xlg'
}
