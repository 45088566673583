import Vue from 'vue'
import { NOTIFY_ALIAS, toast } from '@ui-kit/scripts/configs/notify-config'

Vue[NOTIFY_ALIAS] = toast
Object.defineProperties(Vue.prototype, {
  [NOTIFY_ALIAS]: {
    get() {
      return toast
    },
  },
})
