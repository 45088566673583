import Vue from 'vue'
import { CONFIG } from '@/scripts/app-configs/constants'
import { deepCopyObj } from '@/scripts/tools/helper-tools'
import { PRESCRIPTION_MODES, PRESCRITPION_FLOW_TYPES } from '@/scripts/configs/prescription/prescription-configs'
import prefillExistingSession from '@/scripts/configs/prescription/exercises-list-prefills/prefill-existing-session'
import prefillNewCustomSession from '@/scripts/configs/prescription/exercises-list-prefills/prefill-new-custom-session'
import prefillNewProtocolSession from '@/scripts/configs/prescription/exercises-list-prefills/prefill-new-protocol-session'

import currentSession from './prescription/currentSessionStore'
import backupSession from './prescription/backupSessionStore'
import protocols from './prescription/protocolsStore'
import therapies from './prescription/therapiesStore'
import justification from './prescription/justificationStore'
import recommendations from './prescription/recommendations'

export default {
  namespaced: true,
  state: {
    ready: false,
    trackingIsActive: false,
    hideRomReferences: CONFIG.environment === 'uk',
    mode: '',
    fetching: {
      sessionCategories: {
        state: null,
        message: 'Fetching categories',
      },
      sessions: {
        state: null,
        message: '',
      },
      session: {
        state: null,
        message: '',
      },
      exercises: {
        state: null,
        message: 'copy_2022',
      },
      recommendations: {
        state: null,
        message: 'copy_2582',
      },
    },
    saving: {
      sessions: {
        state: false,
        message: '',
      },
      session: {
        state: false,
        message: 'copy_2029',
      },
      exercises: {
        state: false,
        message: '',
      },
      recommendations: {
        state: false,
        message: 'copy_2589',
      },
    },
    institutionTherapies: [],
    toggles: {
      prescription_justification: true,
    },
    prescriptionsUpdatedDuringNavigation: new Set(),
  },
  getters: {
    isReady: (state) => state.ready,
    getMode: (state) => state.mode,
    hideRomReferences: (state) => state.hideRomReferences,
    flowType: (state, getters) => {
      if (state.mode === PRESCRIPTION_MODES.ADD) {
        const isProtocolPrescription = getters['currentSession/isProtocolPrescription']

        return isProtocolPrescription ? PRESCRITPION_FLOW_TYPES.ADD_PROTOCOL : PRESCRITPION_FLOW_TYPES.ADD_CUSTOM
      }
      if (state.mode === PRESCRIPTION_MODES.EDIT) {
        return PRESCRITPION_FLOW_TYPES.EDIT_CUSTOM
      }

      return null
    },
    trackingIsActive: (state, getters) => {
      const { flowType } = getters
      const justificationToggleActive = getters.getToggle('prescription_justification')
      const shouldTrackFlowType = PRESCRITPION_FLOW_TYPES.ADD_PROTOCOL === flowType || PRESCRITPION_FLOW_TYPES.EDIT_CUSTOM === flowType

      return justificationToggleActive && shouldTrackFlowType
    },
    justificationNeedIsActive(state, getters, _, rootGetters) {
      const { isAIPt, hasProviderEnabled } = rootGetters['user/flags']
      const isServiceTypePtProvider = rootGetters['patient/getMemberBasics']?.serviceType === 'pt_provider'

      return isAIPt || hasProviderEnabled || isServiceTypePtProvider
    },
    isAddMode: (state) => state.mode === PRESCRIPTION_MODES.ADD,
    isEditMode: (state) => state.mode === PRESCRIPTION_MODES.EDIT,
    isLoading: (state) => {
      return Object.values(state.fetching).some((fs) => fs.state) || Object.values(state.saving).some((ss) => ss.state)
    },
    currentLoadingMessage: (state) => {
      const action = Object.values(state.fetching).find((fs) => fs.state) || Object.values(state.saving).find((ss) => ss.state)

      return action ? action.message : ''
    },
    getToggle: (state) => (toggleKey) => state.toggles[toggleKey],
    prescriptionWasEdited: (state) => (sessionId) => state.prescriptionsUpdatedDuringNavigation.has(Number(sessionId)),
  },
  mutations: {
    setFeatureToggleStatus(state, payload) {
      Object.keys(payload).forEach((prop) => {
        state.toggles[prop] = payload[prop]
      })
    },
    setReadyStatus(state, status) {
      state.ready = status
    },
    setMode(state, mode) {
      state.mode = mode
    },
    setEditMode(state, status) {
      state.editMode = status
    },
    setAsFetching(state, payload) {
      Object.keys(payload).forEach((prop) => {
        state.fetching[prop].state = payload[prop]
      })
    },
    setAsSaving(state, payload) {
      Object.keys(payload).forEach((prop) => {
        state.saving[prop].state = payload[prop]
      })
    },
    setPrescriptionAsEdited(state, sessionId) {
      state.prescriptionsUpdatedDuringNavigation.add(sessionId)
    },
  },
  actions: {
    async fetchSessionCategories({ commit }) {
      try {
        commit('setAsFetching', { sessionCategories: true })
        const { data: { categories } } = await Vue.$http('prescription/configs/fetchSessionCategories')

        const portalActiveCategories = categories.filter((c) => !!c.show_on_portal).sort((a, b) => (a.order_in_portal > b.order_in_portal) ? 1 : -1)

        commit('currentSession/setAvailableSessionCategories', portalActiveCategories)
        commit('setAsFetching', { sessionCategories: false })

        return portalActiveCategories
      } catch (e) {
        console.error('Error fetching session categories', e)
        commit('setAsFetching', { sessionCategories: null })

        return []
      }
    },
    discardSessionPrescription({ commit, dispatch }) {
      commit('setReadyStatus', false)
      dispatch('backupSession/discardBackupPrescription')
      commit('currentSession/resetSessionCategory')
      commit('currentSession/resetTrackedResistanceChanges')
      commit('currentSession/updateNumOfExercises', 'reset')
      commit('justification/discardJustificationsByExerciseID')
      commit('currentSession/discardCurrentSession')
      commit('currentSession/discardProtocolFilters')
    },
    async prefillExercisesList({ getters, dispatch },
      {
        flowType,
        patientId,
        therapyId,
        therapyExercisesList,
        prescribedExercisesList,
        activeResistancesForSessionCategory,
      }) {

      const therapyExercises = deepCopyObj(therapyExercisesList)

      switch (flowType) {
        case PRESCRITPION_FLOW_TYPES.ADD_PROTOCOL: {
          const { exercises: protocolSessionExercises } = getters['currentSession/getProtocolSessionSpec']

          return prefillNewProtocolSession(therapyExercises, protocolSessionExercises, activeResistancesForSessionCategory)
        }
        case PRESCRITPION_FLOW_TYPES.ADD_CUSTOM: {
          // Fetch exercise conditions defined for the current patient prescriptions
          const exercisesConditionsByExerciseId = await dispatch('currentSession/fetchTherapyExercisesConditionsByPatient', {
            patientId,
            therapyId,
          })

          return prefillNewCustomSession(therapyExercises, exercisesConditionsByExerciseId)
        }
        case PRESCRITPION_FLOW_TYPES.EDIT_CUSTOM: {
          // Fetching previous prescribed session Exercises
          return prefillExistingSession(therapyExercises, prescribedExercisesList)
        }
        default: {
          console.log('[session-prescription] Aborting prescription. Dunno what to do with session')

          return null
        }
      }
    },
    async bootSession({ rootGetters, getters, commit, dispatch }, { flowType, patientId, therapyId, therapySide, sessionId }) {

      console.log(`[session-prescription] Booting session on ${flowType} mode`)

      /*
      *  If there are NO exercises already in place, it's a new session.
      *  If there're, replacing then will end on losing current edits.
      *  Prevents prescription back and forth navigation to trigger a running ctrl.
      */

      const currentSessionFillIsInvalid = getters['currentSession/currentSessionFillIsInvalid']
      const exercisesWereLoaded = getters['currentSession/alreadyHaveExercisesLoaded'](therapyId, therapySide)

      // Proceeds with already loaded list
      if (exercisesWereLoaded && !currentSessionFillIsInvalid) {
        return
      }

      commit('currentSession/updateNumOfExercises', 'reset')

      let therapyData = {}
      let prescribedSessionData = {}

      // Dealing with exercise fetching
      commit('setAsFetching', { exercises: true })
      if (flowType === PRESCRITPION_FLOW_TYPES.EDIT_CUSTOM) {
        dispatch('currentSession/setCurrentTherapyById', therapyId)
        const { therapy, session } =
          await dispatch('patient/prescription/fetchExistingSessionExercises', { patientId, sessionId, therapySide }, { root: true })

        therapyData = { ...therapy, side: therapySide }
        const blackListedExerciseCodes = therapyData.removedExercises?.map(({ code }) => code)

        prescribedSessionData = session
        commit('prescription/therapies/setTherapyExercises', therapyData, { root: true })
        commit('currentSession/setRemovedExercises', blackListedExerciseCodes)
      } else {
        const [therapyDataResponse, { data: removedExercisesData }] =
          await Promise.all([
            dispatch('therapies/fetchTherapyExercises', { therapyId, therapySide }),
            Vue.$http('prescription/patient/fetchBlacklistedExercises', { patientId, therapyId, sortDir: 'desc' }),
          ])

        therapyData = therapyDataResponse
        const blackListedExerciseCodes = removedExercisesData.exercises?.map(({ code }) => code)

        commit('currentSession/setRemovedExercises', blackListedExerciseCodes)
      }
      commit('setAsFetching', { exercises: false })

      const protocolSessionCategory = getters['currentSession/getSessionCategory']
      const activeResistancesPrePrescription = rootGetters['patient/prescription/getExerciseCodesWithWeightForCurrentSession']
      const activeResistancesForSessionCategory = activeResistancesPrePrescription[protocolSessionCategory] || []

      const sessionPrefillPayload = {
        flowType,
        patientId,
        therapyId,
        sessionId: prescribedSessionData.id,
        therapyExercisesList: therapyData.exercises,
        prescribedExercisesList: prescribedSessionData.exercises,
        activeResistancesForSessionCategory,
      }

      console.log('[session-prescription] Pre-filling Session')
      // Manipulating exercises lists and getting them ready to set
      const {
        maxOrder,
        numOfExercises,
        exercisesList,
        exercisesAdded,
      } = await dispatch('prefillExercisesList', sessionPrefillPayload)

      // Setting up originally added exercises for changes tracking reference
      const justificationIsActive = getters.trackingIsActive

      if (justificationIsActive) {
        commit('prescription/backupSession/setBackupExercises', exercisesAdded, { root: true })
      }

      // Finding and cloning needed session properties from previous session
      if (flowType === PRESCRITPION_FLOW_TYPES.EDIT_CUSTOM) {
        console.log('[session-prescription] Setting previous prescription params')

        const existingSessionBasics = rootGetters['patient/prescription/getPrescribedSessionById'](sessionId)

        const { id, custom_name: customName, side, goal, session_category: sessionCategory } = existingSessionBasics

        commit('currentSession/setSessionId', id)
        commit('currentSession/setCurrentCustomName', customName)
        commit('currentSession/setSessionCategory', sessionCategory)
        commit('currentSession/setCurrentTherapySide', side)
        commit('currentSession/setCurrentGoal', goal)
      }

      console.log('[session-prescription] Setting general session data')

      // Updating counters
      commit('currentSession/updateNumOfExercises', numOfExercises)
      commit('currentSession/updateExerciseMaxOrder', maxOrder)

      // Setting therapy info
      commit('currentSession/setCurrentTherapyProperties', {
        filters: therapyData.filters,
        exercise_positions: therapyData.positions,
      })

      // Setting Exercises list
      commit('currentSession/setAddedExercises', exercisesAdded)
      commit('currentSession/setCurrentFullExercisesList', {
        therapyId,
        therapySide,
        exercisesList,
      })

      // Setting up current session fill as valid
      commit('currentSession/invalidateSessionFill', false)

      // Turning off loading flags
      commit('setReadyStatus', true)
    },
    async addSessionPrescription({ rootGetters, commit, dispatch }, {
      patientId,
      sessionPayload,
      justificationPayload,
      protocolPayload,
    }) {

      // Append protocol payload if necessary
      if (protocolPayload) {
        // Adding justification payload to request body
        Object.assign(sessionPayload, { protocol_data: protocolPayload })
      }

      // Append changes payload if necessary
      if (justificationPayload) {
        // Adding justification payload to request body
        Object.assign(sessionPayload, { changes_justification: justificationPayload })
      }

      const dptActivityUuid = rootGetters['dptActivity/getDptActivityUuid']

      try {
        const { data } = await Vue.$http('prescription/patient/addSession', patientId, {
          body: sessionPayload,
          config: {
            'DPT-Activity-UUID': dptActivityUuid,
          },
        })

        const currentSessionData = rootGetters['prescription/currentSession/getCurrentSession']
        const { weekly_frequency } = rootGetters['patient/getCounters']

        const weeklyFrequency = weekly_frequency || currentSessionData.weekly_frequency

        dispatch('discardSessionPrescription')
        commit('patient/updateCounters', {
          'total_prescriptions': '++',
          'weekly_frequency': weeklyFrequency,
        }, { root: true })

        return Promise.resolve(data)
      } catch (error) {
        console.log(`Error adding new session for patient with ID ${patientId}`, error)

        return Promise.reject(error)
      }
    },
    async updateSessionPrescription({ rootGetters, commit, dispatch }, { patientId, sessionId, updatePayload, justificationPayload }) {

      const sessionIdentifiers = {
        patientId,
        sessionId,
      }

      // Append changes payload if necessary
      if (justificationPayload) {
        // Base justification body for edited sessions
        const editSessionJustificationBasic = {
          patient_id: patientId,
          session_id: sessionId,
          change_type: 'other',
        }

        // Merging base justifications body with shared changes payload object
        const completeEditJustificationsPayload = Object.assign({}, editSessionJustificationBasic, justificationPayload)

        // Adding justification payload to request body
        Object.assign(updatePayload, { update_justifications: completeEditJustificationsPayload })
      }

      const dptActivityUuid = rootGetters['dptActivity/getDptActivityUuid']

      try {
        const { data } = await Vue.$http('prescription/patient/updateSession', sessionIdentifiers, {
          body: updatePayload,
          config: {
            'DPT-Activity-UUID': dptActivityUuid,
          },
        })

        commit('setPrescriptionAsEdited', sessionId)
        dispatch('discardSessionPrescription')

        return Promise.resolve(data)
      } catch (error) {
        console.log(`Error updating session with ID ${sessionId} for patient with ID ${patientId}`, error)

        return Promise.reject(error)
      } finally {
        commit('justification/discardJustificationsByExerciseID')
      }
    },
    async updateSessionArchivedStatus({ rootGetters, commit }, { patientId, sessionId, archive }) {
      const sessionIdentifiers = {
        patientId,
        sessionId,
        newState: archive,
      }

      try {
        const dptActivityUuid = rootGetters['dptActivity/getDptActivityUuid']

        const sessionArchivedStateBody = { is_active: archive }

        commit('patient/prescription/setPrescribedSessionArchiveStates', sessionIdentifiers, { root: true })
        await Vue.$http('prescription/patient/updateSession', sessionIdentifiers, {
          body: sessionArchivedStateBody,
          config: {
            'DPT-Activity-UUID': dptActivityUuid,
          },
        })
        const totalSessionsUpdateType = archive ? '++' : '--'

        commit('patient/updateCounters', { 'total_prescriptions': totalSessionsUpdateType }, { root: true })

        return Promise.resolve(true)
      } catch (e) {
        sessionIdentifiers.newState = !sessionIdentifiers.newState
        commit('patient/prescription/setPrescribedSessionArchiveStates', sessionIdentifiers, { root: true })
        console.error(`Error updating session archived status. Session ID ${sessionId} for patient with ID ${patientId}`, e)

        return Promise.reject(e)
      }
    },
    async deletePrescribedSession({ commit }, { patientId, sessionId, dptActivityUUID }) {
      const sessionIdentifiers = {
        patientId,
        sessionId,
      }

      try {
        await Vue.$http('prescription/patient/deleteSession', sessionIdentifiers, {
          config: {
            'DPT-Activity-UUID': dptActivityUUID,
          },
        })
        commit('patient/prescription/removePrescribedSession', sessionIdentifiers.sessionId, { root: true })

        return Promise.resolve(true)
      } catch (e) {
        console.error(`Error deleting session with ID: ${sessionId} for patient with ID ${patientId}`, e)

        return Promise.reject(e)
      }
    },
  },
  modules: {
    currentSession,
    backupSession,
    therapies,
    protocols,
    justification,
    recommendations,
  },
}
