import i18n from '@/scripts/app-configs/i18n-config'
/**
 * Expects a string and return its translation
 * Accepts prefix to be appended to the value (if provided) when translating
 * @name Translate Or Dash
 * @returns {string} Translated string (value or prefix+value) or a -
 */

export const translateOrDash = (value: string, prefix = ''): string => {
  return value ? i18n.t(`${prefix}${value}`).toString() : '-'
}

export default translateOrDash
