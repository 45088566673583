import router from '@/router'

type Params = Record<string, string>

export default function useOpenNewTab() {

  return (state: string, params: Params, queryParams: Params | null = null) => {
    const { href: routeUrl } = router.resolve({ name: state, params })

    const queryParamsStr = new URLSearchParams('')

    if (queryParams) {
      Object.entries(queryParams).forEach(([param, value]) => {
        queryParamsStr.set(param, value)
      })
    }

    window.open(
      queryParamsStr.toString() ? `${routeUrl}?${queryParamsStr}` : routeUrl,
      '_blank',
    )
  }
}
