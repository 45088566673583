<template>
  <div class="notes-header-wrapper -pxy_2">
    <profile-picture
      class="-mr_2"
      :side="'40px'"
      :vip="vip"
      :high-risk="highRisk"
      :picture="picture"
      :gender="gender"
      :loading="loading"
    />
    <span class="t2 -t_medium" :class="{'text-content-loading': loading}"> {{ name }}</span>
    <sword-button-wuk
      class="close-button"
      theme="text"
      round
      size="small"
      icon="close"
      @click.native="$emit('close')"
    />
  </div>
</template>

<script>
import { computed } from 'vue'
// Components
import ProfilePicture from '@ui-kit/components/pictures/ProfilePicture.vue'
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue'

export default {
  name: 'NotesHeader',
  components: {
    ProfilePicture,
    SwordButtonWuk,
  },
  props: {
    picture: {
      type: String,
      default: null,
    },
    vip: {
      type: Boolean,
      default: false,
    },
    highRisk: {
      type: Boolean,
      default: false,
    },
    gender: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  setup(props) {
    const loading = computed(() => !props.name )

    return { loading }
  },
}
</script>

<style lang="scss" scoped>

.notes-header-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $context-color-6;

  span {
    flex: 1 1 auto;
  }
}

.close-button {
  display: none;

  @media screen and (max-width: break-wuk('medium', 's')) {
    display: block;
  }
}
</style>
