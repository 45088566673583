type MetricScale = {
  min: number | string
  label: string
}

export type MetricScales = {
  adherence: MetricScale[]
  performance: MetricScale[]
  satisfaction: MetricScale[]
  bmi: MetricScale[]
}

export default {
  adherence: [
    { min: '0', label: 'copy_49' },
    { min: '41', label: 'copy_50' },
    { min: '61', label: 'copy_51' },
    { min: '86', label: 'copy_52' },
  ],
  performance: [
    { min: '0', label: 'copy_85' },
    { min: '50', label: 'copy_86' },
    { min: '70', label: 'copy_87' },
    { min: '86', label: 'copy_88' },
  ],
  satisfaction: [
    { min: '0', label: '' },
    { min: '70', label: '' },
    { min: '80', label: '' },
  ],
  bmi: [
    { min: 0.0001, label: 'copy_3184' },
    { min: 18.5, label: 'copy_3185' },
    { min: 25, label: 'copy_3186' },
    { min: 30, label: 'copy_3187' },
  ],
} as MetricScales
