const THERAPY_INTERFACE = {
  id: null,
  side: undefined,
  filters: [],
  positions: [],
  exercises: [],
  removedExercises: [],
}

const SESSION_INTERFACE = {
  id: null,
  therapyId: null,
  customName: null,
  exercises: [],
  lastExercisesConfigDate: null,
  goal: '',
  name: '',
}

const THERAPY_SELECTION_TRIGGERS = {
  MANUAL: 'manual',
  AUTO: 'automatic',
}

const PRESCRIPTION_MODES = {
  ADD: 'add',
  EDIT: 'edit',
}

const PRESCRITPION_FLOW_TYPES = {
  ADD_PROTOCOL: 'add-protocol',
  ADD_CUSTOM: 'add-custom',
  EDIT_PROTOCOL: 'edit-protocol',
  EDIT_CUSTOM: 'edit-custom',
}

const protocolPrescriptionSpecPathMaker = {
  __configs: {
    MAIN_FOLDER: 'protocols-prescription',
    SUB_FOLDERS: {
      PROTOCOLS: 'protocols',
      SESSIONS: 'sessions',
    },
  },
  protocolsSpec() {
    const fileName = 'protocols'

    return `${this.__configs.MAIN_FOLDER}/${fileName}.json`
  },
  sessionsForWeekSpec(protocolId, protocolWeek) {
    const fileName = `protocol_${protocolId}_week_${protocolWeek}`

    return `${this.__configs.MAIN_FOLDER}/${this.__configs.SUB_FOLDERS.PROTOCOLS}/${fileName}.json`
  },
  sessionSpecById(sessionId) {
    const fileName = `session_${sessionId}`

    return `${this.__configs.MAIN_FOLDER}/${this.__configs.SUB_FOLDERS.SESSIONS}/${fileName}.json`
  },
}

const prescriptionTypeForPayload = (flowType, hasPrescriptionChanges) => {
  let prescriptionTypePayloadKey

  if (flowType === PRESCRITPION_FLOW_TYPES.ADD_PROTOCOL) {
    prescriptionTypePayloadKey = hasPrescriptionChanges ? 'protocol_edited' : 'protocol'
  } else {
    prescriptionTypePayloadKey = 'custom'
  }

  return prescriptionTypePayloadKey
}

export {
  THERAPY_INTERFACE,
  SESSION_INTERFACE,
  THERAPY_SELECTION_TRIGGERS,
  PRESCRIPTION_MODES,
  PRESCRITPION_FLOW_TYPES,
  protocolPrescriptionSpecPathMaker,
  prescriptionTypeForPayload,
}
