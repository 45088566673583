export default {
  getPatientSessionResults: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      mock: (_, { patientId, offset, limit }) => `mocks/endpoints/results/getPatientSessionResults/${patientId}/offset_${offset}_limit_${limit}.json`,
    },
    $$makeUrl(configs, { patientId, institutionId, offset, limit }) {
      const url = `therapist/v${configs.api.version}/results/patient/${patientId}`
      const queryParams = new URLSearchParams('')

      if (institutionId) {
        queryParams.append('institution_id', institutionId)
      }

      if (offset !== undefined) {
        queryParams.append('offset', offset)
      }

      if (limit) {
        queryParams.append('limit', limit)
      }

      return `${url}?${queryParams}`
    },
  },
  getClinicalPatientSessionResults: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { programUUID, offset, limit }) {
      const url = `therapist/v${configs.api.version}/clinical-patients/${programUUID}/sessions`
      const queryParams = new URLSearchParams('')

      if (offset !== undefined) {
        queryParams.append('offset', offset)
      }

      if (limit) {
        queryParams.append('limit', limit)
      }

      return `${url}?${queryParams}`
    },
  },
  getClinicalPatientSessionsInfo: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { programUUID }) {
      return `therapist/v${configs.api.version}/clinical-patients/${programUUID}/sessions-info`
    },
  },
  getSessionResults: {
    api: {
      url: 'default',
      version: '1.1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      mock: (_, { patientId, offset, sessionId, limit, groups }) => {
        return `mocks/endpoints/results/getSessionResults/${patientId}/session_${sessionId}/group_${groups}/offset_${offset}_limit_${limit}.json`
      },
    },
    $$makeUrl(configs, { patientId, sessionId, institutionId, groups, offset, limit }) {
      const url = `api/v${configs.api.version}/results/patient/${patientId}/session/${sessionId}`
      const queryParams = new URLSearchParams('')

      if (institutionId) {
        queryParams.append('institution_id', institutionId)
      }

      if (groups) {
        queryParams.append('groups[]', groups)
      }

      if (offset !== undefined) {
        queryParams.append('offset', offset)
      }

      if (limit) {
        queryParams.append('limit', limit)
      }

      return `${url}?${queryParams}`
    },
  },
  getExerciseResults: {
    api: {
      url: 'default',
      version: '1.1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      mock: (_, { patientId, resultId }) => `mocks/endpoints/results/getExerciseResults/${patientId}/${resultId}.json`,
    },
    $$makeUrl(configs, { patientId, resultId }) {
      return `api/v${configs.api.version}/results/patient/${patientId}/result/${resultId}`
    },
  },
}
