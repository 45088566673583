// import { PRESCRITPION_FLOW_TYPES } from '@/scripts/configs/prescription/prescription-configs';

const prescriptionJustificationPLugin = (store) => {

  const sessionChangeMutations = [
    'prescription/currentSession/updateListedExerciseByExerciseID',
    'prescription/currentSession/removeExercisesByID',
    // Leaving this here for future reference on AI dev
    // 'prescription/currentSession/updateAllAddedExerciseByID',
    // 'prescription/currentSession/addExercise',
    // 'prescription/currentSession/removeExerciseById',
    // 'prescription/currentSession/removeExerciseByIndex',
    // 'prescription/currentSession/updateAddedExerciseByIndex',
    // 'prescription/currentSession/updateAddedExerciseByID',
  ]

  store.subscribe((mutation) => {

    const isReady = store.getters['prescription/isReady']
    const validTrackingMutation = sessionChangeMutations.includes(mutation.type)
    const forcedTrackingIgnore = mutation.payload && mutation.payload.ignoreUpdate
    const justificationIsActive = store.getters['prescription/trackingIsActive']

    if (!isReady || !justificationIsActive || !validTrackingMutation || forcedTrackingIgnore) {
      return
    }
    console.log('Will track')
    store.dispatch('prescription/justification/storeExerciseChange', mutation.payload)
  })
}

export default prescriptionJustificationPLugin
