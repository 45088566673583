import Vue from 'vue'
import { triggers } from '@/scripts/global-modals-commands'

/**
 * Handle click event and add css color class to open member side bar
 * @name OpenMemberSideBar
 * @returns {*} picture or text link class
 * @handles openMemberSideBar action
 */

let openMemberSideBarRef

const openSideBarCall = (event, vm, memberUuid) => {
  event.stopPropagation()
  event.preventDefault()
  triggers.toggleMemberSidebar({ memberUuid })
}

const openMemberSidebar = {
  inserted(el, binding) {
    const customClass = binding.arg === 'picture' ? 'button-link button-link--picture' : 'button-link'

    el.className += ` ${customClass}`
  },
  bind(el, binding, vm) {
    openMemberSideBarRef = (event) => openSideBarCall(event, vm, binding.value.patientUuid)
    el.addEventListener('click', openMemberSideBarRef)
  },
  unbind(el) {
    el.removeEventListener('click', openMemberSideBarRef)
  },
  update: function (el, binding, vm, prevVm) {
    const currentUuid = (vm.data.directives.find((d) => d.name === 'open-member-side-bar') || {}).value.patientUuid
    const prevUuid = (prevVm.data.directives.find((d) => d.name === 'open-member-side-bar') || {}).value.patientUuid

    if (!prevUuid || currentUuid === prevUuid) return

    el.removeEventListener('click', openMemberSideBarRef)
    openMemberSideBarRef = (event) => openSideBarCall(event, vm, binding.value.patientUuid)

    el.addEventListener('click', openMemberSideBarRef)
  },
}

Vue.directive('openMemberSideBar', openMemberSidebar)

export default openMemberSidebar
