import { CONFIG } from '@/scripts/app-configs/constants'

export default {
  fetchInstitutionDetails: {
    api: {
      url: 'default',
    },
    $$method: 'get',
    $$meta: {
      mock: (_, institutionId ) => `mocks/endpoints/institutions/fetchInstitutionDetails/${institutionId}.json`,
    },
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, institutionId) {
      return `api/v1/institutions/${institutionId}`
    },
  },
  fetchInstitutionClientData: {
    api: {
      url: CONFIG.onboardingApiUrl,
    },
    $$method: 'get',
    $$meta: {
      mock: () => 'mocks/endpoints/institutions/fetchInstitutionClientData.json',
    },
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, uniqueKey) {
      return `${configs.api.url}/v2/clients/${uniqueKey}`
    },
  },
  clinical: {
    fetchPathologyList: { // TODO Will get dupl. when these task and protocols get merged. Remove ASAP
      api: {
        url: 'default',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl() {
        return 'api/v1.1/pathologies'
      },
    },
  },
}
