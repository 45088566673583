import i18n from '@/scripts/app-configs/i18n-config'

const compatibilityTools = {
  // backwords compatibility for session custom name;
  // this only exists so there is a reference to all the places that use this
  // outdated name
  getDefaultSessionName(name) {
    const prefix = i18n.t('copy_376')

    if (!name) {
      return prefix
    }

    return `${prefix} ${name}`
  },
}

export default compatibilityTools
