export default {
  namespaced: true,
  state: () => ({
    accountUuid: null,
    unit: null,
    channelId: null,
  }),
  getters: {
    getAccountUuid: (state) => state.accountUuid,
    getUnit: (state) => state.unit,
    getChatChannelUrl: (state) => state.channelId,
  },
  mutations: {
    setAccountUuid: (state, accountUuid) => {
      state.accountUuid = accountUuid
    },
    setUnit: (state, unit) => {
      state.unit = unit
    },
    setChannelId: (state, channelId) => {
      state.channelId = channelId
    },
  },
}
