import Vue from 'vue'
import { exerciseState as exerciseStateFilter } from '@/utils/filters'

/**
 * Add class default color class according exercise current execution state
 * @name Exercise state class
 * @returns {*} Exercise state class
 */

const exerciseState = {
  inserted(el, binding) {
    const prefix = 'exercise-state-'
    const exercise = binding.value
    const state = exerciseStateFilter(exercise)

    // Maintaining retro compatibility. Add here older prop values
    el.className += ` ${prefix}${state}`

    return true
  },
}

Vue.directive('exerciseState', exerciseState)

export default exerciseState
