<template>
  <v-dropdown class="patient-reminders" :distance="10" placement="bottom-end">
    <sword-button-wuk
      theme="secondary"
      icon="schedule"
      size="small"
      side="left"
      :text="buttonLabel"
      :disabled="!items.length"
      class="-mt_1 -mx_0"
    />
    <template #popper>
      <ul class="patient-reminders-list">
        <li v-for="(item, index) of items" :key="index">
          <span class="-t_semi-heavy">{{ formatTime(item.hour, item.min) }}</span>
          <span>{{ abbreviatedDays(item.days_of_week) }}</span>
        </li>
      </ul>
    </template>
  </v-dropdown>
</template>

<script>
import dateUtils from '@ui-kit/scripts/utils/date'

// Components
import { Dropdown as VDropdown } from 'floating-vue'
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue'

export default {
  components: { VDropdown, SwordButtonWuk },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    buttonLabel() {
      const total = this.items
        .map((item) => item.days_of_week || [])
        .reduce((accumulator, currentArray) => accumulator + currentArray.length, 0)

      if (!total) return 'SIDEBAR.REMINDERS_NONE'

      return total > 1 ? this.$t('SIDEBAR.REMINDERS_VIEW_TOTAL', { total }) : 'SIDEBAR.REMINDERS_VIEW_1'
    },
  },
  methods: {
    formatTime(hour, minute) {
      return dateUtils.formatTime(hour, minute)
    },
    abbreviatedDays(days) {
      return days.map((day) => this.abbreviateDayOfWeek(day)).join(', ')
    },
    abbreviateDayOfWeek(dayOfWeek, lang = this.$i18n.locale) {
      const date = dateUtils.getNextDate(dayOfWeek)
      const options = { weekday: 'short' }
      let weekday = new Intl.DateTimeFormat(lang, options).format(date)

      weekday = weekday.replace('.', '')

      return weekday.charAt(0).toUpperCase() + weekday.slice(1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~floating-vue/dist/style.css';

.patient-reminders {
  position: relative;
  display: inline-block;

  &::v-deep {
    .sword-icon.svg-icon {
      width: calc($button-round-icon-base-size-wuk * 1.3);
      height: calc($button-round-icon-base-size-wuk * 1.3);
    }
  }

  &-list {
    padding: 1rem 0;
    width: 19rem;

    li {
      display: flex;
      justify-content: space-between;
      font-size: $font-base-12;
      padding: 0.75rem 1rem;
    }
  }
}
</style>
