/**
 * Util for checking if value is number
 * @name Is Number
 * @returns {boolean} If value is a valid number
 */

export const isNumber = (value: number): boolean => {
  return (!!value || value === 0 || value === 0.0) && !Number.isNaN(Number(value))
}

export default isNumber
