import Vue from 'vue'
import Cookies from 'js-cookie'

import { RECOMMENDATION_STATUS } from '@/scripts/configs/recommendations'

import { errorSafeHandler } from '@/scripts/utils/api-error-utils'

import { AUTH_COOKIE } from '@/scripts/app-configs/constants'

// Utils
import { triggers } from '@/scripts/global-modals-commands'

const ALLOWED_USER_ROLES = ['Professional']

const defaultUserState = () => {
  return {
    tokens: {
      accessToken: '',
      refreshToken: '',
    },
  }
}

export default {
  namespaced: true,
  state: {
    ...defaultUserState(),
  },
  getters: {
    accessToken: (state) => state.tokens.accessToken,
    refreshToken: (state) => state.tokens.refreshToken,
    isAuthenticated: (state) => !!state.tokens.accessToken && !!state.tokens.refreshToken,
  },
  mutations: {
    loadStoredAuthTokens(state) {
      const cookiesAT = Cookies.get(AUTH_COOKIE.token_name)
      const cookiesRT = Cookies.get(AUTH_COOKIE.refresh_token_name)

      if (cookiesAT) state.tokens.accessToken = cookiesAT
      if (cookiesRT) state.tokens.refreshToken = cookiesRT
    },
    clearAuthTokens(state) {
      state.tokens.accessToken = defaultUserState().tokens.accessToken
      state.tokens.refreshToken = defaultUserState().tokens.refreshToken
    },
    setAuthTokens: (state, { accessToken, refreshToken }) => {
      if (accessToken) {
        state.tokens.accessToken = accessToken
      }
      if (refreshToken) {
        state.tokens.refreshToken = refreshToken
      }
    },
    deleteAuthCookies() {
      Cookies.remove(AUTH_COOKIE.token_name, {
        domain: AUTH_COOKIE.token_domain,
      })
      Cookies.remove(AUTH_COOKIE.token_expires_date_name, {
        domain: AUTH_COOKIE.token_domain,
      })
      Cookies.remove(AUTH_COOKIE.refresh_token_name, {
        domain: AUTH_COOKIE.token_domain,
      })
    },
  },
  actions: {
    removeAuth({ commit }) {
      commit('deleteAuthCookies')
      commit('clearAuthTokens')
    },
    async login({ commit, dispatch }, { username, password }) {
      try {
        const { data: loginData } = await Vue.$http('user/auth/login', null, { body: { username, password, type: 'credentials' } })
        const {
          user,
          role: userRole,
          access_token: accessToken,
          refresh_token: refreshToken,
        } = loginData

        if (!ALLOWED_USER_ROLES.includes(userRole)) {
          dispatch('loginFailed', 'ROLE_BLOCKED')

          return Promise.reject(Error('ROLE_BLOCKED'))
        }

        await dispatch('user/setUser', {
          user: { ...user, role: userRole.toLowerCase() },
          accessToken,
          refreshToken,
        }, { root: true })

        const { data: userMe } = await Vue.$http('user/me/fetchMe')
        const { data: { institutions } } = await Vue.$http('user/me/fetchInstitutions')

        commit('user/setUserInfo', userMe, { root: true })
        commit('user/setInstitutions', institutions, { root: true })

        await dispatch('user/authentication/loginSuccess', null, { root: true })

        return Promise.resolve({ ...loginData })
      } catch (e) {
        if (!e || !e.response) {
          console.warn('[login] User unable to login')
        }
        dispatch('loginFailed', e.data)

        return Promise.reject(e)
      }
    },
    async logout({ commit, dispatch, rootGetters }, opts = {}) {

      const options = {
        sameUserLoginRoute: null,
        remoteLogout: true,
        ...opts,
      }

      if (options.sameUserLoginRoute) {
        commit('system/setRouteBeforeInactivity', {
          userId: rootGetters['user/getUserId'],
          routeFullPath: options.sameUserLoginRoute,
        }, { root: true })
      }

      if (!Cookies.get(AUTH_COOKIE.token_name)) {
        triggers.closeAll()
        dispatch('user/deleteUser', null, { root: true })

        return Promise.resolve(true)
      }

      try {
        commit('system/setUIReadyState', false, { root: true })
        if (options.remoteLogout) await Vue.$http('user/auth/logout')
        triggers.closeAll()
        dispatch('user/deleteUser', null, { root: true })
        dispatch('patient/discardAllPatientRelatedData', null, { root: true })

        return Promise.resolve(true)
      } catch (e) {
        console.error('[logout] Error logging out user', e)

        return Promise.reject(e)
      } finally {
        commit('system/setUIReadyState', true, { root: true })
      }
    },
    async loginSuccess({ commit, dispatch }) {
      commit('system/setUIReadyState', false, { root: true }) // Restart the results information
      await dispatch('configs/fetch', 'all', { root: true }) // Getting all feature toggle states
      dispatch('user/handleOnboardingProfileModal', null, { root: true })
      dispatch('recommendations/fetchRecommendationsCount', [RECOMMENDATION_STATUS.NEW], { root: true })
      dispatch('general/fetchNotificationsStats', null, { root: true })
      commit('results/resetState', null, { root: true }) // Restart the results information
      commit('system/setUIReadyState', true, { root: true })
    },
    loginFailed({ dispatch }) {
      dispatch('user/deleteUser', null, { root: true })
    },
    async validateSignedAction(_, { action, token }) {
      try {
        const response = await Vue.$http('user/auth/validateSignedAction', { action, token })

        return Promise.resolve(response)
      } catch (e) {
        const { errorLevel } = errorSafeHandler(e)

        if (errorLevel.network) {
          console.error(`[signed-action] Unable to validate '${action}'`, e)
        }

        return Promise.reject(e)
      }
    },
    async unlockAccount(_, token) {
      try {
        const { data } = await Vue.$http('user/auth/unlockAccount', null, { body: { token } })

        if ( data.error ) {
          return Promise.reject(new Error(data.message))
        }

        return Promise.resolve(data)
      } catch (e) {
        const { errorLevel } = errorSafeHandler(e)

        if (errorLevel.network) {
          console.error('[unlock-account] Error trying unlock account request', e)
        }

        return Promise.reject(e)
      }
    },
    async resetPassword(_, { token, userIdentifier, password, repeatedPassword }) {
      const payload = {
        token,
        userIdentifier,
        password,
        password_confirmation: repeatedPassword,
      }

      try {
        const { data } = await Vue.$http('user/auth/resetPassword', payload.token, { body: payload })

        return Promise.resolve(data)
      } catch (e) {
        const { errorLevel } = errorSafeHandler(e)

        if (errorLevel.network) {
          console.error('[reset-password] Failed password reset request tried', e)
        }

        return Promise.reject(e)
      }
    },
    async forgotPassword(_, userIdentifier) {
      try {
        await Vue.$http('user/auth/forgotPassword', null, { body: { userIdentifier } })

        return Promise.resolve()
      } catch (e) {
        console.error('[forgot-password] Error requesting new password', e)

        return Promise.reject(e)
      }
    },
    async changePassword(_, { password, newPassword: passwordnew, confirmPassword: passwordconfirm }) {
      try {
        const { data } = await Vue.$http('user/auth/changePassword', null, { body: { password, passwordnew, passwordconfirm } })

        return Promise.resolve(data)
      } catch (e) {
        const { errorLevel } = errorSafeHandler(e)

        if (errorLevel.network) {
          console.error('[change-password] Error changing user password', e)
        }

        return Promise.reject(e)
      }
    },
    refreshAccessToken() {
      return Vue.$http('user/auth/refreshAccessToken')
        .then(({ data }) => {
          return data.access_token
        })
        .catch((e) => {
          console.error('error Getting new refresh token', e)
        })
    },
    async checkTokenValidity({ commit, dispatch }) {
      commit('loadStoredAuthTokens')
      const tokenExpireDateFromCookie = Cookies.get(AUTH_COOKIE.token_expires_date_name)

      if (!tokenExpireDateFromCookie) {
        console.warn('Empty token expire info on Cookie')

        return
      }
      // get access token expiration date;
      const expirationDate = new Date(atob(tokenExpireDateFromCookie))
      const now = new Date()

      if (!expirationDate || now > expirationDate) {
        const freshAccessToken = await dispatch('refreshAccessToken')

        dispatch('updateAuthTokens', { accessToken: freshAccessToken })
      }
    },
    updateAuthTokens({ commit }, { accessToken, refreshToken }) {

      if (accessToken) {
        commit('setAuthTokens', { accessToken })

        // Removing old cookies
        Cookies.remove(AUTH_COOKIE.token_name, {
          domain: AUTH_COOKIE.token_domain,
        })
        Cookies.remove(AUTH_COOKIE.token_expires_date_name, {
          domain: AUTH_COOKIE.token_domain,
        })

        // Save access token cookie
        Cookies.set(AUTH_COOKIE.token_name, accessToken, {
          expires: AUTH_COOKIE.token_expires,
          domain: AUTH_COOKIE.token_domain,
        })

        const now = new Date()
        const expirationDate = btoa(new Date(now.setMinutes(now.getMinutes() + AUTH_COOKIE.token_validity_minutes)))

        // Save access token timeout date cookie
        Cookies.set(AUTH_COOKIE.token_expires_date_name, expirationDate, {
          expires: AUTH_COOKIE.token_expires,
          domain: AUTH_COOKIE.token_domain,
        })
      }

      if (refreshToken) {
        commit('setAuthTokens', { refreshToken })

        // Removing old cookie
        Cookies.remove(AUTH_COOKIE.refresh_token_name, {
          domain: AUTH_COOKIE.token_domain,
        })

        // Save refresh token cookie
        Cookies.set(AUTH_COOKIE.refresh_token_name, refreshToken, {
          expires: AUTH_COOKIE.token_expires,
          domain: AUTH_COOKIE.token_domain,
        })
      }
    },
  },
}
