<template>
  <transition name="notes-modal">
    <div v-if="memberInfo && modalOpen" class="modal-backdrop notes-backdrop" @click="shouldCloseWithoutSaving">
      <div class="notes-modal" @click.stop>
        <loading-screen v-show="isFetching || savingNote" />
        <notes-overview
          v-if="!isFetching"
          ref="notesOverviewRef"
          :picture="memberInfo.picture"
          :gender="memberInfo.gender"
          :name="memberInfo.name"
          :vip="memberInfo.vip || memberInfo.is_vip_account"
          :high-risk="memberInfo.highRisk || memberInfo.patient_is_high_risk"
          :notes="memberNotesList"
          :saving-note="savingNote"
          @should-close-without-saving="shouldCloseWithoutSaving"
          @save-note="(note) => saveNote(memberIdentifier, note)"
          @delete-note="deleteNote"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import Vue, { ref, computed, watch } from 'vue'
import { useQuery, useMutation } from '@tanstack/vue-query'
import i18n from '@/scripts/app-configs/i18n-config'

// Components
import LoadingScreen from '@/components/feedbacks/LoadingScreen.vue'
import NotesOverview from './NotesOverview.vue'

// Utils
import { triggers } from '@/scripts/global-modals-commands'

const formatLastEditDate = (lastEditDate) => {
  return Vue.$date(lastEditDate * 1000).format('DD MMM YYYY')
}

export default {
  name: 'NotesModal',
  components: {
    NotesOverview,
    LoadingScreen,
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const notesOverviewRef = ref()
    const memberNotesList = ref([])
    const savingNote = ref(false)

    const memberInfo = computed(() => props.payload)
    const memberIdentifier = computed(() => props.payload.user_id || props.payload.userId)

    // Fetch member notes whenever member id gets set or changed
    const { data, isFetching } = useQuery({
      queryKey: ['fetch-member-notes', memberIdentifier.value],
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: computed(() => !!memberIdentifier.value),
      queryFn: async () => {
        const { data: notes } = await Vue.$http('patient/notes/fetchNotes', memberIdentifier.value)

        return notes.map((note) => ({
          ...note,
          formattedLastEditDate: formatLastEditDate(note.last_edit.date),
        }))
      },
    })

    // Creates new note and updates current notes list
    const { mutate: createNote } = useMutation({
      mutationFn: ({ memberId, note }) => {
        return Vue.$http('patient/notes/createNote', memberId, { body: note })
      },
      onSuccess: async ({ data: note }) => {
        const newNote = { ...note, formattedLastEditDate: formatLastEditDate(Math.abs(Date.now() / 1000)) }

        memberNotesList.value = [newNote, ...memberNotesList.value]
      },
      onError: (e, { memberId }) => {
        console.error(`[member-notes] Error creating member note for member ID: ${memberId}`, e)
      },
    })

    // Updates existing note and updates current notes list
    const { mutate: updateNote } = useMutation({
      mutationFn: ({ memberId, note }) => {
        return Vue.$http('patient/notes/updateNote', { patientId: memberId, noteId: note.id }, { body: note })
      },
      onSuccess: async ({ data: note }) => {
        const updatedNote = { ...note, formattedLastEditDate: formatLastEditDate(note.last_edit.date) }
        const updatedNotesList = [updatedNote, ...memberNotesList.value.filter((prevNote) => prevNote.id !== updatedNote.id )]

        memberNotesList.value = [...updatedNotesList]
      },
      onError: (e, { memberId }) => {
        console.error(`[member-notes] Error updating member note for member ID: ${memberId}`, e)
      },
    })

    // Delete existing note and removes it from current notes list
    const { mutate: deleteNote } = useMutation({
      mutationFn: (noteId) => {
        return Vue.$http('patient/notes/deleteNote', { patientId: memberIdentifier.value, noteId: noteId })
      },
      onMutate: async (deletedNoteId) => {
        memberNotesList.value = [...memberNotesList.value].filter(({ id }) => id !== deletedNoteId)
      },
      onError: (e) => {
        console.error(`[member-notes] Error deleting member note for member ID: ${memberIdentifier.value}`, e)
      },
    })

    const saveNote = async (memberId, currentNote) => {
      savingNote.value = true

      if (!currentNote.title) {
        currentNote.title = i18n.t('copy_2676')
      }

      await (currentNote.id ? updateNote({ memberId, note: currentNote }) : createNote({ memberId, note: currentNote }))
      savingNote.value = false
    }

    const shouldCloseWithoutSaving = async () => {
      let shouldSave = false
      const currentNote = notesOverviewRef.value?.currentNote
      const memberId = memberIdentifier.value

      triggers.closeNotes()

      if (currentNote?.id) {
        const prevNote = memberNotesList.value?.find((note) => note.id === currentNote.id)

        shouldSave = prevNote && prevNote.title !== currentNote.title || prevNote.body !== currentNote.body
      } else {
        shouldSave = !!currentNote?.body
      }

      if (shouldSave) {
        await saveNote(memberId, currentNote)
        Vue.$notify.success(i18n.t('copy_2687'))
      } else {
        notesOverviewRef.value?.closeOpenNote()
      }
    }

    watch(data, () => {
      memberNotesList.value = data.value
    })

    return {
      isFetching,
      memberIdentifier,
      notesOverviewRef,
      savingNote,
      memberInfo,
      memberNotesList,
      createNote,
      updateNote,
      deleteNote,
      saveNote,
      shouldCloseWithoutSaving,
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-backdrop.notes-backdrop {
  background-color: transparent;
}

.notes-modal-enter,
.notes-modal-leave-to {
  opacity: 0;
  transform: translateX(110%);
}

.notes-modal-enter-to,
.notes-modal-leave {
  opacity: 1;
  transform: translateX(0);
}

.notes-modal-enter-active {
  transition: all 0.3s ease-out;
}

.notes-modal-leave-active {
  transition: all 0.1s ease-in;
}

.notes-modal {
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  z-index: 11;
  width: 23.75rem;
  height: 33.125rem;
  max-height: calc(100% - 7.5rem);
  background-color: #ffffff;
  border-radius: 0.25rem;

  @media screen and (max-width: break-wuk('medium', 's')) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 26;
    border-radius: 0;
    max-height: initial;
  }
}
</style>
