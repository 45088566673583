/**
 * Setting up translations plugin.
 * Language imports and bootstrap config are done here
 * @module Language Configs
 * @param {object} - privacy gown
 * @param {object} - security
 * @returns {survival}
 */

// Vue App dependencies
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { languagesConfigs } from '@/scripts/app-configs/language/languages-configs'

const long = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  weekday: 'long',
  hour: 'numeric',
  minute: 'numeric',
}

const short = {
  month: 'short',
  day: 'numeric',
}

const dateTimeFormats = {
  'en': {
    short,
    long,
  },
  'pt': {
    short,
    long,
  },
}

Vue.use(VueI18n)

const i18n = new VueI18n({
  ...languagesConfigs.i18nOptions,
  dateTimeFormats,
  silentTranslationWarn: true,
})

export default i18n
