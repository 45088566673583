export default {
  summary: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta:{
      mock: () => 'mocks/endpoints/metrics/summary.json',
    },
    $$makeUrl(configs) {
      return `therapist/v${configs.api.version}/metrics/summary`
    },
  },
}
