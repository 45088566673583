/**
 * Util for parsing prefix and phone number when in a single string
 * @name Parse phone number
 * @returns {string} parsed value
 */

export const parsePhoneNumber = (value: string, replaceValue = ''): string => {
  return value.split('//').join(replaceValue)
}

export default parsePhoneNumber
