<template>
  <feed
    :total-items="totalRecommendationsValue"
    :is-modal-open="isModalOpen"
    :show-load-more="totalRecommendations > limit"
    :is-fetching="isFetching"
    :show-items="!!recommendations.length"
    title="copy_2448"
    no-items-text="NO_RECOMMENDATIONS"
    @load-more="loadMore"
    @close-modal="closeModal"
  >
    <li v-for="recommendation in recommendations" :key="`recommendation-${recommendation.id}`">
      <recommendation-item :recommendation="recommendation" @view-recommendation-reasons="openRecommendationReasonsModal(recommendation.uuid)" />
    </li>
  </feed>
</template>

<script>
// Components
import Feed from '@/components/feed.vue'
import RecommendationItem from '@/components/recommendations/RecommendationItem.vue'

// Configs
import { RECOMMENDATION_STATUS } from '@/scripts/configs/recommendations'

// Utils
import { triggers } from '@/scripts/global-modals-commands'
import { computed } from 'vue'

const DEFAULT_OFFSET = 20

const setDefaultState = () => ({
  recommendations: [],
  totalRecommendations: 0,
  currentPage: 0,
})

export default {
  name: 'RecommendationsFeed',
  components: {
    RecommendationItem,
    Feed,
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isModalOpen = computed(() => props.modalOpen)

    return { isModalOpen }
  },
  data() {
    return {
      ...setDefaultState(),
      isFetching: false,
      limit: DEFAULT_OFFSET,
    }
  },
  computed: {
    totalRecommendationsValue() {
      if (this.totalRecommendations) {
        return this.totalRecommendations
      }

      return this.isFetching ? null : 0
    },
  },
  watch: {
    async isModalOpen(newValue) {
      if (!newValue) {
        return
      }
      this.resetState()
      await this.fetchRecommendations()
      const hasNewRecommendations = this.recommendations.some((recommendation) => recommendation.status === RECOMMENDATION_STATUS.NEW)

      hasNewRecommendations && (await this.lockRecommendations())
    },
  },
  methods: {
    async fetchRecommendations() {
      if (this.isFetching) {
        return
      }

      const offset = this.currentPage * this.limit

      try {
        this.isFetching = true
        const { data } = await this.$http('recommendations/fetchRecommendations', { offset, limit: this.limit })

        this.recommendations = this.recommendations.concat(Object.values(data.list))
        this.totalRecommendations = data.total
      } catch (error) {
        console.error('[pp-recommendations] Error fetching recommendations list.', error)
      } finally {
        this.isFetching = false
      }
    },
    loadMore() {
      if (this.recommendations.length >= this.totalRecommendations) {
        return
      }
      this.currentPage++
      this.fetchRecommendations()
    },
    async lockRecommendations() {
      try {
        this.$store.commit('recommendations/setIsLockingRecommendations', true)
        await this.$http('recommendations/updateRecommendationsBatch', null, { body: { set_all_status_to_locked: true } })
        await this.$store.dispatch('recommendations/fetchRecommendationsCount', [RECOMMENDATION_STATUS.NEW])
      } catch (error) {
        console.error('[lock-pp-recommendations] Error locking the protocol recommendations.', error)
      } finally {
        this.$store.commit('recommendations/setIsLockingRecommendations', false)
      }
    },
    openRecommendationReasonsModal(recommendationUuid) {
      this.$store.commit('recommendations/setSelectedRecommendationUuid', recommendationUuid)
      triggers.toggleRecommendationsReasons()
    },
    resetState() {
      const defaultState = setDefaultState()

      Object.entries(defaultState).forEach(([prop, value]) => {
        this[prop] = value
      })
    },
    closeModal() {
      triggers.closeRecommendationsFeed()
    },
  },
}
</script>
