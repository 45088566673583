const RECOMMENDATION_STATUS = {
  NEW: 'new',
  LOCKED: 'locked',
  DEPRECATED_NEW_CREATED: 'deprecated_new_created',
  DEPRECATED_TOGGLE_OFF_AUTOMATIC_RECOMMENDATIONS: 'deprecated_toggle_off_automatic_recommendations',
  DEPRECATED_DISABLED_PROTOCOL_PRESCRIPTION: 'deprecated_disabled_protocol_prescription',
  APPLIED: 'applied',
  OVERWRITTEN: 'overwritten',
}

const RECOMMENDATION_OUTCOME = {
  OUTPUT_PROGRESS: 'progress',
  OUTPUT_HOLD: 'hold',
  OUTPUT_MANUAL_DECISION: 'manual_decision',
}

const PROTOCOL_PROGRESSION_ORIGINS = {
  PRESCRIPTION: 'manual_prescription',
  TODO_LIST: 'manual_todo_list',
  NOT_DEFINED: 'not_defined',
  UML: 'manual_uml',
}

export { RECOMMENDATION_OUTCOME, RECOMMENDATION_STATUS, PROTOCOL_PROGRESSION_ORIGINS }
