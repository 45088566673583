const prescritonTypesConfigs = {
  protocol: {
    option: {
      optLabel: 'copy_4358',
      optValue: 'protocol_prescription',
    },
  },
  custom: {
    option: {
      optLabel: 'copy_4359',
      optValue: 'custom_prescription',
    },
  },
}

const aiPrescriptionChangesConfigs = {
  protocol: {
    weeks: {
      number: 25,
      sessionsPerWeek: 3,
    },
    status: {
      options: [
        {
          optLabel: 'copy_3077',
          optValue: 'on_schedule',
        }, {
          optLabel: 'copy_3078',
          optValue: 'behind_schedule',
        }, {
          optLabel: 'copy_3079',
          optValue: 'ahead_schedule',
        },
      ],
      justifications: {
        behind_schedule: [
          {
            optLabel: 'copy_378',
            optValue: 'pain',
          }, {
            optLabel: 'copy_377',
            optValue: 'fatigue',
          }, {
            optLabel: 'copy_3081',
            optValue: 'low_compliance',
          }, {
            optLabel: 'copy_3082',
            optValue: 'low_performance',
          }, {
            optLabel: 'Other',
            optValue: 'other',
          },
        ],
        ahead_schedule: [
          {
            optLabel: 'copy_3084',
            optValue: 'high_compliance',
          }, {
            optLabel: 'copy_3085',
            optValue: 'high_performance',
          }, {
            optLabel: 'copy_3087',
            optValue: 'low_pain',
          }, {
            optLabel: 'copy_3086',
            optValue: 'low_fatigue',
          }, {
            optLabel: 'Other',
            optValue: 'other',
          },
        ],
      },
    },
  },
}

const exerciseChangeReasonOptions = [
  {
    optLabel: 'copy_378',
    optValue: 'pain',
  }, {
    optLabel: 'copy_377',
    optValue: 'fatigue',
  }, {
    optLabel: 'copy_3107',
    optValue: 'soreness',
  }, {
    optLabel: 'copy_3096',
    optValue: 'system_problem',
  }, {
    optLabel: 'copy_3099',
    optValue: 'increase_compliance',
  }, {
    optLabel: 'copy_3100',
    optValue: 'good_physical_conditioning',
  }, {
    optLabel: 'copy_3101',
    optValue: 'reduce_frustration',
  }, {
    optLabel: 'copy_3102',
    optValue: 'reduce_session_time',
  }, {
    optLabel: 'copy_3103',
    optValue: 'patient_request',
  }, {
    optLabel: 'copy_3104',
    optValue: 'lack_of_conditioning',
  }, {
    optLabel: 'copy_3105',
    optValue: 'increase_challenge',
  }, {
    optLabel: 'copy_3106',
    optValue: 'low_performance',
  }, {
    optLabel: 'other',
    optValue: 'other',
  },
]

export { aiPrescriptionChangesConfigs, exerciseChangeReasonOptions, prescritonTypesConfigs }
