import i18n from '@/scripts/app-configs/i18n-config'
/**
 * Transform a given value in a yes or no string
 * @name yesOrNoString
 * @returns {string} 'Yes' or 'No' translated strings
 */

export const yesOrNoString = (value: string): string => {
  return i18n.t(value ? 'COMMON.YES' : 'COMMON.NO').toString()
}

export default yesOrNoString
