import axios from 'axios'

export default async (path, bustCache = true) => {

  let url = `public/${path}`

  if (bustCache) {
    const baseTimestamp = new Date().setSeconds(0)
    const bustToken = btoa(new Date(baseTimestamp).toGMTString())

    url += `?t=${bustToken}`
  }

  try {
    return axios({ method: 'get', url, baseURL: '/' })
  } catch {
    return {}
  }
}
