import moment from 'moment'
import { isNumber } from '@/utils/filters'
import i18n from '@/scripts/app-configs/i18n-config'
import translateNumericMoth from '@/utils/filters/translate-numeric-month'

type DateType = Date | string | number

type FormatConfig = {
  input?: string
  output?: string
  utc?: boolean
}

/**
 * Date utils filter
 * @name Date Utils filter
 * @returns {*} Uppercase string
 */

const DEFAULT_WHEN_NO_DATA_AVAILABLE = '-'
const DEFAULT_INPUT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const DEFAULT_DURATION_STRING_FORMAT = 'd:h:m'

/**
 * Util function to form reference dates.
 */
function makeDate(reference: DateType, inputFormat: string, utc?: boolean) {
  let date

  if (reference === 'current') {
    date = new Date()

    return date
  }

  if (typeof reference === 'object') {
    // Spec data object is a timestamp
    return reference
  }

  if (typeof reference === 'number') {
    // Reference is a timestamp
    return new Date(reference * 1000) // make it js friendly
  }

  return utc ? moment(reference, inputFormat) : moment(reference, inputFormat).toDate()
}

function makeDateString(date: Date, outputFormat?: string) {
  const dateString = moment(date).format(outputFormat)

  if (/MMM/.test(outputFormat || '') === false) {
    return dateString
  }

  const monthNameObj = translateNumericMoth(date.getMonth() + 1)

  if (!monthNameObj) {
    return null
  }

  if (/MMMM/.test(outputFormat || '')) {
    // long month name
    return dateString.replace(monthNameObj.name, monthNameObj.translatedname.toString())
  }

  // short month name
  return dateString.replace(
    monthNameObj.name.substring(0, 3),
    monthNameObj.translatedname.toString().substring(0, 3),
  )
}

function splitOutputFormat(outputFormat = DEFAULT_DURATION_STRING_FORMAT) {
  const showDays = /d/
  const showHours = /h/
  const showMinutes = /m/
  const showSeconds = /s/

  return {
    showDays: showDays.test(outputFormat),
    showHours: showHours.test(outputFormat),
    showMinutes: showMinutes.test(outputFormat),
    showSeconds: showSeconds.test(outputFormat),
  }
}

function splitDuration(seconds: number, { showDays = false, showHours = true, showMinutes = true, showSeconds = true }) {
  let currentSecs = seconds

  const days = Math.floor(currentSecs / (3600 * 24))

  currentSecs -= days * 3600 * 24

  let hrs = Math.floor(currentSecs / 3600)

  hrs += days && !showDays ? days * 24 : 0
  currentSecs -= hrs * 3600
  currentSecs += days && !showDays ? days * 3600 * 24 : 0

  let mnts = Math.floor(currentSecs / 60)

  mnts += hrs && !showHours ? hrs * 60 : 0
  currentSecs -= mnts * 60
  currentSecs += hrs && !showHours ? hrs * 3600 : 0

  currentSecs += mnts && !showMinutes ? mnts * 60 : 0

  if (!showSeconds) {
    mnts += Math.ceil(currentSecs / 60)
    if (mnts >= 60) {
      hrs += 1
      mnts = 0
    }
    currentSecs = 0
  }

  return {
    days,
    hours: hrs,
    minutes: mnts,
    seconds: currentSecs,
  }
}

function makeDurationStringFromSeconds(seconds: number, outputFormat?: string) {
  if (!isNumber(seconds)) {
    return DEFAULT_WHEN_NO_DATA_AVAILABLE
  }
  const { showDays, showHours, showMinutes, showSeconds } = splitOutputFormat(outputFormat)
  const { days, hours, minutes, seconds: secs } = splitDuration(seconds, { showDays, showHours, showMinutes, showSeconds })

  let formattedTime = ''
  const daysLabel = days > 1 ? i18n.t('COMMON.DAYS') : i18n.t('COMMON.DAY')

  formattedTime += showDays && days ? `${days} ${daysLabel.toString().toLowerCase()} ` : ''
  formattedTime += showHours && hours ? `${hours}h ` : ''
  formattedTime += showMinutes && minutes ? `${minutes}min ` : ''
  formattedTime += showSeconds && secs ? `${secs}${i18n.t('COMMON.SECONDS')}` : ''

  if (!days && !hours && !minutes && !secs) {
    return showSeconds ? '0sec' : '0min'
  }

  return formattedTime.trim()
}

/**
 * Get the person age using his birth date
 *
 * @returns {number}
 */
function getAge(date: DateType): number {
  const currentDate = new Date()
  const birthDate = new Date(date)
  let age = currentDate.getUTCFullYear() - birthDate.getUTCFullYear()
  const month = currentDate.getMonth() - birthDate.getMonth()

  if (month < 0 || (month === 0 && currentDate.getDate() < birthDate.getDate())) {
    age -= 1
  }

  return age
}

/**
 * Format a date string or Date instance so it can be used
 * by a input[type='date']
 *
 * @param {string | Date} date
 * @returns {string}
 */
function formatDateForInput(date: DateType): string | null {
  if (!date) {
    return null
  }

  const d = moment(date)

  const day = d.date()
  const month = d.month() + 1

  const dd = day >= 10 ? day : `0${day}`
  const mm = month >= 10 ? month : `0${month}`
  const yyyy = d.year()

  return `${yyyy}-${mm}-${dd}`
}

export const shDate = (reference: Date | string | number, type: string, formatConfig?: FormatConfig | string) => {

  /* Shorthand for simple output manipulations (use ensuring the input format is correct).
    * Maintain old implementations support
    * i.e. 'YYYY-MM-DD' instead of {output: 'YYYY-MM-DD'}
  */

  let formatObj

  if (!(typeof formatConfig === 'object')) {
    formatObj = {
      input: DEFAULT_INPUT_DATE_FORMAT,
      output: formatConfig,
      utc: false,
    }
  } else {
    formatObj = formatConfig as FormatConfig
  }

  let date

  switch (type) {
    case 'duration':
      if (typeof reference !== 'number') {
        return reference
      }

      return makeDurationStringFromSeconds(reference, formatObj.output)
    case 'date':
      date = makeDate(reference, (formatObj.input || DEFAULT_INPUT_DATE_FORMAT), formatObj.utc) as Date

      return makeDateString(date, formatObj.output)
    case 'age':
      date = makeDate(reference, (formatObj.input || DEFAULT_INPUT_DATE_FORMAT)) as Date | string

      return getAge(date)
    case 'date-input':

      return formatDateForInput(reference)
    default:
      return 'Invalid filter type'
  }
}

export default shDate
