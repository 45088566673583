export default {
  getStepCountGoal: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { memberAccountUUID }) {
      return `therapist/v${configs.api.version}/members/${memberAccountUUID}/step-count/goal`
    },
  },
  updateStepCountGoal: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'put',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { memberAccountUUID }) {
      return `therapist/v${configs.api.version}/members/${memberAccountUUID}/step-count/goal`
    },
  },
}
