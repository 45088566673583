import axios from 'axios'
import router from '@/router'
import store from '@/store/index'
import { newrelic } from '@sword-health/ui-core'
import { isAuthErrorType, HTTP_ERRORS_CODES } from '@/scripts/utils/error-mappings'

const deleteUserAndRouteToLogin = (e) => {
  store.dispatch('user/authentication/logout', { remoteLogout: false })
  router.replace({ name: 'login' }).catch(() => {})

  return Promise.reject(e)
}

const routeToDefaultPage = (e) => {

  // eslint-disable-next-line consistent-return
  if (router.currentRoute.name === 'patients-funnel') return
  router.replace({ name: 'patients-funnel' })

  // eslint-disable-next-line consistent-return
  return Promise.reject(e)
}

/**
 * SWORD jwt interceptor
 */
axios.interceptors.response.use(
  (r) => r,
  async (e) => {
    // Safe check for further logic
    if (!e || !e.response || !e.response.status) {
      return Promise.reject(e)
    }

    newrelic.noticeApiError(e.response.status, e.response)

    // Validate that is and actual known auth issue
    /* eslint-disable no-prototype-builtins */
    if (!HTTP_ERRORS_CODES.hasOwnProperty(e.response.status)) {
      return Promise.reject(e)
    }

    const resp = e.response
    const orgRequest = e.config
    const accessTokenExpired = isAuthErrorType('ACCESS_TOKEN_EXPIRED', resp)
    const refreshToken = store.getters['user/authentication/refreshToken']
    const tryingToRefreshAccessToken = orgRequest.url.includes('auth/refresh')

    const shouldTryToRefreshAccessToken = accessTokenExpired && refreshToken && !tryingToRefreshAccessToken

    if (shouldTryToRefreshAccessToken) {
      console.log('User token is expired... Will refresh')
      try {
        const freshAccessToken = await store.dispatch('user/authentication/refreshAccessToken')

        // Set new token
        store.dispatch('user/authentication/updateAuthTokens', { accessToken: freshAccessToken })
        // Update original request
        orgRequest.headers.Authorization = freshAccessToken

        // Run Previous actions
        return axios.request(orgRequest)
      } catch (error) {
        console.error(error)
        deleteUserAndRouteToLogin(e)
      }
    }

    if (isAuthErrorType('AUTH_INVALID_TOKEN', resp) && HTTP_ERRORS_CODES[e.response.status] === 'FORBIDDEN') {
      console.log('Can\'t access resource. Routing to default page')

      return routeToDefaultPage(e)
    }

    if (isAuthErrorType('*', resp)) {
      console.log('User token is invalid...')

      return deleteUserAndRouteToLogin(e)
    }

    return Promise.reject(e)
  },
)
