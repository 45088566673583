/**
 * Capitalize Strings
 * @name Capitalize String
 * @returns {string} Capitalized string
 */

export const capitalize = (str: string): string => {
  return str ? str.charAt(0).toUpperCase() + str.substr(1).toLowerCase() : ''
}

export default capitalize
