import VueZendesk from '@dansmaculotte/vue-zendesk'
import { CONFIG } from '@/scripts/app-configs/constants'

const TICKET_FORM_ID = 360000184977
const FIELD_ID = {
  programID: 360008376677,
  programUUID: 13694476595095,
  emailPT: 15657742266903,
  unit: 14004927811095,
}

const DEFAULT_WIDGET_SETTINGS = {
  position: { horizontal: 'right', vertical: 'bottom' },
  offset: {
    vertical: '22px',
    horizontal: '22px',
  },
}

export const prefillZendesk = ({ name, email, ...props }) => {
  zE('webWidget', 'reset')
  zE('webWidget', 'prefill', { name: { value: name }, email: { value: email } })
  zE('webWidget', 'updateSettings', {
    webWidget: {
      ...DEFAULT_WIDGET_SETTINGS,
      contactForm: {
        ticketForms: [{
          id: TICKET_FORM_ID,
          fields: Object.keys(props).map((propKey) => ({
            id: FIELD_ID[propKey],
            prefill: { '*': props[propKey] },
          })),
        }],
      },
    },
  })
}

export default {
  install(Vue, options) {
    const state = {
      visible: false,
    }

    Vue.use(VueZendesk, {
      disabled: true, // disabled option allows you to prevent automatic script loading, to comply with GDPR
      hideOnLoad: true,
      webWidget: DEFAULT_WIDGET_SETTINGS,
      ...options,
    })

    Vue.prototype.$zendesk.toggle = () => {
      state.visible ? zE.hide() : zE.activate({ hideOnClose: true })
      state.visible = !state.visible
    }

    Vue.prototype.$zendesk.init = function () {
      const ZENDESK_KEY = ['eu', 'uk'].includes(CONFIG.environment)
        ? '9550338c-2659-4cf0-82a7-912bf25c19a6'
        : '5e3c7932-6684-4396-adb6-c9ca8baa62f8'

      this.$zendesk.load(ZENDESK_KEY)
    }
  },
}
