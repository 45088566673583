<template>
  <div
    :class="['feed-item', {'feed-item--new': isNew }, {'feed-item__clickable': notificationType === 'new_chat_message'}]"
    @click="$emit('item-click')"
  >
    <div
      class="feed-item__patient-photo"
      @click.stop="$emit('picture-click')"
    >
      <profile-picture
        :gender="gender"
        :picture="picture"
        :vip="vip"
        side="2.5rem"
      />
      <div class="feed-item__gender-icon">
        <gender-icon :gender="gender" />
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import GenderIcon from '@ui-kit/components/pictures/GenderIcon.vue'
import ProfilePicture from '@ui-kit/components/pictures/ProfilePicture.vue'

export default {
  name: 'FeedItem',
  components: {
    GenderIcon,
    ProfilePicture,
  },
  props: {
    notificationType: String,
    gender: {
      type: String,
      default: 'unknown',
    },
    picture: {
      type: String,
    },
    vip: Boolean,
    isNew: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
$gender-icon-size: 1.5rem;

.feed-item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  transition: background-color 0.3s ease-in-out;

  &__clickable {
    cursor: pointer;
  }

  &:hover {
    background-color: $context-color-7;
  }

  &--new {
    background-color: rgba($color-primary-500, 0.1);

    .profile-picture-holder {
      position: relative;

      &::before {
        @include dot-indicator;
        left: -0.7rem;
      }
    }
  }

  &__patient-photo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__gender-icon {
    width: $gender-icon-size;
    height: $gender-icon-size;
    margin-top: calc(-#{$gender-icon-size} / 2);
    background-color: #ffffff;
    border-radius: 50%;
    padding: 0.2rem;
    z-index: 0;
    border: 1px solid $context-color-7;
  }
}
</style>
