const externalLinkOptions = [
  {
    optGroupLabel: 'APPOINTMENT_LINK.RESCHEDULE_OB_CALL',
    options: [
      {
        optLabel: 'APPOINTMENT_LINK.COPY_TO_CLIPBOARD',
        optValue: 'copy-ob-call-link',
        copyable: true,
      },
    ],
  },
  {
    optGroupLabel: 'APPOINTMENT_LINK.REASSESSMENT_CALL',
    options: [
      { optLabel: 'APPOINTMENT_LINK.SEND_TO_MEMBER', optValue: 'send-reassessment-link' },
      {
        optLabel: 'APPOINTMENT_LINK.COPY_TO_CLIPBOARD',
        optValue: 'copy-reassessment-link',
        copyable: true,
      },
    ],
  },
  {
    optGroupLabel: 'APPOINTMENT_LINK.CHECK_IN_CALL',
    options: [
      { optLabel: 'APPOINTMENT_LINK.SEND_TO_MEMBER', optValue: 'send-check-in-link' },
      {
        optLabel: 'APPOINTMENT_LINK.COPY_TO_CLIPBOARD',
        optValue: 'copy-check-in-link',
        copyable: true,
      },
    ],
  },
]

export const QUICK_ACTIONS_CONFIG = [
  {
    key: 'open-dt-message',
    icon: 'mail',
    tooltip: 'DT.MESSAGE',
    disabled: true,
  },
  {
    key: 'open-notes',
    icon: 'notes',
    tooltip: 'copy_2673',
  },
  {
    key: 'send-appointment-links',
    icon: 'external-link',
    tooltip: 'SEND_APPOINTMENT_LINK',
    options: externalLinkOptions,
  },
]
