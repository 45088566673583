/**
 * transform camel case strings to snake case
 * @name Camel to Snake Case
 * @returns {string} Snake case string
 */

export const camelToSnakeCase = (str: string): string => {
  return str ? str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`) : ''
}

export default camelToSnakeCase
