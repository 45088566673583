import { deepCopyObj } from '@/scripts/tools/helper-tools'

function extractMeaningFromProtocolExercise(protocolExercise) {
  return {
    repeats: protocolExercise.reps,
  }
}

export default (therapyExercises, protocolSessionExercises, activeResistancesForSessionCategory) => {

  let exercisesToAdd = []
  let maxOrderExercise = 1
  let totalAddedExercises = 0

  const fullExercisesList = therapyExercises.map((therapyExercise) => {
    let exerciseForList = therapyExercise

    // Find current exercise on previous prescribed exercises
    // const protocolExercise = protocolSessionExercises.find(ex => ex.exercise_code === exerciseForList.code);
    const protocolExerciseInstances = protocolSessionExercises.filter((ex) => ex.exercise_code === exerciseForList.code)

    if (!protocolExerciseInstances.length) {
      exerciseForList.series = 0

      return exerciseForList
    }

    /**
     * If exercise gets found on protocol specification...
     * Apply all changes from spec
     */

    const protocolExerciseInstRef = protocolExerciseInstances[0]
    const protocolExerciseChangesToBeApplied = extractMeaningFromProtocolExercise(protocolExerciseInstRef)

    // Update exercise with protocol session spec and ensure number of series prescribed
    exerciseForList = Object.assign(exerciseForList, protocolExerciseChangesToBeApplied, { series: protocolExerciseInstances.length })

    const exerciseToAdd = protocolExerciseInstances.map((instance) => {
      if (maxOrderExercise < instance.order) {
        maxOrderExercise = instance.order
      }

      const exerciseCopy = deepCopyObj(exerciseForList)

      Object.assign(exerciseCopy, {
        series: 1, // Force exercise series to be 1, as they're replicated
        order: instance.order || maxOrderExercise, // If not ordered use last position
        resistance: activeResistancesForSessionCategory.indexOf(instance.exercise_code) !== -1,
      })

      return exerciseCopy
    })

    exercisesToAdd = exercisesToAdd.concat(exerciseToAdd)
    totalAddedExercises += exerciseForList.series

    return exerciseForList
  })

  return {
    maxOrder: maxOrderExercise,
    numOfExercises: totalAddedExercises,
    exercisesList: fullExercisesList,
    exercisesAdded: exercisesToAdd,
  }
}
