<template>
  <transition name="base-feedback-screen-transition">
    <div class="full-screen-feedback-container text-center content-block" :class="{ 'animated-container': animated, transparent }">
      <div class="full-screen-feedback-container-anchor-el" :class="{ 'center-content': centerContent }">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import feedbackScreenProps from '@/mixins/components/feedbackScreenProps'

export default {
  name: 'BaseFeedbackScreenStructure',
  mixins: [feedbackScreenProps],
}
</script>

<style lang="scss" scoped>
// TODO MOVE TO ANIMATIONS FILE WHEN MOVED TO UI KIT
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pop-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pop-out {
  100% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.9);
  }
  0% {
    transform: scale(1);
  }
}

.full-screen-feedback-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9;
  overflow: hidden;

  &:not(.transparent) {
    background-color: $background-color;
  }

  .full-screen-feedback-container-anchor-el {
    position: relative;

    width: 100%;
    height: 100%;

    &.center-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

$background-animation-duration: 0.3s;
$content-animation-duration: 0.2s;

// Animation

.animated-container {
  &.base-feedback-screen-transition-enter-active,
  &.base-feedback-screen-transition-leave-active {
    animation-name: fade-in;
    animation-duration: $background-animation-duration;
  }

  &.base-feedback-screen-transition-enter-active {
    &.full-screen-feedback-container {
      animation-direction: normal;
    }

    .full-screen-feedback-container-anchor-el {
      animation-name: pop-in;
      animation-duration: $content-animation-duration;
    }
  }

  &.base-feedback-screen-transition-leave-active {
    &.full-screen-feedback-container {
      animation-direction: reverse;
    }

    .full-screen-feedback-container-anchor-el {
      animation: pop-out $content-animation-duration;
    }
  }
}
</style>
