import { CONFIG } from '@/scripts/app-configs/constants'

export default {
  addPatient: {
    api: {
      url: 'default',
      version: '1.1',
    },
    $$method: 'post',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs) {
      return `api/v${configs.api.version}/patients`
    },
  },
  card: {
    assignCard: {
      api: {
        url: CONFIG.cardsApiUrl,
      },
      $$method: 'put',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { number, cvv }) {
        return `${configs.api.url}/v1/${number}-${cvv}/assign`
      },
    },
    revokeCard: {
      api: {
        url: CONFIG.cardsApiUrl,
      },
      $$method: 'put',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, cardUUID) {
        return `${configs.api.url}/v1/${cardUUID}/revoke`
      },
    },
    unlockCard: {
      api: {
        url: CONFIG.cardsApiUrl,
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl() {
        return 'api/v1/cards/reset'
      },
    },
  },
  profile: {
    getPatient: {
      api: {
        url: 'default',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$meta: {
        mock: (_, { patientID }) => `mocks/endpoints/patient/profile/getPatient/${patientID}.json`,
      },
      $$makeUrl(configs, { patientID, include }) {
        const url = `therapist/v1/patients/${patientID}/profile`

        const queryParams = new URLSearchParams('')

        if (include) {
          queryParams.append('include', include)
        }

        return `${url}?${queryParams}`
      },
    },
    getClinicalPatientMetrics: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, programUUID ) {
        return `therapist/v${configs.api.version}/clinical-patients/${programUUID}`
      },
    },
    updateProfile: {
      api: {
        url: 'default',
      },
      $$method: 'put',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { patientId }) {
        return `api/v1.1/patients/${patientId}`
      },
    },
    updatePatientPersonalInformation: {
      api: {
        url: 'default',
      },
      $$method: 'put',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { patientId }) {
        return `therapist/v1/patients/${patientId}/personal-information`
      },
    },
    getPatientProperties: {
      api: {
        url: 'default',
        version: '2',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$meta: {
        mock: (_, { patientId }) => `mocks/endpoints/patient/profile/getPatientProperties/${patientId}.json`,
      },
      $$makeUrl(configs, { patientId, onboardingVersion, uuid, institutionId, withLicenseState, enrollmentFilters, countryStateCode, unit }) {
        const url = `/therapist/v${configs.api.version}/patients/${patientId}/properties`

        const queryParams = new URLSearchParams('')

        if (onboardingVersion) {
          queryParams.append('onboardingVersion', onboardingVersion)
        }

        if (uuid) {
          queryParams.append('uuid', uuid)
          queryParams.append('enrollmentFilters', enrollmentFilters)
        }

        if (institutionId) {
          queryParams.append('institutionId', institutionId)
        }

        if (withLicenseState) {
          queryParams.append('withLicenseState', withLicenseState)
        }

        if (countryStateCode) {
          queryParams.append('countryStateCode', countryStateCode)
        }

        if (unit) {
          queryParams.append('unit', unit)
        }

        return `${url}?${queryParams}`
      },
    },
    resetLastContactDate: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { patientId }) {
        return `therapist/v${configs.api.version}/patients/${patientId}/contact-record`
      },
    },
    setAttentionLevel: {
      api: {
        url: 'default',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, patientId) {
        return `therapist/v1/patient/${patientId}/attention-level`
      },
    },
  },
  professionals: {
    updateSharedProfessionals: {
      api: {
        url: 'default',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, patientId) {
        return `api/v1.1/patients/${patientId}/professionals`
      },
    },
  },
  forms: {
    fetchFilters: {
      api: {
        url: 'default',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl() {
        return 'clinical-forms/api/v1/form/filters'
      },
    },
    getForms: {
      api: {
        url: 'default',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$meta: {
        mock: (_, { patientId }) => `mocks/endpoints/patient/getForms/${patientId}.json`,
      },
      $$makeUrl(configs, { patientId }) {
        return `therapist/v1/forms/${patientId}`
      },
    },
    getFormsAnswers: {
      api: {
        url: 'default',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { formId }) {
        return `clinical-forms/api/v1/submit/${formId}`
      },
    },
    getDynamicFields: {
      api: {
        url: 'default',
        version: 1,
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$meta: {
        mock: () => 'mocks/endpoints/patient/forms/getDynamicFields.json',
      },
      $$makeUrl(configs, { patientID, formKey = null }) {
        const url = `therapist/v${configs.api.version}/patients/${patientID}/dynamic-fields`

        const queryParams = new URLSearchParams({ formKey })

        return `${url}?${queryParams}`
      },
    },
    generateForm: {
      api: {
        url: 'default',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl() {
        return 'clinical-forms/api/v1'
      },
    },
    deleteForm: {
      api: {
        url: 'default',
      },
      $$method: 'delete',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, {
        patientId,
        formId,
      }) {
        return `clinical-forms/api/v1/${patientId}/${formId}`
      },
    },
    reactivateForm: {
      api: {
        url: 'default',
      },
      $$method: 'put',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, {
        patientId,
        formId,
      }) {
        return `clinical-forms/api/v1/${patientId}/${formId}`
      },
    },
    generateFile: {
      api: {
        url: 'default',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { formId }) {
        return `clinical-forms/api/v1/submit/${formId}/document`
      },
    },
  },
  state: {
    setPatientState: {
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { patientId }) {
        return `therapist/v2/patients/${patientId}/state`
      },
    },
    cancelScheduledTransition: {
      $$method: 'put',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { patientId }) {
        return `therapist/v1/patients/${patientId}/cancel-scheduled-transition`
      },
    },
  },
  onboarding: {
    referrals: {
      fetchEcosystemVendorsServices: {
        api: {
          version: '1',
        },
        $$method: 'get',
        $$config: {
          headers: (data) => data.headers.authorization,
        },
        $$meta: {
          mock: () => 'mocks/endpoints/patient/onboarding/referrals/getVendorsServices.json',
        },
        $$makeUrl(configs, { institutionId, locale }) {
          const url = `therapist/v${configs.api.version}/care-ecosystem/institutions/${institutionId}/vendors-services`
          const queryParams = new URLSearchParams({ locale })

          return `${url}?${queryParams}`
        },
      },
      createEcosystemReferral: {
        api: {
          version: '1',
        },
        $$method: 'post',
        $$config: {
          headers: (data) => data.headers.authorization,
        },
        $$makeUrl(configs, patientId) {
          return `therapist/v${configs.api.version}/patients/${patientId}/care-ecosystem/referrals`
        },
      },
      fetchEcosystemReferrals: {
        api: {
          version: '1',
        },
        $$method: 'get',
        $$config: {
          headers: (data) => data.headers.authorization,
        },
        $$meta: {
          mock: () => 'mocks/endpoints/patient/onboarding/referrals/getPatientReferrals.json',
        },
        $$makeUrl(configs, patientId) {
          return `therapist/v${configs.api.version}/patients/${patientId}/care-ecosystem/referrals`
        },

      },
      createReferral: {
        api: {
          url: CONFIG.onboardingApiUrl,
          version: '1',
        },
        $$method: 'post',
        $$config: {
          headers: (data) => data.headers.authorization,
        },
        $$makeUrl(configs) {
          return `${configs.api.url}/v${configs.api.version}/referrals/`
        },
      },
      updateReferral: {
        api: {
          url: CONFIG.onboardingApiUrl,
          version: '1',
        },
        $$method: 'put',
        $$config: {
          headers: (data) => data.headers.authorization,
        },
        $$makeUrl(configs, referralUUID) {
          return `${configs.api.url}/v${configs.api.version}/referrals/${referralUUID}`
        },
      },
      resubmitReferral: {
        api: {
          url: CONFIG.onboardingApiUrl,
          version: '2',
        },
        $$method: 'post',
        $$config: {
          headers: (data) => data.headers.authorization,
        },
        $$makeUrl(configs, referralUUID) {
          return `${configs.api.url}/v${configs.api.version}/referrals/${referralUUID}/resubmit`
        },
      },
    },
  },
  notes: {
    fetchNotes: {
      api: {
        url: 'default',
        version: '1.1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, patientId) {
        return `api/v${configs.api.version}/patients/${patientId}/notes`
      },
    },
    createNote: {
      api: {
        url: 'default',
        version: '1.1',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, patientId) {
        return `api/v${configs.api.version}/patients/${patientId}/notes`
      },
    },
    updateNote: {
      api: {
        url: 'default',
        version: '1.1',
      },
      $$method: 'put',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { patientId, noteId }) {
        return `api/v${configs.api.version}/patients/${patientId}/notes/${noteId}`
      },
    },
    deleteNote: {
      api: {
        url: 'default',
        version: '1.1',
      },
      $$method: 'delete',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { patientId, noteId }) {
        return `api/v${configs.api.version}/patients/${patientId}/notes/${noteId}`
      },
    },

  },
  discoverChat: {
    api: {
      url: 'default',
      version: '2',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { memberAccountUuid, unit }) {
      return `therapist/v${configs.api.version}/chat/member/${memberAccountUuid}/channel/${unit}`
    },
  },
  getConfirmationCode: {
    api: {
      url: 'default',
      version: '1.1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      mock: () => 'mocks/endpoints/patient/getConfirmationCode.json',
    },
    $$makeUrl(configs, patientId) {
      return `api/v1.1/users/${patientId}/confirmation/codes`
    },
  },
  getCCDA: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      mock: ( _, memberAccountID ) => `mocks/endpoints/patient/getCCDA/${memberAccountID}.xml`,
      forceMock: CONFIG.HGMockCCDA,
    },
    $$makeUrl(configs, memberAccountID) {
      return `therapist/v${configs.api.version}/patients/${memberAccountID}/ccda`
    },
  },
  fetchPatientSummarySidebarInfo: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, patientUuid) {
      const url = `therapist/v${configs.api.version}/patients/summary-side-bar`
      const queryParams = new URLSearchParams('')

      if (patientUuid) {
        queryParams.set('patient_uuid', patientUuid)
      }

      return `${url}?${queryParams}`
    },
  },
  generateAppointmentLink: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'post',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, patientUuid) {
      return `therapist/v${configs.api.version}/patients/${patientUuid}/generate-appointment-link`
    },
  },
  geoConfigs: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(config, patientId) {
      return `api/v${config.api.version}/patients/${patientId}/geo-configs`
    },
  },
  generatePdf: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'post',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(config, { programUuid, unit }) {
      return `therapist/v${config.api.version}/program-summary/${unit}/${programUuid}`
    },
  },
}
