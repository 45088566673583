<template>
  <transition name="send-modal">
    <div v-if="isModalOpen" class="modal-backdrop send-message-backdrop" @click="closeModal">
      <send-message
        :sending="sending"
        :send-message-outcome="sendMessageOutcome"
        :default-recipient="defaultRecipient"
        :patients-list="list"
        @send-message="sendMessage"
        @close-modal="closeModal"
        @reset-suggestions="clearSearch"
        @fetch-patients="handleSearch"
      />
    </div>
  </transition>
</template>

<script>
import Vue, { computed, ref } from 'vue'
import { useQuery } from '@tanstack/vue-query'

// Components
import SendMessage from '@ui-kit/components/dt-messages/SendMessage.vue'

// Utils
import { triggers } from '@/scripts/global-modals-commands'

const MESSAGE_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export default {
  name: 'SendMessageModal',
  components: {
    SendMessage,
  },
  props: {
    modalOpen: Boolean,
  },
  setup(props) {
    const search = ref('')
    const isModalOpen = computed(() => !!props.modalOpen)
    const isEnabled = computed(() => !!search.value.length)

    const { data: list } = useQuery({
      queryKey: ['notes-modal-members', search],
      retry: 0,
      enabled: isEnabled,
      staleTime: Infinity,
      queryFn: async () => {
        const { data } = await Vue.$http('patientsList/fetchAllPatients', { search: search.value })

        return data
      },
      select: (members) => {
        return members.map((member) => ({
          ...member,
          vip: member.is_vip_account || false,
          name: `${member.firstname} ${member.lastname}`,
        }))
      },
    })

    function handleSearch(value) {
      search.value = value
    }

    function clearSearch() {
      search.value = ''
    }

    return {
      list,
      isModalOpen,
      // methods
      handleSearch,
      clearSearch,
    }
  },
  data() {
    return {
      sending: false,
      sendMessageOutcome: '',
    }
  },
  computed: {
    isMemberRoute() {
      return !!this.$route.params?.patientID
    },
    defaultRecipient() {
      // checks if current route is member's profile route and gets the data of selected patient
      if (this.isMemberRoute) {
        const patient = this.$store.getters['patient/getMemberBasics']

        if (!patient.id) {
          return null
        }

        return this.getFormattedPatientInfoForMessageForm(patient)
      }
      const todoListPatient = this.$store.getters['general/getSelectedPatient']

      if (todoListPatient) {
        return todoListPatient
      }

      return null
    },
  },
  methods: {
    async sendMessage(messageData) {
      try {
        this.sending = true
        this.sendMessageOutcome = ''
        const professionalRefID = this.$store.getters['user/getUserId']

        await this.$http('general/sendMessage', null, { body: { ...messageData, professionalRefID } })
        this.sendMessageOutcome = MESSAGE_STATUS.SUCCESS
        setTimeout(this.closeModal, 1000)
      } catch (e) {
        this.sendMessageOutcome = MESSAGE_STATUS.ERROR
        console.error(`It was not possible to send the message to member with id ${messageData}.`)
      } finally {
        this.sending = false
      }
    },
    closeModal() {
      triggers.closeSendMessage()
      this.sendMessageOutcome = ''
    },
    getFormattedPatientInfoForMessageForm(patient) {
      return {
        user_id: patient.id,
        firstname: patient.firstname,
        lastname: patient.lastname,
        name: `${patient.firstname} ${patient.lastname}`,
        gender: patient.gender,
        picture: patient.picture,
        institution_id: patient.institutionId,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-backdrop.send-message-backdrop {
  background-color: transparent;
}

.send-modal-enter,
.send-modal-leave-to {
  opacity: 0;
  transform: translateX(110%);
}

.send-modal-enter-to,
.send-modal-leave {
  opacity: 1;
  transform: translateX(0);
}

.send-modal-enter-active {
  transition: all 0.3s ease-out;
}

.send-modal-leave-active {
  transition: all 0.1s ease-in;
}
</style>
