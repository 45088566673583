export default {
  props: {
    animated: {
      type: Boolean,
      default: true,
    },
    centerContent: {
      type: Boolean,
      default: true,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
  },
}
