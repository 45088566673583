import i18n from '@/scripts/app-configs/i18n-config'

type SortableItem = string | { [key: string]: string };

/**
 * Sort plain or objects array alphabetically filter
 * @name SortAlphabetically Utils filter
 * @param {array} arrayToSort Array to sort
 * @param {string} sortByKey Key to sort by
 * @returns {array} Alphabetically sorted Array
 */
export const sortAlphabetically = <T extends SortableItem>(arrayToSort: T[], sortByKey?: string): T[] => {
  if (!Array.isArray(arrayToSort)) {
    console.warn('[sort-alpha-filter] Unable to alphabetically sort provided source')

    return arrayToSort
  }

  return arrayToSort.sort((a: T, b: T) => {
    const aVal = typeof a === 'string' ? a : a[sortByKey || ''] || ''
    const bVal = typeof b === 'string' ? b : b[sortByKey || ''] || ''

    return i18n.t(aVal).toString().localeCompare(i18n.t(bVal).toString())
  })
}

export default sortAlphabetically
