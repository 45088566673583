export default ({ enabled = [], disabled = [] }) => {

  const formatResistanceTracking = (action) => {
    return Object.entries(action).map(([category, codes]) => {
      return { session_category: category, exercise_codes: codes }
    })
  }

  const enabledResistancedChanges = formatResistanceTracking(enabled)
  const disabledResistancedChanges = formatResistanceTracking(disabled)

  if (!enabledResistancedChanges.length && !disabledResistancedChanges.length) {
    return {}
  }

  const resistancePropagation = {}

  Object.assign(resistancePropagation, enabledResistancedChanges.length ? { resistance_enabled: enabledResistancedChanges } : null)
  Object.assign(resistancePropagation, disabledResistancedChanges.length ? { resistance_disabled: disabledResistancedChanges } : null)

  return {
    resistance_propagation: resistancePropagation,
  }
}
