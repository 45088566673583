/* eslint-disable consistent-return */
import axios from 'axios'
import store from '@/store'
import { EndpointDecoder } from '@sword-health/ui-http-mapper'
import { CONFIG } from '@/scripts/app-configs/constants'
import { endpointsSpec, generateAuthTokens } from '@/http/endpoints'
import '@/http/interceptors/request-interceptors'
import '@/http/interceptors/response-interceptors'
import getLocalFiles from '@/scripts/tools/get-local-files'
import dayjs from 'dayjs'

const swordClinicalPortalEndpointInterface = new EndpointDecoder(endpointsSpec)

store.subscribe((mutation) => {
  const userTokensChanges = ['user/authentication/setAuthTokens', 'user/authentication/loadStoredAuthTokens', 'user/resetProfile']

  if (userTokensChanges.includes(mutation.type)) {
    swordClinicalPortalEndpointInterface.dataUpdate = { headers: generateAuthTokens() }
  }
})

axios.defaults.baseURL = CONFIG.baseUrl

function axiosWrapper(method, url, ...args) {
  const configIndex = args.length - 1
  const source = axios.CancelToken.source()

  args[configIndex] = Object.assign(args[configIndex], {
    cancelToken: source.token,
  })

  const request = axios[method](url, ...args)

  request.cancel = source.cancel

  return request
}

function swordHttpClient(...args) {
  const endpointConfig = swordClinicalPortalEndpointInterface.$http(...args)

  if (!endpointConfig) {
    return
  }

  const { method, url, requestArgs, meta } = endpointConfig

  // necessary for HG to work with the current demo env, to be removed when new demo is ready
  if ((CONFIG.useHttpMocks || meta?.forceMock) && process.env.NODE_ENV !== 'test') {
    if (!meta?.mock) {
      console.error(`No mockData found for endpoint ${url} - ${args[0]}`)

      return Promise.reject(new Error(`No mockData found for endpoint ${url}`))
    }

    const [defPath, requestData, requestConfigs] = args
    const requestBody = requestConfigs?.body
    const mockData = meta.mock(defPath, requestData, requestBody)

    if (!mockData) {
      console.error(`Empty mockData found for endpoint ${url} - ${args[0]}`)

      return Promise.reject(new Error(`Empty mockData found for endpoint ${url} - ${args[0]}`))
    }

    const localStaticURL = '/static/mocks'

    return getLocalFiles(mockData).then((mockObj) => {
      let mockDataString = JSON.stringify(mockObj.data)

      mockDataString = mockDataString.replaceAll('[LOCAL_STATIC_URL]', localStaticURL)
        .replaceAll(
          /\[DATE_([0-9]+)_(DAY|MONTH|YEAR)_(AGO|AHEAD)\](?:\[([^\]]+)\])?/g,
          (str, amount, frame, verb, format) => {
            /*
            * Example: [DATE_3_DAY_AGO][YYYY-MM-DD HH:mm:ss]
            */
            const now = new Date()
            const operator = verb === 'AGO' ? -1 : 1
            const directionalAmount = amount * operator

            if (frame === 'DAY') {
              now.setDate(now.getDate() + directionalAmount)
            } else if (frame === 'MONTH') {
              now.setMonth(now.getMonth() + directionalAmount)
            } else if (frame === 'YEAR') {
              now.setFullYear(now.getFullYear() + directionalAmount)
            }

            return format ? dayjs(now).format(format) : now.getTime()
          },
        )
      mockObj.data = JSON.parse(mockDataString)

      const errorProps = ['error_code', 'error_description']
      const errorMock = Object.keys(mockObj.data).some((mp) => {
        const errorPropUsed = errorProps.includes(mp)
        const accessDenied = mp === 'access' && mockObj.data.access === 'denied'

        return errorPropUsed || accessDenied
      })

      if (errorMock) {
        mockObj.response = { data: mockObj.data }
        delete mockObj.data

        return Promise.reject(mockObj)
      }

      return Promise.resolve(mockObj)
    })
  }

  // eslint-disable-next-line no-unused-vars
  const config = requestArgs[1]

  if (config?.headers && config['DPT-Activity-UUID']) {
    config.headers['DPT-Activity-UUID'] = config['DPT-Activity-UUID']
  }

  if (method === 'delete') {
    const [body] = requestArgs

    config.data = body

    return axiosWrapper(method, url, config)
  }

  return axiosWrapper(method, url, ...requestArgs)
}

const GLOBAL_ALIAS = '$http'

export default {
  install(Vue) {
    Vue[GLOBAL_ALIAS] = swordHttpClient
    Vue.prototype.$http = swordHttpClient
  },
}
