export default {
  history: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta:{
      mock: () => 'mocks/endpoints/activity-reports/history.json',
    },
    $$makeUrl(configs, { memberAccountUUID, startDate, endDate } ) {

      const url = `therapist/v${configs.api.version}/members/${memberAccountUUID}/activity-reports/history`
      const queryParams = new URLSearchParams()

      if (startDate) {
        queryParams.set('start_date', startDate)
      }

      if (endDate) {
        queryParams.set('end_date', endDate)
      }

      return `${url}?${queryParams}`
    },
  },
  getActivityReports: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      mock: () => {
        return 'mocks/endpoints/activity-reports/activity-reports.json'
      },
    },
    $$makeUrl(configs, { startDate, endDate }) {
      return `therapist/v${configs.api.version}/members/activity-reports?start_date=${startDate}&end_date=${endDate}`
    },
  },
  generateFile: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      mock: () => {
        return 'mocks/endpoints/activity-reports/generate-file.json'
      },
    },
    $$makeUrl(configs, { memberAccountUUID, startDate, endDate, fileType }) {
      const url = `therapist/v${configs.api.version}/members/${memberAccountUUID}/activity-reports/file`
      const queryParams = new URLSearchParams()

      if (startDate) {
        queryParams.set('start_date', startDate )
      }

      if (endDate) {
        queryParams.set('end_date', endDate)
      }

      if (fileType) {
        queryParams.set('file_type', fileType)
      }

      return `${url}?${queryParams}`

    },
  },
}
