<template>
  <sword-tag-wuk
    v-tooltip="{
      content: 'Gutenberg flow',
      theme: 'sword-tooltip',
    }"
    class="gutenberg-highlight"
    color="black"
    size="small"
    text="G"
  />
</template>

<script>
import SwordTagWuk from '@ui-kit/components/tag/Tag.vue'

export default {
  name: 'GutenbergHighlight',
  components: { SwordTagWuk },
}
</script>

<style lang="scss" scoped>
.gutenberg-highlight {
  margin-right: 0.25rem;
}
</style>
