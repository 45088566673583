/**
 * Get number with selected fraction size.
 * @name Number fraction size
 * @param {number} number - Value
 * @param {number} fractionSize - Number of digits after the decimal point
 * @returns {string} transformed number
 */

export const fractionNumber = (number: string, fractionSize: number, zeroDecimals: boolean = false): string => {
  if (Number(number) % 1 === 0 && !zeroDecimals) {
    return number
  }

  return Number.parseFloat(number).toFixed(fractionSize)
}

export default fractionNumber
