import axios from 'axios'
import * as config from '@/scripts/app-configs/config'

const aclModuleURL = config.get('ACL_MODULE_URL') || ''
const cookieName = config.get('ACL_MODULE_COOKIE_NAME') || 'sh.acl.auth'
const appCookieName = config.get('VUE_APP_AUTH_COOKIE_NAME') || 'sh.portal.auth'
const appCookieTime = config.get('VUE_APP_AUTH_COOKIE_TIME') || 3600

function isACLModuleEnabled() {
  try {
    return JSON.parse(config.get('ACL_MODULE_ENABLED'))
  } catch (e) {
    return false
  }
}

function redirectToSignIn() {
  const encodedCurrentLocation = encodeURIComponent(window.location.href)

  window.location.assign(`${aclModuleURL}/v1/sign-in?redirect_url=${encodedCurrentLocation}`)
}

function requestUserAccess() {
  if (!isACLModuleEnabled()) {
    console.log('ACL Module disabled, ignoring')

    return Promise.resolve()
  }

  if (!document.cookie.includes(cookieName)) {
    return Promise.reject(Error('Auth cookie missing'))
  }

  console.log('Using ACL Module to verify user access')

  if (document.cookie.includes(appCookieName)) {
    return Promise.resolve()
  }

  const requestOpts = {
    withCredentials: true,
    params: {
      application: 'clinical-portal',
      region: config.get('REGION') || window.location.hostname.split('.')[0],
    },
  }
  // To ignore possible interceptors added by the app
  const axiosInstance = axios.create({ baseURL: aclModuleURL })

  return axiosInstance.get('v1/login', requestOpts).then(({ data }) => {
    // eslint-disable-next-line no-restricted-globals
    document.cookie = `${appCookieName}=${data.token}; samesite=none; secure; domain=${location.hostname};max-age=${appCookieTime}`

    return Promise.resolve()
  }).catch(() => Promise.reject())
}

export default {
  requestUserAccess,
  redirectToSignIn,
}
