// TODO Merge into useMemberState Composable
import { PATIENT_UNIT } from '@/scripts/configs/patient-units'

const stateDecodeRegExp = new RegExp(/(\w+)_(\d+)/)

export const acceptedManualStateTransitionsConfigs = {
  530: {
    description: 'Exclude from Enrolled',
    rangeLength: 10,
    reversible: [610, 619],
    considerInactive: true,
    context: 'excluded',
  },
  540: {
    description: 'Excluded from Active',
    rangeLength: 10,
    considerInactive: true,
    reversible: [620, 629],
    context: 'excluded',
  },
  700: {
    description: 'On-Hold from Enrolled',
    rangeLength: 10,
    reversible: [300, 399],
    considerInactive: true,
    context: 'on_hold',
  },
  710: {
    description: 'On-Hold from Active',
    rangeLength: 10,
    reversible: [400, 499],
    considerInactive: true,
    context: 'on_hold',
  },
  720: {
    description: 'On-Hold from Treated',
    rangeLength: 10,
    considerInactive: true,
    context: 'on_hold',
  },
  730: {
    description: 'On-Hold from Discharged',
    rangeLength: 10,
    considerInactive: true,
    context: 'on_hold',
  },
  900: {
    description: 'Closed from Enrolled',
    rangeLength: 10,
    considerInactive: true,
    context: 'closed',
  },
  910: {
    description: 'Closed from Active',
    rangeLength: 10,
    considerInactive: true,
    context: 'closed',
  },
  920: {
    description: 'Closed from Treated',
    rangeLength: 10,
    considerInactive: true,
    context: 'closed',
    excludeForUnitsWhenCurrentStatus: {
      // Use '*' as value to fully exclude for a unit
      [PATIENT_UNIT.MOVE]: [800], // < when Treatment 800.
    },
  },
  930: {
    description: 'Closed from Discharge',
    rangeLength: 10,
    considerInactive: true,
    context: 'closed',
  },
}

// Candidate code is within a valid range of manual transitions
function codeInsideValidRange(codeToTest, rangeMin, rangeMax) {
  const numericCode = Number(codeToTest)

  return rangeMin <= numericCode && numericCode <= rangeMax
}

function findStateConfigByCodeRange(codeToTest) {
  const stateConfig = Object.entries(acceptedManualStateTransitionsConfigs).find(([code, configs]) => {
    const rangeBaseCode = Number(code)

    return codeInsideValidRange(codeToTest, rangeBaseCode, (rangeBaseCode + configs.rangeLength - 1))
  })

  return stateConfig && stateConfig[1]
}

// Decodes state and splits if for name and code
const stateDecoder = (state) => {
  try {
    const stateData = state.match(stateDecodeRegExp)
    const [handler, name, code] = stateData
    const stateConfig = findStateConfigByCodeRange(Number(code))

    return {
      handler,
      name,
      code: Number(code),
      isInactive: !!(stateConfig && stateConfig.considerInactive),
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}

// Gets a complete state def (enrolled_300) or code and gets its configs
export const getStateConfigs = (state) => {
  let stateData = {}

  try {
    stateData = stateDecoder(state)
  } catch (e) {
    console.log('Unable to decode state')
  }

  const CSS_CLASS_PREFIX = 'patient-state-'
  const memberStatusHyphenated = stateData.name.replace(/_/g, '-')

  let tagStyleModifier = ''

  switch (memberStatusHyphenated) {
    case 'pre-enrolled':
    case 'enrolled':
    case 'treated':
      tagStyleModifier = 'blue'
      break
    case 'active':
      tagStyleModifier = 'green'
      break
    case 'maintenance':
      tagStyleModifier = 'yellow'
      break
    case 'excluded':
    case 'dropout':
      tagStyleModifier = 'red'
      break
    case 'inactive':
    case 'on-hold':
    case 'discharged':
    case 'unknown':
      tagStyleModifier = 'grey'
      break
    default:
      tagStyleModifier = 'black'
      break
  }

  return {
    ...stateData,
    tagStyleModifier,
    cssClass: stateData.name ? `${CSS_CLASS_PREFIX}${memberStatusHyphenated}` : `${CSS_CLASS_PREFIX}unknown`,
    copy: `patient_state_${stateData.name}`,
  }
}

export const filterTransitions = (currentState, possibleTransitions, memberUnit) => {

  if (!possibleTransitions || !possibleTransitions.length) {
    return []
  }

  // Decode Current state
  let currentStateData = {}

  try {
    currentStateData = stateDecoder(currentState)
  } catch (e) {
    console.log('Unable to decode current state')

    return []
  }
  const { code: currentStateCode } = currentStateData

  return possibleTransitions.filter((candidate) => {
    let candidateStateData = {}

    // Decode Possible state
    try {
      candidateStateData = stateDecoder(candidate)
    } catch (e) {
      console.log('Unable to decode state')

      return false
    }
    const { code: candidateStateCode } = candidateStateData

    // Test if any possible transition matches re
    return Object.entries(acceptedManualStateTransitionsConfigs).some(([code, configs]) => {

      // Rules are appliable to all units, unless explicitly excluded by unit or current status
      const unitSpecificRules = configs.excludeForUnitsWhenCurrentStatus && configs.excludeForUnitsWhenCurrentStatus[memberUnit]

      if (unitSpecificRules && (unitSpecificRules === '*' || unitSpecificRules.includes(currentStateCode))) return false

      // Check is declared valid transition
      const rangeBaseCode = Number(code)
      const validCandidateCode = codeInsideValidRange(candidateStateCode, rangeBaseCode, (rangeBaseCode + configs.rangeLength - 1))

      if (validCandidateCode) {
        return true
      }

      // Check is we're already on a accepted state and if it could be a reverse transition from current state
      const shouldCheckReversible = codeInsideValidRange(currentStateCode, rangeBaseCode, (rangeBaseCode + configs.rangeLength - 1))

      if (!configs.reversible || !shouldCheckReversible) {
        return false
      }

      return codeInsideValidRange(candidateStateCode, configs.reversible[0], configs.reversible[1])
    })
  })
}
