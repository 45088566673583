import Vue from 'vue'
import useBroadcastTabChannel from '@/composables/use-broadcast-tab-message'

const defaultState = () => {
  return {
    fetching: {
      protocols: null,
      prescribableProtocols: null,
    },
    saving: {
      protocolPrescription: false,
    },
    canUseProtocolPrescription: true,
    prescribedProtocol: null,
    pendingProtocol: null,
    recommendationEnabled: null,
    pendingActionsByUUID: {},
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    canUseProtocolPrescription: (state) => state.canUseProtocolPrescription,
    isFetching: (state) => (context) => state.fetching[context] || state.fetching[context] === null,
    isSaving: (state) => (context) => state.saving[context],
    treatmentPathEnabled: (state) => !!state.prescribedProtocol && !state.saving.protocolPrescription,
    recommendationEnabled: (state) => state.recommendationEnabled,
    protocolPrescriptionExpired: (state, getters) => getters.daysLeftInPrescribedProtocol === null || getters.daysLeftInPrescribedProtocol <= 0,
    daysLeftInPrescribedProtocol: (state) => {
      if (!state.prescribedProtocol || !state.prescribedProtocol.expires_at) {
        return null
      }

      return Vue.$date(state.prescribedProtocol.expires_at).diff(Vue.$date(), 'day')
    },
    getPrescribedProtocol: (state) => state.prescribedProtocol,
    getPendingProtocol: (state) => state.pendingProtocol,
    hasPendingAction: (state) => (uuid) => !!state.pendingActionsByUUID[uuid],
  },
  mutations: {
    setProtocolPrescriptionHasDisabled(state) {
      state.canUseProtocolPrescription = false
    },
    setRecommendationEnabledStatus(state, status) {
      state.recommendationEnabled = status
    },
    setAsFetching(state, payload) {
      Object.keys(payload).forEach((prop) => {
        state.fetching[prop] = payload[prop]
      })
    },
    setAsSaving(state, payload) {
      Object.keys(payload).forEach((prop) => {
        state.saving[prop] = payload[prop]
      })
    },
    setPrescribedProtocol(state, protocol) {
      state.prescribedProtocol = protocol
    },
    setPendingProtocol(state, protocol) {
      state.pendingProtocol = protocol
    },
    resetProtocolPrescription(state) {
      const { prescribedProtocol: defaultPrescribedProtocolState } = defaultState()

      Vue.set(state, 'prescribedProtocol', defaultPrescribedProtocolState)
    },
    setActionAsPending(state, { uuid, status = true }) {
      if (status) {
        Vue.set(state.pendingActionsByUUID, uuid, true)

        return
      }
      Vue.delete(state.pendingActionsByUUID, uuid)
    },
  },
  actions: {
    async fetchPrescribableProtocols({ commit, dispatch }, { patientId, kitName }) {
      commit('setAsFetching', { prescribableProtocols: true })
      try {
        const prescribableTherapies = await dispatch('patient/prescription/prescribableTherapiesForCurrentPatientConditionOnActiveInstitution',
          patientId, { root: true },
        )
        const prescribableTherapiesIds = prescribableTherapies.map((t) => t.id)

        return await dispatch('prescription/protocols/fetchProtocolsByTherapiesIds',
          { therapyIds: prescribableTherapiesIds.null, kitName, patientId },
          { root: true })
      } catch (e) {
        console.error('Error getting patient protocols', e)

        return null
      } finally {
        commit('setAsFetching', { prescribableProtocols: false })
      }

    },
    async prescribeProtocol({ rootGetters, commit }, { protocolId, phaseId, patientUuid, origin }) {
      const { postMessage } = useBroadcastTabChannel()

      commit('setAsSaving', { protocolPrescription: true })
      // Getting patient data
      const currentPatient = rootGetters['patient/getMemberBasics']
      const patientUuidToUse = patientUuid || currentPatient.uuid
      const body = {
        patient_uuid: patientUuid || currentPatient.uuid,
        protocol_id: protocolId,
        phase_id: phaseId,
        origin,
      }

      try {
        const { data } = await Vue.$http('prescription/protocols/prescribeProtocol', null, { body })

        commit('setPrescribedProtocol', data.prescribed)
        commit('setPendingProtocol', data.pending)

        postMessage('prescription:save', patientUuidToUse)

        return Promise.resolve(data)
      } catch (error) {
        console.error(`Error prescribing treatment path for patient with uuid ${patientUuidToUse}`, error)

        return Promise.reject(error)
      } finally {
        commit('setAsSaving', { protocolPrescription: false })
      }
    },
    async fetchPrescribedAndPendingProtocols({ rootGetters, commit }) {
      commit('setAsFetching', { protocols: true })
      // Getting patient data
      const currentPatient = rootGetters['patient/getMemberBasics']
      const { uuid } = currentPatient

      try {
        const { data } = await Vue.$http('prescription/protocols/getPrescribedProtocolPath', uuid)
        const prescribedProtocol = data?.prescription.length ? data.prescription[0] : null

        commit('setPrescribedProtocol', prescribedProtocol)
        commit('setPendingProtocol', data.pending_prescription)
        commit('setRecommendationEnabledStatus', prescribedProtocol?.recommendation_enabled)

        return Promise.resolve(data)
      } catch (error) {
        console.error(`Error prescribing treatment path for patient with uuid ${uuid}`, error)

        return Promise.reject(error)
      } finally {
        commit('setAsFetching', { protocols: false })
      }
    },
    async extendProtocolPhase({ rootGetters, commit, getters }, { protocolUuid, patientUuid, origin, taskinhaId }) {
      // Getting patient data
      const protocolUuidToUse = protocolUuid || getters.getPrescribedProtocol.uuid
      const currentPatient = rootGetters['patient/getMemberBasics']
      const patientUuidToUse = patientUuid || currentPatient.uuid

      try {
        commit('setActionAsPending', { uuid: protocolUuidToUse }, { root: true })
        const { data } = await Vue.$http('prescription/protocols/extendProtocolPhase', protocolUuidToUse,
          { body: { patient_uuid: patientUuidToUse, origin, taskinha_id: taskinhaId } },
        )

        commit('setPrescribedProtocol', data)

        return Promise.resolve(data)
      } catch (error) {
        console.error(`Error extending treatment path for patient with uuid ${patientUuidToUse}`, error)

        return Promise.reject(error)
      } finally {
        commit('setActionAsPending', { uuid: protocolUuidToUse, status: false }, { root: true })
      }
    },
    async progressProtocolPhaseWithChanges({ rootGetters, getters, commit }, { protocolUuid, patientUuid, origin, taskinhaId }) {
      // Getting patient data
      const protocolUuidToUse = protocolUuid || getters.getPrescribedProtocol.uuid
      const currentPatient = rootGetters['patient/getMemberBasics']
      const patientUuidToUse = patientUuid || currentPatient.uuid

      try {
        commit('setActionAsPending', { uuid: protocolUuidToUse }, { root: true })
        const { data } = await Vue.$http('prescription/protocols/progressProtocolPhaseWithChanges', protocolUuidToUse, {
          body: {
            patient_uuid:
            patientUuidToUse,
            taskinha_id: taskinhaId,
            origin,
          },
        })

        commit('resetProtocolPrescription')

        return Promise.resolve(data)
      } catch (error) {
        console.error(`Error progressing protocol phase with changes for protocol with uuid ${protocolUuidToUse}`, error)

        return Promise.reject(error)
      } finally {
        commit('setActionAsPending', { uuid: protocolUuidToUse, status: false }, { root: true })
      }
    },
    async progressProtocolPhaseImmediately({ rootGetters, getters, commit }, { protocolUuid, patientUuid, origin, taskinhaId }) {
      // Getting patient data
      const protocolUuidToUse = protocolUuid || getters.getPrescribedProtocol.uuid
      const currentPatient = rootGetters['patient/getMemberBasics']
      const patientUuidToUse = patientUuid || currentPatient.uuid

      try {
        commit('setActionAsPending', { uuid: protocolUuidToUse }, { root: true })
        const { data } = await Vue.$http('prescription/protocols/progressProtocolPhaseImmediately', protocolUuidToUse, {
          body: {
            patient_uuid:
            patientUuidToUse,
            taskinha_id: taskinhaId,
            origin,
          },
        })

        commit('resetProtocolPrescription')

        return Promise.resolve(data)
      } catch (error) {
        console.error(`Error progressing protocol phase immediately for protocol with uuid ${protocolUuidToUse}`, error)

        return Promise.reject(error)
      } finally {
        commit('setActionAsPending', { uuid: protocolUuidToUse, status: false }, { root: true })
      }
    },
    async disableTreatmentPath({ rootGetters, commit, getters }, { newStatus, origin }) {
      // Getting patient data
      const { uuid: protocolUuid } = getters.getPrescribedProtocol
      const currentPatient = rootGetters['patient/getMemberBasics']
      const { uuid } = currentPatient
      // TODO NEED IMPLEMENTATION
      const justification = 'JUSTIFICATION_PLACEHOLDER'

      try {
        commit('setActionAsPending', { uuid: protocolUuid }, { root: true })
        await Vue.$http('prescription/protocols/disableTreatmentPath', protocolUuid, { body: { patient_uuid: uuid, justification, origin } })
        commit('resetProtocolPrescription')
        if (!newStatus) {
          commit('setRecommendationEnabledStatus', false)
        }

        return Promise.resolve()
      } catch (error) {
        console.error(`Error changing treatment path status for patient uuid: ${uuid} | new status: ${newStatus}`, error)

        return Promise.reject(error)
      } finally {
        commit('setActionAsPending', { uuid: protocolUuid, status: false }, { root: true })
      }
    },
    async toggleRecommendationEnabled({ getters, commit, rootGetters }, { newStatus, origin }) {
      // Getting patient data
      const { uuid: protocolUuid } = getters.getPrescribedProtocol
      const currentPatient = rootGetters['patient/getMemberBasics']
      const { uuid } = currentPatient

      try {
        commit('setRecommendationEnabledStatus', newStatus, origin)
        await Vue.$http('prescription/protocols/updateRecommendationEnabled', protocolUuid, {
          body: {
            patient_uuid: uuid,
            recommendation_enabled: newStatus,
            origin,
          },
        })

        return Promise.resolve()
      } catch (error) {
        commit('setRecommendationEnabledStatus', !newStatus)
        console.error(`Error toggling recommendation enable: ${uuid} | new status: ${newStatus}`, error)

        return Promise.reject(error)
      }
    },
  },

}
