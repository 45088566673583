import { isNumber } from '@/utils/filters'

type UnitSystem = {
  isImperial: boolean
  kilogramsToCorrectSystem: (value: number) => number | null
  metersToCorrectSystem: (value: number) => number | null
}

function heightToCorrectSystem(height: number, unitSystem: UnitSystem) {
  if (!height && height !== 0 && height !== 0.0) {
    return ''
  }

  if (!isNumber(height)) {
    return height
  }
  const convertedHeight = Number(unitSystem.metersToCorrectSystem(height))

  if (!convertedHeight && convertedHeight !== 0) {
    return null
  }

  return unitSystem.isImperial ? `${Math.round(convertedHeight)}` : `${convertedHeight.toFixed(2)}`
}

function weightToCorrectSystem(weight: number, unitSystem: UnitSystem) {
  if (!weight && weight !== 0 && weight !== 0.0) {
    return ''
  }

  if (!isNumber(weight)) {
    return weight
  }

  const convertedWeight = unitSystem.kilogramsToCorrectSystem(weight)

  if (!convertedWeight && convertedWeight !== 0) {
    return null
  }

  return `${Math.round(convertedWeight)}`
}

/**
 * Filter to convert a given value into unitSystems default value,
 * i.e., convert kg into pounds if unitSystem's system is 'imperial'
 *
 * @param {Number} value
 * @param {UnitSystem} unitSystem
 * @param {String} unitType
 */
export function convertUnitToCorrectSystem(value: number, unitSystem: UnitSystem, unitType: string) {
  switch (unitType) {
    case 'height':
      return heightToCorrectSystem(value, unitSystem)
    case 'weight':
      return weightToCorrectSystem(value, unitSystem)
    default:
      return value
  }
}

export default convertUnitToCorrectSystem
