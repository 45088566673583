import Vue from 'vue'

const defaultRecommendationsState = () => ({
  newRecommendationsNumber: null,
  isLockingRecommendations: false,
  selectedRecommendationUuid: null,
})

export default {
  namespaced: true,
  state: defaultRecommendationsState(),
  getters: {
    newRecommendationsNumber: (state) => state.newRecommendationsNumber,
    isLockingRecommendations: (state) => state.isLockingRecommendations,
    selectedRecommendationUuid: (state) => state.selectedRecommendationUuid,
  },
  mutations: {
    setNewRecommendationsNumber: (state, newRecommendationsNumber) => {
      state.newRecommendationsNumber = newRecommendationsNumber
    },
    setIsLockingRecommendations: (state, isLockingRecommendations) => {
      state.isLockingRecommendations = isLockingRecommendations
    },
    setSelectedRecommendationUuid: (state, selectedRecommendationUuid) => {
      state.selectedRecommendationUuid = selectedRecommendationUuid
    },
    resetSelectedRecommendationUuid: (state) => {
      state.selectedRecommendationUuid = defaultRecommendationsState().selectedRecommendationUuid
    },
    resetRecommendations: (state) => {
      Object.assign(state, defaultRecommendationsState())
    },
  },
  actions: {
    fetchRecommendationsCount: async ({ commit }, status) => {
      try {
        const { data } = await Vue.$http('recommendations/fetchRecommendationsCount', { status: status.join(',') })

        commit('setNewRecommendationsNumber', data.new)
      } catch (e) {
        console.error('[recommendations] Error fetching recommendations count', e)
        throw new Error(e)
      }
    },
  },
}
