type Exercise = {
  reps_ok: number
  reps_prescribed: number
  movementsExecuted: number
  correctedMovements: number
  movementsPrescribed: number
  prescribedMovements: number
  trainingTime: number
  trainingtime: number
}

/**
 * Returns exercise state string
 * @name Exercise state str
 * @returns {string} Exercise state string
 */

export const exerciseState = (exercise: Exercise): string => {
  // Maintaining retro compatibility. Add here older prop values
  const repetitions = {
    ok:
      exercise.reps_ok ||
      exercise.movementsExecuted ||
      exercise.correctedMovements,
    prescribed:
      exercise.reps_prescribed ||
      exercise.movementsPrescribed ||
      exercise.prescribedMovements,
  }

  const trainingTime = exercise.trainingTime || exercise.trainingtime || 0

  if (!repetitions.ok && !trainingTime) {
    return 'undone'
  }

  return repetitions.ok === repetitions.prescribed ? 'complete' : 'incomplete'
}

export default exerciseState
