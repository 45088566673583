import dayjs from 'dayjs'
import 'dayjs/locale/pt'

import pluginUtc from 'dayjs/plugin/utc'
import pluginTimezone from 'dayjs/plugin/timezone'
import pluginLocalizedFormat from 'dayjs/plugin/localizedFormat'
import pluginIsBetween from 'dayjs/plugin/isBetween'
import pluginCustomParseFormat from 'dayjs/plugin/customParseFormat'
import pluginRelativeTime from 'dayjs/plugin/relativeTime'
import pluginWeekOfYear from 'dayjs/plugin/weekOfYear'
import pluginDuration from 'dayjs/plugin/duration'

import i18n from '@/scripts/app-configs/i18n-config'
import { preferredLanguage } from '@/scripts/app-configs/language/languages-configs'

dayjs.locale(preferredLanguage)

export default {
  install(Vue) {

    dayjs.extend(pluginUtc)
    dayjs.extend(pluginTimezone)
    dayjs.extend(pluginLocalizedFormat)
    dayjs.extend(pluginIsBetween)
    dayjs.extend(pluginCustomParseFormat)
    dayjs.extend(pluginRelativeTime)
    dayjs.extend(pluginWeekOfYear)
    dayjs.extend(pluginDuration)

    Vue.prototype.$date = dayjs
    Vue.$date = dayjs

    Vue.prototype.$date.prototype.pretty = function makeDatePrettier(d) {
      const date = dayjs(d)
      const diff = this.diff(date, 'day')

      if (!diff) {
        return this.format('h mm A')
      }
      if (diff === 1) {
        return `Yesterday, ${this.format('h mm A')}`
      }

      return this.format('MMMM DD, YYYY h  mm A')
    }
    Vue.prototype.$date.prototype.isSameDay = function isSameD(d) {
      const date = dayjs(d)

      return this.isSame(date, 'day')
    }

    Vue.prototype.$date.prototype.divide = function divideDate() {
      return {
        day: this.date(),
        month: this.month(),
        year: this.year(),
        month_name: this.format('MMMM'),
      }
    }

    Vue.prototype.$date.prototype.shToday = function checkIfIsToday(d) {
      const date = dayjs(d)

      return dayjs().isSame(date, 'day')
    }

    Vue.prototype.$date.prototype.shYesterday = function shYesterday(d) {
      const date = dayjs(d)

      return dayjs().subtract(1, 'day').isSame(date, 'day')
    }

    Vue.prototype.$date.prototype.shDayDiff = function shDayDiff(d = new Date) {
      const date = dayjs(d).set('hour', 0).set('minute', 0).set('second', 0)
      const dateOrigin = dayjs(this.$d).set('hour', 0).set('minute', 0).set('second', 0)

      return dateOrigin.diff(date, 'day')
    }

    Vue.prototype.$date.prototype.laterThan = function shMoreFuture(d) {
      const timestampToTest = Math.round(this.$d.getTime() / 1000)
      const timestampRef = Math.round(dayjs(d).$d.getTime() / 1000)

      return timestampToTest > timestampRef
    }

    Vue.prototype.$date.prototype.dateDetails = function generateDateDetails(format) {
      const date = dayjs(new Date(this * 1000))
      const dateMonth = date.get('month') + 1
      const dateYear = date.get('year')
      const dateTimestamp = date.unix()
      const dateUtcTimestamp = dateTimestamp + new Date(this).getTimezoneOffset() * 60
      const dateUtcDate = dayjs(new Date(dateUtcTimestamp * 1000))

      return {
        isToday: date.shToday(date),
        isYesterday: date.shYesterday(date),
        day: date.get('date'),
        month: dateMonth,
        year: dateYear,
        daysInMonth: new Date(dateYear, dateMonth, 0).getDate(),
        hour: date.get('hour'),
        minutes: date.get('minute'),
        seconds: date.get('second'),
        dateFormatted: date.format(format),
        timestamp: dateTimestamp,
        timezone: (new Date(this).getTimezoneOffset() * -1) / 60,
        utcTimestamp: dateUtcTimestamp,
        utcDate: dateUtcDate,
        utcFormatted: dateUtcDate.format(format),
      }
    }

    Vue.prototype.$date.prototype.defaultDiff = function defDiff(d, ignoreTime = false) {
      let date = dayjs(d)
      let refDate = this

      if (ignoreTime) {
        refDate = refDate.startOf('day')
        date = date.startOf('day')
      }

      const diff = refDate.diff(date, 'day')

      switch (diff) {
        case 0: {
          return i18n.t('LATEST_ACTIVITY.TODAY')
        }
        case 1: {
          return i18n.t('LATEST_ACTIVITY.YESTERDAY')
        }
        default: {
          return `${diff} ${i18n.t('COMMON.DAYS_AGO')}`
        }
      }
    }

    Vue.prototype.$date.prototype.verboseDate = function formatVerbose(format) {
      const today = i18n.t('LATEST_ACTIVITY.TODAY')
      const yesterday = i18n.t('LATEST_ACTIVITY.YESTERDAY')
      const pastDate = this.format(format)
      let verboseDate = pastDate

      if (this.shToday(this)) {
        verboseDate = today
      }

      if (this.shYesterday(this)) {
        verboseDate = yesterday
      }

      const hour = `${String(this.get('hour')).padStart(2, '0')}:${String(this.get('minute')).padStart(2, '0')}h`

      return `${verboseDate}, ${hour}`
    }
    Vue.prototype.$date.prototype.guessBrowserTimezone = function guessBrowserTimezone() {
      return dayjs.tz.guess()
    }
    Vue.prototype.$date.prototype.isDateBetweenRange = function isDateBetweenRange(date, rangeStartDate, rangeEndDate) {
      return dayjs(date).isBetween(dayjs(rangeStartDate), dayjs(rangeEndDate), null, '[]')
    }

    Vue.prototype.$date.prototype.formatDuration = function formatDuration(secondsRaw) {
      const duration = dayjs.duration(secondsRaw, 'seconds')
      const minutes = duration.minutes()
      const seconds = duration.seconds()

      if (seconds === 0) return minutes.toString()

      return `${minutes}:${seconds.toString().padStart(2, '0')}`
    }
  },
}
