<template>
  <div class="notes-overview">
    <notes-header
      :picture="picture"
      :gender="gender"
      :vip="vip"
      :high-risk="highRisk"
      :name="name"
      @close="shouldCloseWithoutSaving"
    />
    <div class="notes-overview__content">
      <edit-note-form
        v-if="currentNote"
        :title-value="currentNote.title"
        :body-value="currentNote.body"
        :last-edit="currentNote.last_edit"
        :note-type="currentNote.type"
        @title="(newTitle) => currentNote.title = newTitle"
        @body="(newBody) => currentNote.body = newBody"
        @cancel="closeOpenNote"
        @submit-form="saveNote"
      />
      <notes-list
        v-else
        :notes="notes"
        @open-note="openNote"
        @new-note="createNewNote"
        @delete-note="deleteNote"
      />
    </div>
  </div>
</template>

<script>
import NotesList from './NotesList.vue'
import NotesHeader from './NotesHeader.vue'
import EditNoteForm from './EditNoteForm.vue'

export default {
  name: 'NotesOverview',
  components: {
    NotesList,
    NotesHeader,
    EditNoteForm,
  },
  props: {
    picture: {
      type: String,
      default: '',
    },
    vip: {
      type: Boolean,
      default: false,
    },
    highRisk: {
      type: Boolean,
      default: false,
    },
    gender: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    notes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentNote: null,
    }
  },
  methods: {
    createNewNote() {
      this.currentNote = { title: '', body: '', type: 'free' }
    },
    closeOpenNote() {
      this.currentNote = null
    },
    openNote(note) {
      this.currentNote = { ...note, type: note.type.name }
    },
    saveNote() {
      this.$emit('save-note', this.currentNote)
      this.closeOpenNote()
    },
    deleteNote(note) {
      this.$emit('delete-note', note)
    },
    shouldCloseWithoutSaving() {
      this.$emit('should-close-without-saving')
    },
  },
}
</script>

<style lang="scss" scoped>
.notes-overview {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: initial;
  box-shadow: 0 25px 40px 0px rgba(27, 27, 27, 0.2);

  &__content {
    height: calc(100% - 4.5rem);
  }
}

</style>
