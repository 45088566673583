export default (exercise) => {
  return {
    id: exercise.id,
    code: exercise.code,
    session_exercise_id: exercise.session_exercise_id || null,
    repeats: exercise.repeats,
    resistance: exercise.resistance || false,
    conditions: exercise.conditions ? exercise.conditions : null,
    series: exercise.series,
    order: exercise.order,
    position: exercise.position || null,
  }
}
