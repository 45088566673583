import { render, staticRenderFns } from "./infinite-scroll.vue?vue&type=template&id=f2ac4cba&scoped=true"
import script from "./infinite-scroll.vue?vue&type=script&lang=js"
export * from "./infinite-scroll.vue?vue&type=script&lang=js"
import style0 from "./infinite-scroll.vue?vue&type=style&index=0&id=f2ac4cba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2ac4cba",
  null
  
)

export default component.exports