import Vue from 'vue'
import { DirectiveBinding } from 'vue/types/options'

/**
 * Custom directive to apply Shadow DOM.
 *
 * @param {HTMLElement} el - The DOM element to which the directive is applied.
 * @param {Object} binding - The binding object that contains information about the directive.
 * @param {Object} binding.value - The value passed to the directive.
 * @param {string} binding.value.styles - Styles to be applied in the Shadow DOM.
 * @param {string} binding.value.content - Additional content to be added to the Shadow DOM.
 */
const shadowDom = {
  inserted(el: HTMLElement, binding: DirectiveBinding) {
    const shadowRoot = el.attachShadow({ mode: 'open' })

    // Move all children of the element to the Shadow Root
    while (el.firstChild) {
      shadowRoot.appendChild(el.firstChild)
    }

    // Apply the styles passed in the binding (if any)
    if (binding.value && binding.value.styles) {
      const style = document.createElement('style')

      style.textContent = binding.value.styles
      shadowRoot.appendChild(style)
    }

    // Add additional content passed in the binding (if any)
    if (binding.value && binding.value.content) {
      const content = document.createElement('div')

      content.innerHTML = binding.value.content
      shadowRoot.appendChild(content)
    }

    // Move inherited styles from the main document to shadowRoot
    const inheritedStyles = Array.from(document.styleSheets).reduce((styles, sheet) => {
      try {
        return styles + Array.from(sheet.cssRules).reduce((innerStyles, rule) => innerStyles + rule.cssText, '')
      } catch (e) {
        return styles
      }
    }, '')

    if (inheritedStyles) {
      const style = document.createElement('style')

      style.textContent = inheritedStyles
      shadowRoot.appendChild(style)
    }
  },
}

Vue.directive('shadowDom', shadowDom)

export default shadowDom
