export default {
  members: {
    getHeaderData: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, memberId) {
        return `therapist/v${configs.api.version}/move/members/${memberId}/header`
      },
    },
  },
}
