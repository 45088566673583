import router from '@/router'
import { CONFIG, EXTERNAL_LINKS, FEATURE_TOGGLE } from '@/scripts/app-configs/constants'
import useBroadcastTabChannel from '@/composables/use-broadcast-tab-message'

// Utils
import { triggers } from '@/scripts/global-modals-commands'

let refWindow = null

export const openWebChat = ({ accountUuid, unit, channelId } = {}, options) => {
  const openChatOptions = {
    sameTab: false,
    openPopup: false,
    ...options,
  }

  if (FEATURE_TOGGLE.USE_UI_PORTAL_CHAT) {
    let chatRoutePayload = { name: 'chat' }

    const { postMessage, onMessage } = useBroadcastTabChannel()

    if (accountUuid && unit || channelId) {
      if (openChatOptions.openPopup) {
        triggers.toggleChatPopup({ accountUuid, unit, channelId })

        return
      }

      if (channelId) {
        chatRoutePayload = {
          path: `/chat/channel/${channelId}`,
        }
      } else {
        chatRoutePayload = {
          path: '/chat/discover',
          query: { accountUuid, unit },
        }
      }
    }

    const chatRouteData = router.resolve(chatRoutePayload)

    if (openChatOptions.sameTab) {
      router.push(chatRoutePayload)
    } else {
      if (refWindow && !refWindow.closed || router.currentRoute.name.match('chat')) {
        postMessage('chat:update-url', chatRouteData.href)

        refWindow?.focus()

        return
      }

      refWindow = window.open(chatRouteData.href, EXTERNAL_LINKS.uiPortal.chatTarget)

      onMessage('chat:closed', () => {
        refWindow = null
      })
    }

    return
  }
  // Old chat only!
  const channelPath = accountUuid ? `/channel/patient-${CONFIG.environment}-${accountUuid}` : ''

  window.open(
    `${EXTERNAL_LINKS.therapistChat.url}${channelPath}`,
    openChatOptions.sameTab ? '_self' : EXTERNAL_LINKS.therapistChat.target,
  )
}
