const deepCopyObj = (source) => JSON.parse(JSON.stringify(source))

function assignToStruct(struct, objData) {
  return Object.keys(struct).reduce((assignedStruct, structProp) => {
    assignedStruct[structProp] = objData[structProp] || struct[structProp]

    return assignedStruct
  }, {})
}

export { deepCopyObj, assignToStruct }
