<template>
  <form
    class="note-form-wrapper"
    name="noteEdition"
    @submit.prevent="submitForm()"
  >
    <section class="note-fields -pxy_0">
      <template>
        <base-input-wuk
          v-model="title"
          class="-pxy_2"
          name="noteTitle"
          :placeholder="$t('NOTES.TITLE')"
          :maxlength="NOTE_TITLE_MAX_LENGTH"
          :validation="$v.title"
        />
        <textarea
          v-model="body"
          class="note-body sword-base-input-wuk -pt_0 -px_2 -pb_1"
          name="noteBody"
          :placeholder="$t('copy_2829')"
          autocomplete="off"
          @focus="$v.body.$reset()"
          @blur="$v.body.$touch()"
        ></textarea>
        <input-error-wuk
          v-if="$v.body.$invalid"
          class="note-error-feedback -pxy_2"
          :validator="$v.body"
        />
      </template>
    </section>
    <!-- BOTTOM -->
    <section class="notes-footer">
      <sword-button-wuk
        text="COMMON.CANCEL"
        icon="close"
        side="left"
        class="cancel-button"
        @click.native="$emit('cancel')"
      />
      <sword-button-wuk
        text="copy_2684"
        icon="add"
        side="left"
        type="submit"
        @click.native="$emit('new-note')"
      />
    </section>
  </form>
</template>

<script>
import { ref, computed } from 'vue'

import { parseValidationsStr } from '@sword-health/input-validation'
import InputErrorWuk from '@ui-kit/components/inputs/footnotes/InputError.vue'
import BaseInputWuk from '@ui-kit/components/inputs/BaseInput.vue'
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue'

const NOTE_TITLE_MAX_LENGTH = 20

export default {
  name: 'EditNoteForm',
  components: {
    BaseInputWuk,
    InputErrorWuk,
    SwordButtonWuk,
  },
  props: {
    titleValue: {
      type: String,
      default: '',
    },
    bodyValue: {
      type: String,
      default: '',
    },
    lastEdit: {
      type: Object,
      default: null,
    },
    noteType: {
      type: String,
      default: null,
    },
  },
  emits: ['title', 'body', 'submit-form'],
  setup(props, { emit }) {
    const vSetup = ref({})

    const title = computed({
      get() {
        return props.titleValue
      },
      set(value) {
        emit('title', value)
      },
    })

    const body = computed({
      get() {
        const parser = new DOMParser()
        const dom = parser.parseFromString(props.bodyValue, 'text/html')

        return dom.body.textContent
      },
      set(value) {
        emit('body', value)
      },
    })

    const submitForm = () => {
      vSetup.value?.$touch()
      if (!vSetup.value?.$invalid) {
        emit('submit-form')
      }
    }

    return {
      NOTE_TITLE_MAX_LENGTH,
      title,
      body,
      submitForm,
      vSetup,
    }
  },
  mounted() {
    this.vSetup = this.$v
  },
  validations() {
    return {
      title: parseValidationsStr({
        maxL: NOTE_TITLE_MAX_LENGTH,
      }),
      body: parseValidationsStr({
        required: true,
        pack: 'freeField',
      }),
    }
  },
}
</script>

<style lang="scss" scoped>

.note-body {
  outline: none;
  resize: none;
  height: 100%;
  @include custom-scrollbar-wuk(getColor($blues-wuk, 5), 5px, getColor($blues-wuk, 2));
}

.note-form-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.note-fields {
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.note-error-feedback {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.notes-footer {
  display: grid;
  grid: min-content / 1fr 1fr;

  .sword-button-wuk {
    height: 4rem;
    font-size: 1.375rem;
    margin: 0;
    border-radius: 0;

    &.cancel-button {
      background: $context-color-7;
      color: $primary-color-dark;

      &:hover {
        background: $context-color-6;
      }
    }

    ::v-deep {
      .sword-icon.svg-icon {
        height: 1.25rem;
        width: 1.25rem;
      }

      span {
        font-weight: 500;
      }
    }
  }
}

</style>
