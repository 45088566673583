import { SUPPORTED_CHANGE_TYPES } from '@/scripts/configs/prescription/justifications-configs'

export default class ChangeTracker {
  constructor(exerciseId, referenceExercisesList, referenceExercise, currentlyTrackedChanges = {}, candidatePayload) {
    this.exerciseId = exerciseId
    this.referenceExercise = referenceExercise
    this.referenceExercisesList = referenceExercisesList
    this.currentlyTrackedChanges = currentlyTrackedChanges
    this.candidatePayload = candidatePayload
  }

  get trackedChanges() {
    const hasTrackedChanges = Object.keys(this.currentlyTrackedChanges).length

    return hasTrackedChanges ? this.currentlyTrackedChanges : null
  }

  run() {
    const beingRemoved = this.candidatePayload.series === 0

    // Drop all changes on exercise if it gets un-prescribed, again
    if (!this.referenceExercise && beingRemoved) {
      this.untrackChanges('all')

      return this.trackedChanges
    }

    // Run across payload candidate to see what should be tracked or not
    Object.entries(this.candidatePayload).forEach(([changedProp, newValue]) => {

      // Unsupported change type. Ignore
      if (!SUPPORTED_CHANGE_TYPES.includes(changedProp)) {
        return
      }

      // Exercise as no changes yet, so just add it
      if (!this.currentlyTrackedChanges) {
        this.trackChange(this.candidatePayload)

        return
      }

      // Get the previous value of the changed prop
      const orgValue = this.oldValueOfPropInExercise(changedProp)

      // If exercise change returned to original value, remove this as a change
      if (newValue === orgValue) {
        this.untrackChanges(changedProp)

        return
      }

      // It is actually a new value for the prop, so update tracking object
      this.trackChange(this.candidatePayload)
    })

    // For previous prescribed being removed and unprescribed that where added, only consider removal add/remove actions
    if (beingRemoved || !this.referenceExercise) {
      this.untrackChanges('allButSeries')
    }

    return this.trackedChanges
  }

  oldValueOfPropInExercise(propName) {
    let orgValue

    if (propName === 'series') {
      orgValue = this.referenceExercisesList.filter((ex) => ex.id === Number(this.exerciseId)).length
    } else {
      orgValue = this.referenceExercise ? this.referenceExercise[propName] : null
    }

    return orgValue
  }

  trackChange(changePayload) {
    Object.assign(this.currentlyTrackedChanges, changePayload)

    return this.currentlyTrackedChanges
  }

  untrackChanges(deleteScope) {
    switch (deleteScope) {
      case 'all':
        this.currentlyTrackedChanges = {}
        break
      case 'allButSeries':
        Object.keys(this.currentlyTrackedChanges).forEach((trackedProp) => {
          if (trackedProp !== 'series') {
            delete this.currentlyTrackedChanges[trackedProp]
          }
        })
        break
      default:
        if (deleteScope in this.currentlyTrackedChanges) {
          delete this.currentlyTrackedChanges[deleteScope]
        }
        break
    }

    return this.currentlyTrackedChanges
  }
}
