<template>
  <sword-modal v-if="!!modalContext" :header="metaData.headerStr" title="header" @close="closeModal">
    <template #body>
      <div class="body-content">
        <p class="t1 -mb_0">
          {{ $t(metaData.bodyStr) }}
        </p>
        <sword-checkbox-wuk v-model="stopShowing" class="-mt_4" :opt-value="true">
          <p class="t3 -mb_0">
            {{ $t('copy_2475') }}
          </p>
        </sword-checkbox-wuk>
      </div>
    </template>
    <template #footer>
      <div class="navigation">
        <div class="buttons-group">
          <sword-button-wuk theme="secondary" :text="metaData.dismissButtonText" @click.native="closeModal" />
          <sword-button-wuk :text="metaData.confirmButtonText" @click.native="next" />
        </div>
      </div>
    </template>
  </sword-modal>
</template>

<script>
// Components
import SwordModal from '@/components/modals/SwordModal.vue'
import SwordCheckboxWuk from '@ui-kit/components/inputs/checkboxes/SwordCheckbox.vue'
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue'

// Utils
import { triggers } from '@/scripts/global-modals-commands'
import { computed } from 'vue'

const INCOMPLETE_PROFILE_MODAL_DATA = {
  headerStr: 'copy_4438',
  bodyStr: 'copy_4439',
  dismissButtonText: 'copy_4409',
  confirmButtonText: 'copy_4410',
}

const MISSING_PROFILE_MODAL_DATA = {
  headerStr: 'copy_3492',
  bodyStr: 'copy_3493',
  dismissButtonText: 'copy_3736',
  confirmButtonText: 'copy_3735',
}

export default {
  name: 'OnboardingProfileMissingModal',
  components: {
    SwordModal,
    SwordButtonWuk,
    SwordCheckboxWuk,
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const modalContext = computed(() => props.payload.modalContext)
    const isModalOpen = computed(() => props.modalOpen && modalContext.value)

    return { isModalOpen, modalContext }
  },
  data() {
    return {
      stopShowing: false,
    }
  },
  computed: {
    metaData() {
      return !this.modalContext || !this.isIncompleteProfileModal ? MISSING_PROFILE_MODAL_DATA : INCOMPLETE_PROFILE_MODAL_DATA
    },
    isIncompleteProfileModal() {
      return this.modalContext === 'incompleteProfile'
    },
  },
  methods: {
    dismissIncompleteOnboardingModal() {
      this.$store.dispatch('user/preferences/setPreference', {
        key: 'dismissOnboardingProfileWarning',
        value: true,
        remote: true,
      })
    },
    closeModal() {
      if (this.stopShowing) {
        this.dismissIncompleteOnboardingModal()
      }
      triggers.closeOnboardingProfileMissing()
    },
    next() {
      if (this.stopShowing) {
        this.dismissIncompleteOnboardingModal()
      }
      this.$router.push({ name: 'remote-config' })
      this.closeModal()
    },
  },
}
</script>
<style lang="scss" scoped>
.body-content::v-deep {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  .t1 {
    line-height: 1.39;
  }
}
</style>
