import { SYSTEM_STORAGE } from '@/scripts/app-configs/constants'
import * as config from '@/scripts/app-configs/config'

export default () => {
  const forceLanguage = config.get('FORCE_LANGUAGE') === true

  if (forceLanguage) {
    return forceLanguage
  }

  // If not forcing, consider local storage
  try {
    const { system } = JSON.parse(localStorage.getItem(SYSTEM_STORAGE.key))

    if (system.language) {
      return system.language
    }

  } catch (e) {
    if (!(e instanceof TypeError)) {
      throw e
    }
  }

  // If there's no language in storage, go to browser language
  // Check if client language available
  // If so, use it set language cookie to that one
  const browserLanguage = (navigator.language || navigator.userLanguage).split('-')[0]
  const availableLanguages = config.get('AVAILABLE_LANGUAGES').split(',')

  if (availableLanguages.includes(browserLanguage)) {
    return browserLanguage
  }

  // if not fitting any available language, fallback to default
  return config.get('LANGUAGE_FALLBACK')
}
