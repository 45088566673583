import Vue from 'vue'
import { deepCopyObj } from '@/scripts/tools/helper-tools'

/**
 * Stores previous prescription while editing a session
 * New prescription of this session will be manipulated as new session on add session module
 */

const defaultExistingSessionBasics = {
  id: null,
  name: null,
  custom_name: null,
  side: null,
  setup: null,
  is_active: null,
  therapy_id: null,
  total_executed: null,
  daily_frequency: 1,
  exercises_prescribed: null,
  estimated_time: null,
  image: '',
  therapy_name: null,
  valid: null,
}

const defaultPrescribedSession = () => {
  return Object.assign({}, defaultExistingSessionBasics, { exercises: [] })
}

export default {
  namespaced: true,
  state: {
    previousPrescription: defaultPrescribedSession(),
    exercises: [],
  },
  getters: {
    getBackupPrescriptionExercises: (state) => state.exercises,
    getBackupReferenceExerciseById: (state) => (id) => {
      return state.exercises.find((ex) => ex.id === Number(id))
    },
  },
  mutations: {
    setBackupExercises(state, exercises) {
      const exercisesCopy = deepCopyObj(exercises)

      Vue.set(state, 'exercises', exercisesCopy)
    },
    resetBackupPrescription(state) {
      Vue.set(state, 'exercises', [])
    },
    setBackupPrescriptionBasics(state, payload) {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key]
      })
    },
  },
  actions: {
    discardBackupPrescription({ commit }) {
      // Discard backup prescription data
      commit('resetBackupPrescription')
      // Discard exercises changes tracked
      commit('prescription/justification/resetTrackedChanges', null, { root: true })
    },
  },
}
