<template>
  <div class="sh-input-error-message fc-red t4">
    <span v-if="manualError">
      {{ manualError }}
    </span>
    <span v-else-if="firstErrorMessage">
      {{ $t(firstErrorMessage, firstError.params) }}
    </span>
  </div>
</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'

export default {
  name: 'InputErrorMessage',
  mixins: [singleErrorExtractorMixin],
  props: {
    manualError: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.sh-input-error-message {
  position: absolute;
  bottom: -5px;
  right: 2px;
}
</style>
