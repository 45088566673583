export default {
  namespaced: true,
  state: {
    dptActivityUuid: undefined,
  },
  getters: {
    getDptActivityUuid: (state) => state.dptActivityUuid,
  },
  mutations: {
    setDptActivityUuid(state, newDptActivityUuid) {
      state.dptActivityUuid = newDptActivityUuid
    },
  },
}
