// TODO Patologies list, its request and store will get duplicated soon. Remove ASAP.  (dif names, no issue now)
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    memoizedPathologyList: null,
  },
  getters: {
    getMemoizedPathologyList: (state) => {
      return state.memoizedPathologyList
    },
  },
  mutations: {
    setMemoizedPathologyList(state, pathologyRequest) {
      state.memoizedPathologyList = pathologyRequest
    },
  },
  actions: {
    getPathologyList({ commit, getters }) {
      if (!getters.getMemoizedPathologyList) {
        commit('setMemoizedPathologyList', Vue.$http('institutions/clinical/fetchPathologyList'))
      }

      return getters.getMemoizedPathologyList
    },
    async fetchPathologyList({ dispatch, commit }) {
      try {
        const { data } = await dispatch('getPathologyList')

        return data.pathologies
      } catch (error) {
        commit('setMemoizedPathologyList', null)
        throw error
      }
    },
  },
}
