import Vue from 'vue'

const PROP_NAMES_PROTOCOL = {
  sessionName: 'session_1_name',
  sessionId: 'session_1_id',
}

/**
 *  Managing institution therapies
 */

export default {
  namespaced: true,
  state: {},
  actions: {
    async fetchProtocolsByTherapiesIds(_, actionPayload) {
      try {
        const { data: payload } = await Vue.$http('prescription/protocols/getProtocolsDefinitions', actionPayload)

        return payload.data
      } catch (error) {
        console.error(`Error fetching protocols of therapy IDs: ${actionPayload?.therapyIds}`, error)
      }

      return null
    },
    async fetchProtocolSessions(_, { protocolId, phase = null }) {
      try {
        const { data: payload } = await Vue.$http('prescription/protocols/getProtocolSessions', {
          protocolId,
          phase,
        })

        const { sessions } = payload.data

        if (sessions) {
          // filter repeated sessions when fetching for phases
          return sessions.reduce(
            (acc, session) =>
              acc.find(
                (uniqSession) => uniqSession[PROP_NAMES_PROTOCOL.sessionId] === session[PROP_NAMES_PROTOCOL.sessionId],
              )
                ? acc
                : [...acc, session],
            [],
          )
        }
      } catch (error) {
        console.error(`Error fetching sessions for protocol ID: ${protocolId}`, error)
      }

      return null
    },
    async fetchProtocolSessionDefinition(_, sessionId) {
      try {
        const { data: payload } = await Vue.$http('prescription/protocols/getProtocolSessionDefinition', sessionId)

        return payload.data
      } catch (error) {
        console.error(`Error fetching session for ID: ${sessionId}`, error)
      }

      return null
    },
  },
}
