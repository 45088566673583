/**
 * HTTP Error status codes mapping
 * @type {*}
 */
export const HTTP_ERRORS_CODES = {
  401: 'UNAUTHORIZED',
  403: 'FORBIDDEN',
}

/**
 * Auth Error codes mapping
 * @type {*}
 */
export const AUTH_ERROR_CODES = {
  10050: 'AUTH_REVOKED_TOKEN',
  10020: 'AUTH_INVALID_TOKEN',
  10030: 'AUTH_ACCESS_TOKEN_EXPIRED',
  10060: 'AUTH_ACCESS_TOKEN_NOT_FOUND',
  10410: 'AUTH_INVALID_TOKEN',
}

function isAuthErrorType(expEv, resp) {

  if (!resp.data) {
    return true
  }

  const eCode = resp.data.error_code

  function errorMatch() {
    if (expEv === '*') {
      // Any kind of auth error
      return !!AUTH_ERROR_CODES[eCode]
    }

    return (
      AUTH_ERROR_CODES[eCode] === expEv ||
      AUTH_ERROR_CODES[eCode] === `AUTH_${expEv}`
    )
  }

  return (
    resp.status &&
    resp.data.error_code &&
    Object.prototype.hasOwnProperty.call(HTTP_ERRORS_CODES, resp.status) &&
    errorMatch()
  )
}

export { isAuthErrorType }
