<template>
  <a class="widget-link -pxy_0">
    <div>
      <sword-icon-wuk :name="icon" />
    </div>
    <p class="t4 -t_medium -mt_1 -mb_0 mt5 compact-text">
      {{ $t(label) }}
    </p>
  </a>
</template>

<script>
import SwordIconWuk from '@ui-kit/components/icons/SwordIcon.vue'

export default {
  name: 'WidgetLink',
  components: { SwordIconWuk },
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-link {
  background: none;
  border: none;
  width: 5.5rem;
  color: inherit;
  text-decoration: none;

  div {
    height: 5.5rem;
    width: 100%;
    background-color: $background-color;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.75rem;
    transition: background-color 0.3s ease-in-out;
  }

  .sword-icon.svg-icon {
    width: auto;
    height: calc($button-round-icon-base-size-wuk * 2.5);
    transition: background-color 0.3s ease-in-out;
  }

  svg {
    fill: $context-color-1;
  }

  p {
    transition: background-color 0.3s ease-in-out;
    text-align: center;
    color: $context-color-1;
  }

  &:hover {
    div {
      background-color: $primary-color-pale;
    }

    svg {
      fill: $primary-color;
    }

    p {
      color: $primary-color;
    }
  }
}
</style>
