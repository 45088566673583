export default {
  fetchPatients: {
    api: {
      url: 'default',
      version: '2.1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => {
        const authorizationHeader = data.headers.authorization

        return Object.assign({}, authorizationHeader, { 'Content-Type': 'text/plain' })
      },
    },
    $$makeUrl(configs, {
      owned = false,
      institutionId = null,
      patientsState,
      include,
      offset = null,
      limit = null,
      search = null,
      searchType = null,
      sortBy = null,
      sortDir = null,
    }) {
      const url = `api/v${configs.api.version}/patients`
      const queryParams = new URLSearchParams('')

      queryParams.set('client', 'web')
      if (owned) {
        queryParams.set('owned', owned)
      }
      if (offset) {
        queryParams.set('offset', offset)
      }
      if (limit) {
        queryParams.set('limit', limit)
      }
      if (patientsState) {
        queryParams.set('current_state', patientsState)
      }
      if (include) {
        queryParams.append('include', include)
      }
      if (institutionId) {
        queryParams.set('institution_id', institutionId)
      }
      if (searchType && search) {
        queryParams.set('search', search)
        queryParams.set('search_type', searchType)
      }

      if (sortBy && sortDir) {
        queryParams.set('sort_by', sortBy)
        queryParams.set('sort_dir', sortDir)
      }

      return `${url}?${queryParams}`
    },
  },
  fetchAllPatients: {
    api: {
      url: 'default',
      version: '2.1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => {
        const authorizationHeader = data.headers.authorization

        return Object.assign({}, authorizationHeader, { 'Content-Type': 'text/plain' })
      },
    },
    $$makeUrl(configs, { search = null }) {
      const url = `api/v${configs.api.version}/patients/minimum-details`
      const queryParams = new URLSearchParams('')

      if (search) {
        queryParams.append('search', search)
      }

      return `${url}?${queryParams}`
    },
  },
}
