/**
 * transform kebab case strings to snake case
 * @name Kebab to Camel Case
 * @returns {string} Snake case string
 */

export const kebabToCamelCase = (str: string): string => {
  return str ? str.replace(/-./g, (x) => x[1].toUpperCase()) : ''
}

export default kebabToCamelCase
