export default {
  getConfigs: {
    api: {
      url: 'default',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      mock: (_, { context = 'user', userId, group }) => {
        if (context === 'user') {
          return `mocks/endpoints/config/user/user_${userId}_groups_${group}.json`
        }

        return 'mocks/endpoints/config/getSystemConfigs.json'
      },
    },
    $$makeUrl(configs, { context = 'user', userId, keys, group }) {
      if (context === 'user') {
        return `api/v1.1/user/${userId}/config?group=${group}&keys=${keys}`
      }

      return `api/v1.1/config/${keys}`
    },
  },
  getSystemConfigs: {
    api: {
      url: 'default',
    },
    $$method: 'get',
    $$meta: {
      mock: (_, { context = 'user' }) => {
        if (context === 'user') {
          return null // TODO: mock
        }

        return 'mocks/endpoints/config/getSystemConfigs.json'
      },
    },
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { context = 'user', userId, keys, group }) {
      if (context === 'user') {
        return `api/v1.1/user/${userId}/config?group=${group}&keys=${keys}`
      }

      return `api/v1.1/config/keys/${keys}`
    },
  },
  setConfig: {
    api: {
      url: 'default',
    },
    $$method: 'post',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { context = 'user', userId, key }) {
      if (context === 'user') {
        return `api/v1.1/user/${userId}/config`
      }

      return `api/v1.1/config/${key}`
    },
  },
  setLanguage: {
    api: {
      url: 'default',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(_, language) {
      return `lang/${language}`
    },
  },
}
