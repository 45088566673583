export default {
  fetchNotificationsStats: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs) {
      return `therapist/v${configs.api.version}/notifications/stats`
    },
  },
  fetchNotifications: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { offset, limit, newerThan }) {
      const url = `therapist/v${configs.api.version}/notifications`
      const queryParams = new URLSearchParams('')

      if (offset !== undefined) {
        queryParams.append('offset', offset)
      }

      if (limit) {
        queryParams.append('limit', limit)
      }

      if (newerThan) {
        queryParams.append('newer_than', newerThan)
      }

      return `${url}?${queryParams}`
    },
  },
  markAllAsRead: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'put',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs) {
      return `therapist/v${configs.api.version}/notifications/mark-all-as-read`
    },
  },
  toggleReadStatus: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'put',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs) {
      return `therapist/v${configs.api.version}/notifications/toggle-read-status`
    },
  },
}
