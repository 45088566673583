export default {
  getCurrentProgram: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { programUuid }) {
      return `therapist/v${configs.api.version}/bloom/members/${programUuid}/current-prescription`
    },
  },
  activateCurrentProgram: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'put',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { programUuid }) {
      return `therapist/v${configs.api.version}/bloom/members/${programUuid}/current-prescription/activate`
    },
  },
  getProgramsList: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { programUuid }) {
      return `therapist/v${configs.api.version}/bloom/programs?program_uuid=${programUuid}`
    },
  },
  updateCurrentProgram: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'post',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { programUuid }) {
      return `therapist/v${configs.api.version}/bloom/members/${programUuid}/prescriptions`
    },
  },
  getExcludedExercises: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { programUuid, offset, limit }) {
      return `therapist/v${configs.api.version}/bloom/members/${programUuid}/excluded-exercises?offset=${offset ?? 0}&limit=${limit ?? 20}`
    },
  },
  updateExcludedExercise: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'put',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { programUuid }) {
      return `therapist/v${configs.api.version}/bloom/members/${programUuid}/excluded-exercises`
    },
  },
}
