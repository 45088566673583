<template>
  <transition name="modal" appear>
    <div :class="['sword-modal-mask', {'center-text': centered}]" @click="$emit('close')">
      <div class="sword-modal-container" :class="typeClass" @click.stop>
        <div class="sword-modal-top-bar -px_2">
          <sword-icon name="dismiss" class="modal-top-bar-icon" @click.native="$emit('close')" />
        </div>

        <div class="-px_6">
          <slot name="header">
            <h5 class="-my_0 -t_semi-heavy">
              {{ $t(header) }}
            </h5>
          </slot>
        </div>

        <div class="sword-modal-body -my_4 -px_6">
          <slot name="body">
            <img v-if="imageData" class="sword-modal-body-image" :src="imageData.src" :alt="imageData.altText" />
            <p v-if="body" class="t2 -mb_0 -t_medium">
              {{ $t(body) }}
            </p>
            <p v-if="body2nd" class="t2 -mb_0 -t_medium">
              {{ $t(body2nd) }}
            </p>
            <p v-if="body3rd" class="t2 -mb_0 -t_medium">
              {{ $t(body3rd) }}
            </p>
          </slot>

          <slot name="body-bottom"></slot>
        </div>

        <div class="sword-modal-footer -mb_4 -px_2">
          <slot name="footer">
            <div class="buttons-group">
              <sword-button-wuk
                theme="secondary"
                side="left"
                :text="leftBtText"
                @click.native="$emit('cancel')"
              />

              <sword-button-wuk :text="rightBtText" @click.native="$emit('ok')" />
            </div>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue'

export default {
  name: 'SwordModal',
  components: {
    SwordButtonWuk,
  },
  props: {
    type: {
      type: String,
      default: 'info',
      validator(value) {
        return ['info', 'warning'].indexOf(value) !== -1
      },
    },
    header: {
      type: String,
    },
    body: {
      type: String,
      required: false,
    },
    body2nd: {
      type: String,
      required: false,
    },
    body3rd: {
      type: String,
      required: false,
    },
    imgPath: {
      type: String,
      required: false,
    },
    imgAltExt: {
      type: String,
      required: false,
    },
    leftBtText: {
      type: String,
      default: 'copy_12',
      required: false,
    },
    rightBtText: {
      type: String,
      default: 'copy_11',
      required: false,
    },
    centered: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    typeClass() {
      return `--${this.type}-modal`
    },
    imageData() {
      if (!this.imgPath) {
        return null
      }

      return {
        src: this.imgPath,
        altText: this.imgAltExt || '',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$modal-width: 90%;
$modal-radius: 1rem;
$modal-shadow: $default-shadow-wuk;
$modal-backdrop-color: rgba($context-color-1-rgb, 0.4);

// Desktop sizes
$modal-max-width-desktop: 90%;
$modal-min-width-desktop: 32.5rem;
// Mobile sizes
$modal-max-width-mobile: 90%;
$modal-min-width-mobile-phone: initial;

.sword-modal {
  &-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid: min-content/auto;
    align-content: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    background-color: $modal-backdrop-color;
  }

  &-top-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .modal-top-bar-icon {
      cursor: pointer;
      width: 1rem;
      height: 1rem;
      margin: 0.5rem 0;
    }
  }

  &-container {
    max-width: $modal-max-width-desktop;
    min-width: $modal-min-width-desktop;
    max-height: calc(100vh - 5rem);
    // padding: $modal-padding;
    border-radius: $modal-radius;
    box-shadow: $modal-shadow;
    margin: auto;
    background-color: white;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      background: $context-color-7;
      width: 0.3rem;
    }

    ::-webkit-scrollbar-thumb {
      background: $context-color-4;
      border-radius: 0.15rem;;
    }
  }

  &-body {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 1rem !important;
  }

  &-footer {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: break-wuk('medium', 'l')) {
  .sword-modal-container {
    max-width: $modal-max-width-mobile;
  }
}

@media screen and (max-width: break-wuk('small', 's')) {
  .sword-modal-container {
    min-width: $modal-min-width-mobile-phone;
    // padding: $modal-padding-mobile;
  }
}

/**
  Modal animation
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

// ### WIP FOR CONFIRMATION MODALS
// DESERVES MORE WORK (SIZING ETC)
.sword-modal-body-image {
  position: relative;
  width: 170px;
  height: auto;
  margin: 0 auto 25px;

  svg {
    width: inherit;
  }
}
</style>
