import Vue, { unref, ref, computed, watch } from 'vue'
import { EXTERNAL_LINKS } from '@/scripts/app-configs/constants'
import type { memberChatDetailsType, useChatIframeOptionsType, ChannelsUrlsInfoType, MemberChannelsReportType } from '@/views/chat/types'

import store from '@/store'

const defaultOpts: useChatIframeOptionsType = {
  enableIframeUrlUpdate: true,
}

export default function useChatIframe(
  memberChatDetails: memberChatDetailsType,
  iFrameEventsCallback?: CallableFunction,
  opts?: useChatIframeOptionsType,
) {

  const options: useChatIframeOptionsType = {
    ...defaultOpts,
    ...(opts || {}),
  }

  const preferredChannelUrl = ref<string>(`${EXTERNAL_LINKS.uiPortal.url}/app/chat?mode=widget`)

  const iframeRef = ref<HTMLElement | null>(null)
  const iframeIsReady = ref<boolean>(false)

  const currentUser = computed(() => {
    const { isIntakePt, isLeadPt } = store.getters['user/flags']

    return {
      id: store.getters['user/getUserId'],
      isIntakePt,
      isLeadPt,
      enabledCommunication: store.getters['user/enabledCommunication'],
    }
  })

  // Turning incoming member props reactive so we can watch them properly
  const accountUuid = computed(() => memberChatDetails.accountUuid?.value)
  const unit = computed(() => memberChatDetails.unit?.value)
  const chatChannelUrl = computed(() => memberChatDetails.chatChannelUrl?.value)
  const smsChannelUrl = computed(() => memberChatDetails.smsChannelUrl?.value)
  const userRelationshipInfo = computed(() => memberChatDetails.userRelationshipInfo?.value)

  const resetSelectedChatChannelUrl = () => {
    preferredChannelUrl.value = `${EXTERNAL_LINKS.uiPortal.url}/app/chat?mode=widget`
  }

  // Fallback utility to find member channels on legacy screens that do not have that info from existing endpoints
  const discoverChannel = async (memberAccountUuid: string, unit: string): Promise<ChannelsUrlsInfoType | null> => {
    try {
      const { data } = await Vue.$http('general/discoverChannels', { memberAccountUuid, unit })

      return data.comms_data
    } catch (error) {
      console.error(error, '[discover-chat-channels] Error fetching member channels urls', { memberAccountUuid, unit })

      return null
    }
  }

  /* Utility function to abstract member channels info origin.
   * Simply reports final channels info available
   * If not available for params tries to discover it using api
   */
  const getMemberChannelsReport = async (): Promise<MemberChannelsReportType> => {
    let discoverChannels

    // If there's no channel known (passed directly as param), try to discover them
    if (!chatChannelUrl.value && !smsChannelUrl.value && (accountUuid.value && unit.value)) {
      discoverChannels = await discoverChannel(accountUuid.value.toString(), unit.value.toString())
    }

    return {
      chat: discoverChannels ? discoverChannels.chat : chatChannelUrl.value || null,
      sms: discoverChannels ? discoverChannels.sms : smsChannelUrl.value || null,
      userRelationship: {
        isOwner: discoverChannels ? discoverChannels.owner_id === currentUser.value.id : userRelationshipInfo?.value?.isOwner || false,
        isIntake: currentUser.value.isIntakePt,
        isLead: currentUser.value.isLeadPt,
      },
    }
  }

  /* Generates the proper Url for the preferred member channel on the ui-portal (chat) app
   * From there, ui-portal deals with tabs (chat/sms), permissions and so on.
   * This is just to put the app in the right place to begin
   */
  const generateIframeMemberChatUrl = async (): Promise<string> => {

    let memberPreferredRouteUrl = '/app/chat'

    const {
      chat: chatChannel,
      sms: smsChannel,
      userRelationship: userAndMemberRelationship,
    } = await getMemberChannelsReport()

    /* Intake users give priority to SMS channels
     * In case of double configs (treatment flow & intake) sms tab will still be prioritised
     * Chat tab should still be enabled
     */
    const { isOwner, isIntake, isLead } = userAndMemberRelationship
    const preferSms = !isOwner && (isIntake || isLead) && currentUser.value.enabledCommunication.sms

    if (chatChannel || smsChannel) {
      const appChannelRoute = !preferSms ? `/channel/${chatChannel}` : `/sms/channel/${smsChannel}`

      memberPreferredRouteUrl += appChannelRoute
    }

    const queryParams = new URLSearchParams('')

    queryParams.set('mode', 'widget')

    return `${EXTERNAL_LINKS.uiPortal.url}${memberPreferredRouteUrl}?${queryParams}`
  }

  watch([accountUuid, unit, chatChannelUrl, smsChannelUrl], async () => {
    if (!unref(options.enableIframeUrlUpdate)) return
    preferredChannelUrl.value = await generateIframeMemberChatUrl()
  })

  generateIframeMemberChatUrl().then((channelUrl) => {
    preferredChannelUrl.value = channelUrl
  })

  return {
    initialIframeSrc: generateIframeMemberChatUrl(),
    preferredChannelUrl,
    // Refs
    iframeRef,
    iframeIsReady,
    // Methods
    resetSelectedChatChannelUrl,
  }
}
