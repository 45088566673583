/**
 * Safe check value for UI display
 * Undefined, null and empty values will instead return a placeholder
 * @name Safe Check Value
 * @returns {string, number} Input data to be checked
 */

const defaultOptions = {
  placeholder: '-',
  trimStrings: true,
}

export const safeCheckValue = (inputVal: string | number | boolean, customOptions = {}): string | number | boolean => {
  const options = Object.assign({}, defaultOptions, customOptions)

  if (typeof inputVal === 'undefined' || inputVal === null) return options.placeholder

  if (typeof inputVal === 'string' && options.trimStrings) {
    return inputVal.trim() || options.placeholder
  }

  return inputVal
}

export default safeCheckValue
