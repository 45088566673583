import Vue, { unref } from 'vue'
import i18n from '@/scripts/app-configs/i18n-config'

const defaultOpts = {
  useNotifications: true,
}

export default function useAppointmentLinks(memberUuidToUse) {

  const generateAppointmentLink = async (appointmentType, sendBySms = false, opts = defaultOpts) => {

    const options = {
      ...opts,
      ...defaultOpts,
    }

    if (!appointmentType) {
      console.warn('[appointment-link] Please provide an appointment type')

      return false
    }
    const memberUuid = unref(memberUuidToUse)

    try {
      const payload = {
        type: appointmentType,
        send_sms: sendBySms,
      }
      const { data } = await Vue.$http('patient/generateAppointmentLink', memberUuid, { body: payload })
      const { url: appointmentUrl, url_empty_reason: emptyUrlReason } = data

      if (!appointmentUrl && options.useNotifications) {
        const LINK_WARNINGS = {
          skipped_video_cal: i18n.t('APPOINTMENT_LINK_WARNING.SKIPPED_VIDEO_CALL'),
          video_call_form_submitted: i18n.t('APPOINTMENT_LINK_WARNING.VIDEO_CALL_FORM_SUBMITTED'),
        }

        Vue.$notify.warning(LINK_WARNINGS[emptyUrlReason] || i18n.t('APPOINTMENT_LINK_WARNING.GENERATE_PROBLEM'))
      }

      return Promise.resolve({ appointmentUrl, emptyUrlReason })
    } catch (e) {
      console.error(`It was not possible to generate a ${appointmentType} appointment link for member uuid ${memberUuid}.`, e)

      if (options.useNotifications) {
        Vue.$notify.error(this.$t('copy_89'))
      }

      return Promise.reject(e)
    }
  }

  return {
    generateAppointmentLink,
  }
}
