import * as config from './config'

const USER_ROLES = {
  all: '*',
  editor: 'editor',
  guest: 'guest',
}

const CONFIG = {
  baseUrl: config.get('BASE_URL'),
  broadcastChannelName: config.get('BROADCAST_CHANNEL_NAME') || 'clinical-portal-broadcast',
  onboardingApiUrl: config.get('ONBOARDING_API_URL'),
  cardsApiUrl: config.get('CARDS_API_URL'),
  assetsApiUrl: config.get('ASSETS_API_URL'),
  chatApiUrl: config.get('CHAT_API_URL'),
  stateMachineApiUrl: config.get('STATE_MACHINE_API_URL'),
  therapy: '',
  enableAnalytics: config.get('ENABLE_ANALYTICS'),
  analyticsKey: config.get('ANALYTICS_KEY'),
  enableDebug: config.get('ENABLE_DEBUG') === 'true',
  defaultPhonePrefix: config.get('PHONE_PREFIX') || '+1',
  useHttpMocks: (config.get('DEMO_MODE') === 'true') || false,
  environment: config.get('ENVIRONMENT'),
  HGMockCCDA: config.get('HG_MOCK_CCDA') === 'true' || false,
}

const SESSION_TIMEOUT_CONFIG = {
  autoLogoutTimeout: config.get('AUTO_LOGOUT_TIMEOUT') || 900, // Revert for config enabling.
  sessionTimerStartCookieName: 'session_timer_start',
}

const USER_STORAGE = {
  key: 'userSHCP',
}

const SYSTEM_STORAGE = {
  key: 'systemSHCP',
}

const AUTH_COOKIE = {
  token_expires: Number(config.get('TOKEN_COOKIE_LIFE_TIME') || 2),
  token_domain: config.get('TOKEN_COOKIE_DOMAIN'),
  token_name: 'atoken_sword',
  token_expires_date_name: 'atoken_expires_sword',
  token_validity_minutes: 15,
  refresh_token_name: 'rtoken_sword',
  base_url_expires: 10,
}

const EXTERNAL_LINKS = {
  uiPortal: {
    url: config.get('UI_PORTAL_URL'),
    target: `sword-health-ui-portal-window-${new Date().getTime()}`,
    chatTarget: `sword-health-ui-portal-chat-window-${new Date().getTime()}`,
  },
  therapistChat: {
    url: config.get('THERAPIST_CHAT_URL'),
    target: 'sword-health-therapist-chat-window',
  },
  supportAndHelp: {
    url: config.get('SUPPORT_FORM_URL'),
    target: 'sword-health-support-and-help-window',
  },
}

// eslint-disable-next-line
let VERSION_FILE = {};

try {
  // eslint-disable-next-line
  VERSION_FILE = require('@/version.json');
} catch (ex) {
  console.log('NO VERSION FILE AVAILABLE')
}

const FEATURE_TOGGLE = {
  uiPortalRedirect: false,
  USE_UI_PORTAL_CHAT: config.get('USE_UI_PORTAL_CHAT') === 'true',
  UML_ENABLED: config.get('UML_ENABLED') === 'true',
  ENABLE_AIFEED_NEWSFEED: config.get('ENABLE_AIFEED_NEWSFEED') === 'true',
}

export {
  USER_ROLES,
  USER_STORAGE,
  SYSTEM_STORAGE,
  CONFIG,
  SESSION_TIMEOUT_CONFIG,
  VERSION_FILE,
  AUTH_COOKIE,
  EXTERNAL_LINKS,
  FEATURE_TOGGLE,
}
