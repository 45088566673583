<template>
  <div class="pulse-loader-wrapper" data-title=".dot-pulse">
    <div class="dot --left"></div>
    <div class="dot --center"></div>
    <div class="dot --right"></div>
  </div>
</template>

<script>
export default {
  name: 'PulseLoader',
}
</script>

<style lang="scss" scoped>
$dots-size: 16px;
$dots-radius: calc($dots-size/2);
$dots-spacing: 4px;
$dots-grow-time: 1.8s;

.dot {
  display: inline-block;
  width: $dots-size;
  height: $dots-size;
  border-radius: $dots-radius;
  background-color: $sword;
  color: $sword;
  transform: scale(0);
  animation: pulse $dots-grow-time infinite linear;

  &.--left {
    margin-right: $dots-spacing;
    animation-delay: 0s;
  }

  &.--center {
    animation-delay: 0.25s;
  }

  &.--right {
    margin-left: $dots-spacing;
    animation-delay: 0.5s;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
  60%,
  100% {
    transform: scale(0);
  }
}
</style>
