<template>
  <v-dropdown class="patient-goals" :distance="10" placement="bottom-end">
    <sword-button-wuk
      theme="secondary"
      icon="target-arrow"
      size="small"
      side="left"
      :text="buttonLabel"
      :disabled="!items.length"
      class="-mt_1 -mx_0"
    />
    <template #popper>
      <div class="patient-goals-popover">
        <div v-for="(item, index) of orderedItems" :key="index" class="patient-goal">
          <div class="icon">
            <inline-svg :src="getIconPath(item.key)" />
          </div>
          <div class="info">
            <span class="patient-goal-title -t_medium -mb_1">{{ translateGoal(item) }}</span>
            <goal-status-pill :status="item.is_achieved ? 'achieved' : 'not_achieved'" />
          </div>
        </div>
        <div class="patient-goals-actions">
          <sword-button-wuk
            v-close-popper
            theme="secondary"
            icon="copy"
            size="small"
            side="left"
            :text="items.length > 1 ? $t('SIDEBAR.GOALS_COPY_ALL') : $t('SIDEBAR.GOALS_COPY_1')"
            @click.native="handleCopyGoals"
          />
        </div>
      </div>
    </template>
  </v-dropdown>
</template>

<script>
import { Dropdown as VDropdown, VClosePopper } from 'floating-vue'
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue'
import GoalStatusPill from '@ui-kit/components/micro/GoalStatusPill.vue'

const ICONS = {
  hobby: 'goal',
  play: 'tennis',
  work: 'article',
  other: 'target',
  no_pain: 'no-pain',
  sleep_quality: 'sleep',
  no_surgery: 'no-surgery',
  physical_fitness: 'strength',
  pain_prevention: 'shield-alt',
  no_medication: 'no-medication',
  mental_wellbeing: 'mental-health',
  exercise_routine: 'exercise-routine',
  better_bladder_control_with_movements: 'bladder_control_with_movements',
  reduce_frequent_urges_to_pee: 'urges_to_bathroom',
  empty_bowel: 'empty_bowel',
  reduce_straining_with_bowel_movements: 'straining_with_bowel_movements',
  reduce_leaking_stool: 'leaking_stool',
  reduce_pain_when_in_bathroom: 'pain_when_in_bathroom',
  reduce_pain_everyday_activities: 'pain_everyday_activities',
  reduce_vaginal_pain_or_sensitivity: 'vaginal_pain_or_sensitivity',
  reduce_hip_or_buttock_pain: 'hip_or_buttock_pain',
  hurry_to_the_bathroom: 'hurry_to_the_bathroom',
  reduce_pain_pelvic_cramping: 'vaginal_pain_or_sensitivity_tension',
  reduce_other_pelvic_symptoms: 'pelvic_health',
  reduce_pain_with_urination: 'pelvic_pain',
  reduce_constipation: 'urges_to_bathroom',
  reduce_pain_bowel_movements: 'urges_to_bathroom',
  reduce_diarrhea_lose_stool: 'fecal_leakage',
  reduce_abdominal_pain_bloating: 'vaginal_pain_or_sensitivity_tension',
  reduce_bowel_movement_regularity: 'empty_bowel_completely',
  improve_abdominal_disconfort: 'straining_with_bowel_movements',
  reduce_abdominal_groin_pain: 'pelvic_health',
}

export default {
  components: { VDropdown, SwordButtonWuk, GoalStatusPill },
  directives: {
    'close-popper': VClosePopper,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    orderedItems() {
      const items = this.items?.length ? [...this.items] : []

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-nested-ternary
      return items.sort((a, b) => ((a.is_achieved === b.is_achieved) ? 0 : a.is_achieved ? -1 : 1))
    },
    buttonLabel() {
      const total = this.items.length
      const achieved = this.items.filter((item) => item.is_achieved).length

      if (!total) return this.$t('SIDEBAR.GOALS_NONE')
      if (total === 1) return this.$t('SIDEBAR.GOALS_VIEW_SINGULAR', { achieved })

      return this.$t('SIDEBAR.GOALS_VIEW_PLURAL', { achieved, total })
    },
  },
  methods: {
    translateGoal({ key, value }) {
      return this.$t(`personal_goal_${key}`, { value })
    },
    handleCopyGoals() {
      const output = this.orderedItems.map((goal) => `• ${this.translateGoal(goal)}`).join('\n')

      this.$emit('copyToClipboard', output)
    },
    getIconName(key) {
      return ICONS[key] ?? 'goal'
    },
    getIconPath(key) {
      const icon = ICONS[key] ?? 'goal'

      return require(`@ui-kit/assets/images/goals/${icon}.svg`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~floating-vue/dist/style.css';

.patient-goals {
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;

  &::v-deep {
    .sword-icon.svg-icon {
      width: calc($button-round-icon-base-size-wuk * 1.3);
      height: calc($button-round-icon-base-size-wuk * 1.3);
    }
  }

  &-popover {
    padding: 0.5rem 0;
    width: 25rem;

    .patient-goal {
      display: flex;
      padding: 0.75rem 1rem;
      gap: 1rem;

      &-title {
        display: flex;
        font-size: $font-base-14;
        color: $context-color-1;
      }

      &:not(:nth-last-child(2)) {
        border-bottom: 1px solid $context-color-7;
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .icon {
        min-width: 3.5rem;
        height: 3.5rem;
        background-color: #f7f4f2;
        border-radius: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }

  &-actions {
    display: flex;
    justify-content: center;
    padding: 0.65rem;

    button {
      width: 100%;
    }
  }
}
</style>
