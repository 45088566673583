import Vue from 'vue'
import compatibilityTools from '@/scripts/tools/compatibility'

import { sortByDate } from '@/utils/filters'

const { getDefaultSessionName } = compatibilityTools

const generateDefaultState = () => ({
  isFetching: false,
  sessions: {
    active: null,
    list: [],
  },
})

export default {
  namespaced: true,
  state: generateDefaultState(),
  getters: {
    isFetching: (state) => state.isFetching,
    sessionsList: (state) => state.sessions.list,
    activeSession: (state) => state.sessions.active,
  },
  mutations: {
    setAsFetching(state, newState) {
      state.isFetching = newState
    },
    setSessionsList(state, sessions) {
      sessions.forEach((session) => {
        (session.custom_name = session.custom_name || getDefaultSessionName(session.name))
      })
      state.sessions.list = [...state.sessions.list, ...sessions]
    },
    setActiveSession(state, session) {
      state.sessions.active = session
    },
    resetState(state) {
      const rawState = generateDefaultState()

      Object.entries(rawState).forEach(([prop, value]) => {
        Vue.set(state, prop, value)
      })
    },
  },
  actions: {
    async fetchPatientSessionResults({ commit, rootGetters }, { patientId, offset, limit }) {
      try {
        const institutionId = rootGetters['user/currentInstitutionId']

        commit('setAsFetching', true)
        const { data } = await Vue.$http('results/getPatientSessionResults', { patientId, institutionId, offset, limit })

        data.sessions.forEach((session) => {
          session.exercises = sortByDate(session.exercises, 'begin')
        })
        commit('setSessionsList', data.sessions)

        return Promise.resolve(data)
      } catch (error) {
        console.error(`Error fetching patient ${patientId} results.`, error)

        return Promise.reject(error)
      } finally {
        commit('setAsFetching', false )
      }
    },
    async fetchClinicalPatientSessionResults({ commit }, { programUUID, offset, limit }) {
      try {
        commit('setAsFetching', true)
        const { data } = await Vue.$http('results/getClinicalPatientSessionResults', { programUUID, offset, limit })

        data.results.forEach((result) => {
          result.exercises = sortByDate(result.exercises, 'begin_at')
        })
        commit('setSessionsList', data.results)

        return Promise.resolve(data)
      } catch (error) {
        console.error('Error fetching clinical patient results.', error)

        return Promise.reject(error)
      } finally {
        commit('setAsFetching', false )
      }
    },
    async fetchClinicalPatientSessionsInfo({ commit }, { programUUID }) {
      try {
        commit('setAsFetching', true)
        const { data } = await Vue.$http('results/getClinicalPatientSessionsInfo', { programUUID })

        return Promise.resolve(data)
      } catch (error) {
        console.error('Error fetching clinical patient results metrics.', error)

        return Promise.reject(error)
      } finally {
        commit('setAsFetching', false )
      }
    },
    async fetchSessionResults({ commit, rootGetters }, { patientId, sessionId, groups, offset, limit }) {
      try {
        const institutionId = rootGetters['user/currentInstitutionId']

        commit('setAsFetching', true)
        const { data } = await Vue.$http('results/getSessionResults', { patientId, institutionId, sessionId, groups, offset, limit })

        return Promise.resolve(data)
      } catch (error) {
        console.error(`Error fetching session ${sessionId} results for patient ${patientId}.`, error)

        return Promise.reject(error)
      } finally {
        commit('setAsFetching', false )
      }
    },
    async fetchExerciseResults({ commit }, { patientId, resultId }) {
      try {
        commit('setAsFetching', true)
        const { data } = await Vue.$http('results/getExerciseResults', { patientId, resultId })

        return Promise.resolve(data)
      } catch (error) {
        console.error(`Error fetching exercise results for id ${resultId} for patient ${patientId}.`, error)

        return Promise.reject(error)
      } finally {
        commit('setAsFetching', false )
      }
    },
  },
}
