export default {
  auth: {
    login: {
      api: {
        url: 'default',
        version: 1,
      },
      $$method: 'post',
      $$config: {
        withCredentials: true,
        headers: (data) => data.headers.authorization,
      },
      $$meta: {
        mock: (_, reqData, { username, password }) => {

          const demoUsers = {
            demo_professional_1: 'sworddemo',
            demo_professional_2: 'sworddemo',
            swordhealth: 'pass',
          }

          const userAllowed = demoUsers[username] && demoUsers[username] === password

          return `mocks/endpoints/user/login_${userAllowed ? 'success' : 'wrong_credentials'}.json`
        },
      },
      $$makeUrl(configs) {
        return `therapist/v${configs.api.version}/auth/login`
      },
    },
    logout: {
      api: {
        url: 'default',
        version: 1,
      },
      $$method: 'post',
      $$meta: {
        mock: () => 'mocks/endpoints/user/logout.json',
      },
      $$config: {
        withCredentials: true,
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs) {
        return `api/v${configs.api.version}/logout`
      },
    },
    forgotPassword: {
      api: {
        url: 'default',
      },
      $$method: 'post',
      $$config: {
        withCredentials: true,
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl() {
        return 'password/reset'
      },
    },
    changePassword: {
      api: {
        url: 'default',
        version: 1,
      },
      $$method: 'post',
      $$config: {
        withCredentials: true,
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs) {
        return `api/v${configs.api.version}/changePassword`
      },
    },
    unlockAccount: {
      api: {
        url: 'default',
      },
      $$method: 'post',
      $$config: {
        withCredentials: true,
      },
      $$makeUrl() {
        return 'unlock'
      },
    },
    refreshAccessToken: {
      api: {
        url: 'default',
        version: '2',
      },
      $$method: 'get',
      $$meta: {
        mock: () => 'mocks/endpoints/user/refreshAccessToken.json',
      },
      $$config: {
        withCredentials: true,
        headers: (data) => {
          const authorizationHeader = data.headers.refreshAuthorization

          return Object.assign({}, authorizationHeader, { 'Content-Type': 'text/plain' })
        },
      },
      $$makeUrl(configs) {
        return `api/v${configs.api.version}/auth/refresh`
      },
    },
    validateSignedAction: {
      api: {
        url: 'default',
        version: 1,
      },
      $$method: 'get',
      $$makeUrl(configs, { action, token }) {
        const queryParams = new URLSearchParams({ action, token })
        const url = `api/v${configs.api.version}/signed/action`

        return `${url}?${queryParams}`
      },
    },
    resetPassword: {
      api: {
        url: 'default',
      },
      $$method: 'post',
      $$makeUrl(configs, token) {
        return `password/reset/${token}`
      },
    },
  },
  me: {
    fetchMe: {
      api: {
        url: 'default',
        version: 2,
      },
      $$method: 'get',
      $$meta: {
        mock: () => 'mocks/endpoints/user/me/fetchMe.json',
      },
      $$config: {
        withCredentials: true,
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs) {
        return `therapist/v${configs.api.version}/me`
      },
    },
    fetchInstitutions: {
      api: {
        url: 'default',
        version: 2,
      },
      $$method: 'get',
      $$config: {
        withCredentials: true,
        headers: (data) => data.headers.authorization,
      },
      $$meta: {
        mock: () => {
          return 'mocks/endpoints/user/me/fetchInstitutions.json'
        },
      },
      $$makeUrl(configs) {
        return `therapist/v${configs.api.version}/me/institutions`
      },
    },
  },
  getUser: {
    api: {
      url: 'default',
    },
    $$method: 'get',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, userId) {
      return `api/v1.1/users/${userId}`
    },
  },
  updateProfessional: {
    api: {
      url: 'default',
      version: 2,
    },
    $$method: 'put',
    $$config: {
      withCredentials: true,
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, userId) {
      return `api/v${configs.api.version}/users/${userId}`
    },
  },
}
