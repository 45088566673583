let defaults = require('./defaults.json')

if (process.env.NODE_ENV === 'development') {
  try {
    // eslint-disable-next-line
    defaults = require('./local.json');
  } catch {
    console.info('Using default.json vars')
  }
}

// When we have ramda in this project, let's make this more beautiful.
const isNullOrEmpty = (val) => {
  const nullOrUndefined = !val && val !== false && val !== 0
  const isEmpty = !nullOrUndefined && typeof val === 'object' && !Object.keys(val).length

  return nullOrUndefined || isEmpty
}

const keyExistsInObject = (object, key) => {
  if (isNullOrEmpty(object) || isNullOrEmpty(key)) {
    return false
  }

  if (Object.prototype.hasOwnProperty.call(object, key)) {
    return !isNullOrEmpty(object[key])
  }

  return false
}

export function get(key) {
  if (keyExistsInObject(window.CONFIG, key)) {
    return window.CONFIG[key]
  }

  if (process.env.NODE_ENV !== 'development') {
    console.debug(`Unable to find ${key} in window config. Retrieving default value`)
  }

  return defaults[key]
}
