type SortableItem = string | Date | { [key: string]: string };

/**
 * Sort plain or objects array by date filter
 * @name SortByDate Utils filter
 * @param {array} arrayToSort Array to sort
 * @param {string} sortByKey Key to sort by
 * @returns {array} Date sorted Array
 */
export const sortByDate = <T extends SortableItem>(arrayToSort: T[], sortByKey?: string, orderBy: 'asc' | 'desc' = 'asc'): T[] => {
  const orientation = orderBy === 'asc' ? 1 : -1

  const isSortable = Array.isArray(arrayToSort)
  const plainStrArray = isSortable && arrayToSort.every((element) => typeof element === 'string')

  if (!isSortable || (!plainStrArray && !sortByKey)) {
    console.warn('[sort-date-filter] Unable to sort provided source')

    return arrayToSort
  }

  function laterThan(d1: string, d2: string) {
    const timestampToTest = Math.round(new Date(d1).getTime() * 1000)
    const timestampRef = Math.round(new Date(d2).getTime() * 1000)

    return timestampToTest > timestampRef
  }

  return arrayToSort.sort((a: T, b: T) => {
    const aVal = plainStrArray ? a as string : (a as { [key: string]: string })[sortByKey || ''] || ''
    const bVal = plainStrArray ? b as string : (b as { [key: string]: string })[sortByKey || ''] || ''

    if (aVal === bVal) {
      return 0
    }

    return (laterThan(aVal, bVal) ? 1 : -1) * orientation
  })
}

export default sortByDate
