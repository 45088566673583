export default {
  getAllPlans: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { programUuid }) {
      return `therapist/v${configs.api.version}/mind/members/${programUuid}/prescriptions`
    },
  },
  addPlan: {
    getPrograms: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs) {
        return `therapist/v${configs.api.version}/mind/programs`
      },
    },
    getSettings: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { programUuid }) {
        return `therapist/v${configs.api.version}/mind/members/${programUuid}/prescriptions/settings`
      },
    },
    mutation: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'post',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs, { programUuid }) {
        return `therapist/v${configs.api.version}/mind/members/${programUuid}/prescriptions`
      },
    },
  },
  getPlan: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { programUuid, prescriptionUuid }) {
      return `therapist/v${configs.api.version}/mind/members/${programUuid}/prescriptions/${prescriptionUuid}/overview`
    },
  },
  deletePlan: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'delete',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { programUuid, prescriptionUuid }) {
      return `therapist/v${configs.api.version}/mind/members/${programUuid}/prescriptions/${prescriptionUuid}`
    },
  },
  editPlan: {
    getActivities: {
      api: {
        url: 'default',
        version: '1',
      },
      $$method: 'get',
      $$config: {
        headers: (data) => data.headers.authorization,
      },
      $$makeUrl(configs) {
        return `therapist/v${configs.api.version}/mind/activities?internal_name=&client=clinical_portal`
      },
    },
  },
  savePlan: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'put',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { prescriptionUuid }) {
      return `therapist/v${configs.api.version}/mind/members/prescriptions/${prescriptionUuid}`
    },
  },
}
