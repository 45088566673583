import Vue from 'vue'
import { shDate } from '@/utils/filters'

const formatMonthAndDay = (date) => {
  return shDate(new Date(date), 'date', 'MMM DD')
}

const isOutdated = (date) => {
  return Vue.$date(new Date(date)).diff(Vue.$date(), 'day') < 0
}

const getProtocolInfo = (protocol) => {
  return {
    name: protocol.protocol_name,
    currentPhase: protocol.protocol_current_phase_name,
    totalPhases: protocol.protocol_total_phases,
    startDate: formatMonthAndDay(protocol.protocol_created_at),
    endDate: formatMonthAndDay(protocol.protocol_expires_at),
    outdated: isOutdated(protocol.protocol_expires_at),
  }
}

export { formatMonthAndDay, isOutdated, getProtocolInfo }
