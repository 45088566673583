export default (justifications, fullExerciseslist, changesByExerciseID, backupPrescriptionExercises) => {

  const exercisesPayload = Object.entries(justifications).map(([exerciseID, exerciseChanges]) => {

    function getOldAndNewValuesByChangedProps() {
      return Object.entries(changesByExerciseID[exerciseID]).map(([changeType, newValue]) => {
        const orgExercise = backupPrescriptionExercises.find((ex) => ex.id === Number(exerciseID))

        return {
          [changeType]: {
            old: orgExercise ? orgExercise[changeType] : null,
            new: newValue,
          },
        }
      })
    }

    return {
      code: fullExerciseslist.find((ex) => ex.id === Number(exerciseID)).code,
      changes: Object.assign({}, ...getOldAndNewValuesByChangedProps()), ...exerciseChanges,
    }
  })

  return exercisesPayload
}
