export default {
  createDPTActivity: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'post',
    $$meta: {
      mock: () => 'mocks/endpoints/dpt-activity/createDptActivity.json',
    },
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs) {
      return `therapist/v${configs.api.version}/dpt-activities`
    },
  },
  heartbeatDPTActivity: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'put',
    $$meta: {
      mock: () => 'mocks/endpoints/dpt-activity/heartbeatDptActivity.json',
    },
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { activityUuid }) {
      return `therapist/v${configs.api.version}/dpt-activities/${activityUuid}/heartbeat`
    },
  },
}
