export default {
  fetchRecommendationsCount: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$meta: {
      mock: () => 'mocks/endpoints/recommendations/fetchRecommendationsCount.json',
    },
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs, { status }) {
      const url = `therapist/v${configs.api.version}/protocol-progression-recommendations/total`
      const queryParams = new URLSearchParams('')

      if (status !== undefined) {
        queryParams.append('status', status)
      }

      return `${url}?${queryParams}`
    },
  },
  fetchRecommendations: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      mock: () => 'mocks/endpoints/recommendations/fetchRecommendations.json',
    },
    $$makeUrl(configs, { offset, limit }) {
      const url = `therapist/v${configs.api.version}/protocol-progression-recommendations`
      const queryParams = new URLSearchParams('')

      if (offset !== undefined) {
        queryParams.append('offset', offset)
      }

      if (limit) {
        queryParams.append('limit', limit)
      }

      return `${url}?${queryParams}`
    },
  },
  updateRecommendationsBatch: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'post',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$makeUrl(configs) {
      return `therapist/v${configs.api.version}/protocol-progression-recommendations/batch`
    },
  },
  fetchRecommendationReasons: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {
      headers: (data) => data.headers.authorization,
    },
    $$meta: {
      mock: (_, recommendationUuid) => {
        console.log(recommendationUuid)

        return `mocks/endpoints/recommendations/fetchRecommendationReasons/${recommendationUuid}.json`
      },
    },
    $$makeUrl(configs, recommendationUuid) {
      return `therapist/v${configs.api.version}/protocol-progression-recommendations/${recommendationUuid}/justifications`
    },
  },
}
