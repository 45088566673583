<template>
  <div class="patient-profile-summary">
    <!-- Photo and Gender -->
    <div class="patient-profile-summary__photo-container">
      <a
        :href="profileUrl"
        class="patient-profile-summary__picture-wrapper"
        target="_blank"
        rel="noreferrer noopener"
      >
        <profile-picture
          :gender="gender"
          :vip="vip"
          :high-risk="highRisk"
          :picture="picture"
          side="4rem"
        />
      </a>
      <div class="patient-profile-summary__gender-icon">
        <gender-icon v-if="gender" :gender="gender" />
      </div>
    </div>

    <!-- Patient attention level -->
    <patient-attention-level
      v-model="attentionLevel"
      :patient-id="patientId"
      :editable="attentionLevelEditable"
      @change="handleChangeAttentionLevel"
    />

    <!-- Patient identity annotations -->
    <div class="patient-profile-summary__identity-annotations">
      <gutenberg-highlight v-if="isGutenbergFlowMember"/>
      <p class="-mb_0">
        <a :href="profileUrl" target="_blank" rel="noreferrer noopener">
          {{ `${firstName} ${lastName}` }}
        </a>
        <template v-if="!!age">
          <span class="dot-separator -mx_1"></span>
          <span>{{ $t("copy_1288") }} {{ age }}</span>
        </template>
      </p>
    </div>
    <p class="t3 -mb_1">
      <template v-if="currentState">
        <span :class="`patient-profile-summary__status ${currentStateDetails.cssClass}-bg`"></span>
        <span>{{ $t(currentStateDetails.copy) }}</span>
        <span class="dot-separator -mx_1"></span>
      </template>

      <template v-if="remainingDaysInOnHold !== null">
        <chip
          :title="
            remainingDaysInOnHold === 0
              ? $t('LATEST_ACTIVITY.TODAY')
              : $t('n_days_left', { daysleft: remainingDaysInOnHold })
          "
          color="primary"
          rounded
          bold
        >
          {{ remainingDaysInOnHold }}d
        </chip>
        <span class="dot-separator -mx_1"></span>
      </template>

      <span class="copy">{{ $t("copy_4331") }} </span>
      <hover-to-copy
        class="patient-profile-summary__id"
        @on-copy="() => handleClickAction('copy-patient-id')"
      >{{ patientId }}</hover-to-copy>

      <span class="dot-separator -mx_1"></span>
      <span>{{ institutionName }}</span>
    </p>

    <p v-if="asoName">
      <span class="copy">{{ $t("member_info.aso_group") }}</span>
      <span class="patient-profile-summary__id">
        {{ asoName }}
      </span>
    </p>

    <tag
      v-if="hasPendingExclusion"
      color="neutral"
      size="small"
      :text="$t('SIDEBAR.PENDING_EXCLUSION')"
    />

    <!-- Quick Actions -->
    <div class="patient-profile-summary__actions -mt_2">
      <quick-action
        v-for="(action, key) in quickActions"
        v-if="!action.disabled"
        :key="`quick-action-${key}`"
        :action-key="action.key"
        :icon="action.icon"
        :tooltip="action.tooltip"
        :options="action.options"
        @action="handleClickAction"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'

// Components
import GenderIcon from '@ui-kit/components/pictures/GenderIcon.vue'
import ProfilePicture from '@ui-kit/components/pictures/ProfilePicture.vue'
import PatientAttentionLevel from '@ui-kit/components/attention-level/PatientAttentionLevel.vue'
import HoverToCopy from '@ui-kit/components/micro/HoverToCopy.vue'
import GutenbergHighlight from '@/components/micro/GutenbergHighlight.vue'
import Chip from '@ui-kit/components/Chip.vue'
import Tag from '@ui-kit/components/tag/Tag.vue'
import QuickAction from '@/components/inputs/QuickAction.vue'

import { QUICK_ACTIONS_CONFIG } from './quickActionsConfig'

export default {
  name: 'PatientProfileSummary',
  components: {
    HoverToCopy,
    QuickAction,
    GenderIcon,
    GutenbergHighlight,
    Chip,
    ProfilePicture,
    PatientAttentionLevel,
    Tag,
  },
  props: {
    patientId: {
      type: Number,
      required: true,
    },
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    gender: {
      type: String,
      default: '',
    },
    vip: Boolean,
    highRisk: Boolean,
    picture: {
      type: String,
      default: '',
    },
    age: {
      type: Number,
    },
    currentState: {
      type: String,
      default: null,
    },
    remainingDaysInOnHold: {
      type: Number,
      default: null,
    },
    patientAttentionLevel: {
      type: String,
      default: '',
    },
    attentionLevelEditable: Boolean,
    institutionName: {
      type: String,
      default: '',
    },
    profileUrl: {
      type: String,
      default: '',
    },
    hasPendingExclusion: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String,
    },
    isGutenbergFlowMember: {
      type: [String, Boolean],
      default: false,
    },
    asoName: {
      type: String,
    },
  },
  emits: ['open-ui', 'copy-to-clipboard', 'send-to-user'],
  setup(props, { emit }) {
    const attentionLevel = ref(props.patientAttentionLevel)
    const showSendLinksMenu = ref(false)

    const currentStateDetails = computed(() => {
      const CSS_CLASS_PREFIX = 'patient-state-'

      return {
        cssClass: props.currentState
          ? `${CSS_CLASS_PREFIX}${props.currentState.replace(/_/g, '-')}`
          : `${CSS_CLASS_PREFIX}unknown`,
        copy: props.currentState ? `patient_state_${props.currentState}` : '-',
      }
    })

    const quickActions = computed(() => {
      const quickActions = QUICK_ACTIONS_CONFIG.filter(
        (quickAction) => quickAction.key !== 'open-dt-message',
      )

      return (props.unit === 'bloom' || props.unit === 'mind') ? quickActions : QUICK_ACTIONS_CONFIG
    })

    const handleChangeAttentionLevel = (attentionLevel) => {
      emit('change-attention-level', attentionLevel)
    }

    const handleClickAction = (action) => {
      const context = action.replace(/^[a-z]+-/, '')

      switch (action) {
        case 'open-dt-message':
        case 'open-notes':
          emit('open-ui', { context })
          break
        case 'copy-reassessment-link':
        case 'copy-ob-call-link':
        case 'copy-patient-id':
        case 'copy-check-in-link':
          emit('copy-to-clipboard', {
            context,
            content: context === 'patient-id' ? props.patientId : null,
          })
          break
        case 'send-reassessment-link':
        case 'send-check-in-link':
          emit('send-to-user', { context })
          break
        default:
          break
      }
    }

    return {
      quickActionsConfigs: QUICK_ACTIONS_CONFIG,
      attentionLevel,
      showSendLinksMenu,
      currentStateDetails,
      quickActions,
      handleChangeAttentionLevel,
      handleClickAction,
    }
  },
}
</script>

<style lang="scss" scoped>
$gender-icon-size: 1.5rem;

.patient-profile-summary {
  @include t-regular;

  display: flex;
  flex-direction: column;
  align-items: center;

  > p {
    display: flex;
    align-items: center;
  }

  a {
    color: inherit;

    &:hover {
      color: $primary-color;
    }
  }

  &__identity-annotations {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__id {
    margin-left: 0.3rem;
  }

  &__status {
    height: 0.375rem;
    width: 0.375rem;
    border-radius: 50%;
    margin-right: 0.25rem;
  }

  &__actions {
    position: relative;
  }

  &__photo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.6rem;
  }

  &__picture-wrapper {
    border: 1px solid transparent;
    border-radius: 50%;
    transition: border-color 0.2s ease-in-out;

    &:hover {
      border-color: $primary-color;
    }
  }

  &__gender-icon {
    width: $gender-icon-size;
    height: $gender-icon-size;
    margin-top: calc(-#{$gender-icon-size} / 2);
    background-color: $color-white;
    border-radius: 50%;
    padding: 0.2rem;
    z-index: 2;
    border: 1px solid $context-color-7;
  }

  .copy {
    color: $context-color-4;
  }
}
</style>
