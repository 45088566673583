<template>
  <div class="infinite-scroll">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'InfiniteScroll',
  props: {
    /**
     * configuration options that can be passed to the intersection observer, including root, rootMargin and threshold´
     * see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API for more details
     */
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      observer: null,
      isFetching: false,
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(([entry]) => {
      const { isIntersecting } = entry

      if (isIntersecting) {
        this.$emit('load-more')
      }
      this.$emit('input', isIntersecting || false)
    }, this.options)

    this.observer.observe(this.$el)
  },
  beforeDestroy() {
    this.observer.disconnect()
  },
}
</script>
<style lang="scss" scoped>
.infinite-scroll {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
