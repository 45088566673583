import exerciseToPrescriptionFormat from '@/scripts/configs/prescription/payload-generators/exercises-to-prescription-format'

const genNewSessionPayload = ({
  patientId,
  addedExercises,
  sessionData,
  weeklyFrequency,
  sessionName,
  sessionDefinitionId,
  goal,
  sessionCategory,
}) => {

  const { therapy: sessionTherapy, therapySide: sessionTherapySide } = sessionData

  // TODO PINHEIRO ON BACKEND ABOUT THERAPY SIDE. REMOVE WHEN POSSIBLE
  const therapySide = sessionTherapySide || sessionTherapy.sides[0]

  // Basic payload for new sessions
  const sessionPayload = {
    patient_id: patientId,
    therapy_id: sessionTherapy.id,
    side: therapySide,
    daily_frequency: 1,
    exercises: addedExercises.map(exerciseToPrescriptionFormat),
    goal,
    session_definition_id: sessionDefinitionId,
    session_category: sessionCategory,
  }

  // If there's a custom name for this session, add it
  if (sessionName) {
    sessionPayload.custom_name = sessionName
  }

  // If patient still hasn't got weekly set, use the one set during prescription flow
  if (weeklyFrequency) {
    sessionPayload.weekly_frequency = weeklyFrequency
  }

  return sessionPayload
}

const genEditSessionPayload = (addedExercises, customName, goal, removedExercises, reAddedExercises) => {
  const sessionPayload = {
    daily_frequency: 1,
    exercises: addedExercises.map(exerciseToPrescriptionFormat),
    custom_name: customName,
    goal,
  }

  if (reAddedExercises.length) {
    sessionPayload.blacklisted_exercises_removed = reAddedExercises
  }

  if (removedExercises.length) {
    sessionPayload.blacklisted_exercises_added = removedExercises
  }

  return sessionPayload
}

const genProtocolPrescriptionPayload = (status, statusJustification, filters, protocolSpecs, originalExercises) => {

  const { protocolName, protocolSessionName } = protocolSpecs
  const { reason, explanation } = statusJustification
  const { protocolId, protocolPhase, protocolWeek, protocolSessionId } = filters

  return {
    filters: {
      id: protocolId,
      phase: protocolPhase,
      week: protocolWeek,
      session: protocolSessionId,
    },
    status,
    status_justification: {
      reason,
      explanation,
    },
    base_payload: {
      protocol_name: protocolName,
      session_name: protocolSessionName,
      exercises: originalExercises.map(exerciseToPrescriptionFormat),
    },
  }
}

export { genNewSessionPayload, genEditSessionPayload, genProtocolPrescriptionPayload }
