<template>
  <li class="note-item-wrapper -px_2 -py_1" @click="$emit('open')">
    <template v-if="isBeingDeleted">
      <div class="note-details-container">
        <span class="-t2 -t_medium">{{ $t('NOTES.DELETE') }}?</span>
      </div>
      <div class="actions-container" @click.stop>
        <sword-button-wuk
          class="-mx_1"
          theme="secondary"
          size="medium"
          text="COMMON.YES"
          @click.native="$emit('delete')"
        />
        <sword-button-wuk
          theme="tertiary"
          size="medium"
          text="COMMON.NO"
          @click.native="isBeingDeleted = false"
        />
      </div>
    </template>
    <template v-else>
      <div class="note-details-container">
        <span class="-t3 -t_medium">{{ title }}</span>
        <span class="-t3">{{ date }}</span>
      </div>
      <sword-button-wuk
        class="clear-results-button-mobile -px_0 -pl_1"
        theme="link"
        round
        size="small"
        icon="close"
        @click.native.stop="isBeingDeleted = true"
      />
    </template>
  </li>
</template>

<script>
import { ref } from 'vue'

// Components
import SwordButtonWuk from '@ui-kit/components/buttons/SwordButton.vue'

export default {
  name: 'NoteItem',
  components: {
    SwordButtonWuk,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    date: {
      type: [String, Number],
      required: true,
    },
  },
  setup() {
    const isBeingDeleted = ref(false)

    return { isBeingDeleted }
  },
}
</script>

<style lang="scss" scoped>
.note-item-wrapper {
  cursor: pointer;
  border-radius: 1.33rem;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 4.5rem;
  box-shadow: 0 6px 17px 0 rgba(31, 34, 44, 0.07);

}

.note-details-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;

  &:last-child {
    color: $context-color-5;
  }
}

.actions-container {
  flex: none;
  display: flex;
  align-items: center;

  &::v-deep {
    .sword-button-wuk {
      min-width: 5rem;
      margin: 0;
    }
  }
}
</style>
