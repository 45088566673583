import { deepCopyObj } from '@/scripts/tools/helper-tools'

export default (therapyExercises, exercisesConditionsByExerciseId) => {

  const exercisesToAdd = []
  let maxOrderExercise = 1
  let totalAddedExercises = 0

  const fullExercisesList = therapyExercises.map((therapyExercise) => {
    const exerciseToEdit = therapyExercise

    // Applying default series to exercise if available on definition
    exerciseToEdit.series = exerciseToEdit.series || exerciseToEdit.default_series || 0

    // Assign exercise conditions
    const exerciseConditions = exercisesConditionsByExerciseId[exerciseToEdit.id]

    exerciseToEdit.conditions = exerciseConditions

    // Exercise doesn't need to be pre-filled
    if (!exerciseToEdit.series > 0) {
      return exerciseToEdit
    }

    if (maxOrderExercise < exerciseToEdit.order) {
      maxOrderExercise = exerciseToEdit.order
    }

    // Replicate each series as a new exercise instance
    for (let i = 0; i < exerciseToEdit.series; i++) {
      const exerciseCopy = deepCopyObj(exerciseToEdit)

      // Force execise series to be 1
      exerciseCopy.series = 1
      exercisesToAdd.push(exerciseCopy)
    }
    totalAddedExercises += exerciseToEdit.series

    return exerciseToEdit
  })

  return {
    maxOrder: maxOrderExercise,
    numOfExercises: totalAddedExercises,
    exercisesList: fullExercisesList,
    exercisesAdded: exercisesToAdd,
  }
}
